import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import Page from "../../components/Page";
import {
  membershipsAccept,
  membershipsDecline,
  selectIsLoggedIn,
  selectUserEmail,
  selectUserId,
  selectUserName,
  selectUserPhoto,
  upsertOneInvitation,
} from "../../store";
import { useIntl } from "react-intl";
import { fetchDoc, fetchReserves } from "../../apis/firebase";
import ReserveCard from "./ReserveCard";
import { useTheme } from "@mui/material/styles";

const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return isMobile;
};

export default function MembershipInvite() {
  const { inviteId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const email = useSelector(selectUserEmail) || "";
  const userId = useSelector(selectUserId) || "";
  const userName = useSelector(selectUserName) || "";
  const userPhoto = useSelector(selectUserPhoto) || "";
  const isMobile = useIsMobile();

  const [reserves, setReserves] = useState([]);
  const [invitation, setInvitation] = useState();

  useEffect(() => {
    if (!inviteId) return;
    const fetch = async () => {
      const data = await fetchDoc("notifications", inviteId);

      setInvitation(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteId]);

  const {
    memberId,
    membershipId,
    planId,
    description,
    membershipPhoto,
    name,
    note,
    parentName,
    planPhoto,
    price,
    vendorId,
    vendorName,
    vendorPhoto,
  } = invitation ?? {
    email: "",
    memberId: "",
    membershipId: "",
    planId: "",
    userId: "",
  };

  useEffect(() => {
    if (!planId) return;
    const fetch = async () => {
      const querySnapshot = await fetchReserves(planId);
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push({
          ...doc.data(),
        });
      });

      setReserves(data);
    };
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const handleAccept = async () => {
    if (!isLoggedIn) {
      dispatch(
        upsertOneInvitation({
          toBeAcceptedOnLogin: true,
          ...invitation,
        })
      );
      navigate("/login", {
        state: { from: `/invitations/${inviteId}/type/membership` },
      });
      return;
    }
    const response = await dispatch(
      membershipsAccept({
        email,
        body: `${intl.formatMessage({ id: "from" })} ${userName}`,
        inviteId,
        memberId,
        membershipId,
        note,
        planId,
        userId,
        userPhoto,
        vendorId,
        vendorName,
        vendorPhoto,
      })
    );
    if (!response.error) {
      navigate(`/enrolments/${planId}`);
    }
  };

  const handleDecline = async () => {
    await dispatch(
      membershipsDecline({
        inviteId,
      })
    );
    navigate(`/`);
  };

  return (
    <Page
      title={`${intl.formatMessage({ id: "membership" })} ${intl.formatMessage({
        id: "invite",
      })}`}
    >
      <Container maxWidth="md">
        {invitation ? (
          <Card>
            <CardHeader
              avatar={<Avatar aria-label="vendor" src={vendorPhoto} />}
              title={`${intl.formatMessage({
                id: "membership",
              })} ${intl.formatMessage({ id: "invite" })}`}
              subheader={`${intl.formatMessage({ id: "from" })} ${vendorName}`}
              sx={{ padding: 3 }}
              action={
                <Stack>
                  {isMobile ? (
                    <>
                      <Button
                        onClick={handleAccept}
                        variant="text"
                        size="small"
                        sx={{ marginBottom: 2 }}
                      >
                        {isLoggedIn
                          ? `${intl.formatMessage({ id: "activate" })}`
                          : `${intl.formatMessage({
                              id: "login_to_continue",
                            })}`}
                      </Button>
                      <Button
                        onClick={handleDecline}
                        variant="text"
                        size="small"
                      >
                        {intl.formatMessage({ id: "decline" })}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={handleAccept}
                        variant="contained"
                        size="medium"
                        sx={{ marginBottom: 2 }}
                      >
                        {isLoggedIn
                          ? `${intl.formatMessage({ id: "activate" })}`
                          : `${intl.formatMessage({
                              id: "login_to_continue",
                            })}`}
                      </Button>
                      <Button
                        onClick={handleDecline}
                        variant="outlined"
                        size="medium"
                      >
                        {intl.formatMessage({ id: "decline" })}
                      </Button>
                    </>
                  )}
                </Stack>
              }
            />
            {membershipPhoto && (
              <CardMedia
                component="img"
                height="194"
                image={planPhoto || membershipPhoto}
                alt={name}
              />
            )}

            <CardContent>
              <Typography component="div" variant="h5">
                {parentName}
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
              >
                {name}{" "}
                {price && parseFloat(price) > 0
                  ? `$${parseFloat(price).toFixed(2)}`
                  : intl.formatMessage({ id: "free" })}
              </Typography>
              <Typography paragraph>{description}</Typography>
              <Typography component="div" variant="h5">
                {intl.formatMessage({ id: "membership_invite_info" })}
              </Typography>
              {reserves?.length > 0 && (
                <>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                    gutterBottom
                  >
                    {intl.formatMessage({ id: "products_may_include" })}:
                  </Typography>
                  <Grid container spacing={3}>
                    {reserves
                      .filter((r) => r.id === r.parentId)
                      .map((reserve, i) => (
                        <ReserveCard key={i} reserve={reserve} index={i} />
                      ))}
                  </Grid>
                </>
              )}
            </CardContent>
            <CardActions sx={{ padding: 3, justifyContent: "flex-end" }}>
              <Button
                onClick={handleDecline}
                variant="outlined"
                startIcon={<CancelIcon />}
              >
                {intl.formatMessage({ id: "decline" })}
              </Button>
              <Button
                onClick={handleAccept}
                variant="contained"
                startIcon={<CheckIcon />}
              >
                {isLoggedIn
                  ? `${intl.formatMessage({ id: "accept" })}`
                  : `${intl.formatMessage({ id: "login_to_continue" })}`}
              </Button>
            </CardActions>
          </Card>
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: 400 }}
          />
        )}
      </Container>
    </Page>
  );
}
