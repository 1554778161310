import PropTypes from "prop-types";
import { noCase } from "change-case";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import { alpha } from "@mui/material/styles";
import {
  Box,
  Button,
  Badge,
  Avatar,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  notificationsSelectors,
  updateDoc,
  updateManyNotification,
} from "../../store";

// const NOTIFICATIONS = [
//   {
//     id: nanoid(4),
//     title: 'Your order is placed',
//     description: 'waiting for shipping',
//     avatar: null,
//     type: 'order_placed',
//     createdAt: set(new Date(), { hours: 10, minutes: 30 }),
//     isUnRead: true
//   },
//   {
//     id: nanoid(4),
//     title: "faker.name.findName()",
//     description: 'answered to your comment on the Minimal',
//     avatar: mockImgAvatar(2),
//     type: 'friend_interactive',
//     createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
//     isUnRead: true
//   },
//   {
//     id: nanoid(4),
//     title: 'You have new message',
//     description: '5 unread messages',
//     avatar: null,
//     type: 'chat_message',
//     createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
//     isUnRead: false
//   },
//   {
//     // id: nanoid(4),
//     title: 'You have new mail',
//     description: 'sent from Guido Padberg',
//     avatar: null,
//     type: 'mail',
//     createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
//     isUnRead: false
//   },
//   {
//     id: nanoid(4),
//     title: 'Delivery processing',
//     description: 'Your order is being shipped',
//     avatar: null,
//     type: 'order_shipped',
//     createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
//     isUnRead: false
//   }
// ];

function renderContent(notification, _title) {
  const title = (
    <Typography variant="subtitle2">
      {_title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification?.body ?? "")}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img alt={_title} src="/static/icons/ic_notification_package.svg" />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img alt={_title} src="/static/icons/ic_notification_shipping.svg" />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: <img alt={_title} src="/static/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: <img alt={_title} src="/static/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: <img alt={_title} src={notification.icon} />,
    title,
  };
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
};

function NotificationItem({ notification }) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { avatar = "", title } = renderContent(
    notification,
    intl.formatMessage({ id: notification.title })
  );

  const handleClick = async () => {
    await dispatch(
      updateDoc({
        collection: "notifications",
        id: notification.id,
        payload: {
          isUnRead: false,
        },
      })
    );
    navigate(notification.click_action);
  };

  return (
    <ListItemButton
      to={notification.click_action}
      onClick={handleClick}
      disableGutters
      component={RouterLink}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Box
              component={Icon}
              icon={clockFill}
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {/* {formatDistanceToNow(new Date(notification.updated))} */}
            {notification.updated}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const notifications = useSelector(notificationsSelectors.selectAll);
  const unreadNotifications = notifications?.filter((n) => n.isUnRead) ?? [];
  const totalUnRead = unreadNotifications.length;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  // const [notifications, setNotifications] = useState(NOTIFICATIONS);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllAsRead = () => {
    dispatch(
      updateManyNotification(
        unreadNotifications
          .filter((n) => n.isUnRead)
          .map((notification) => ({
            ...notification,
            isUnRead: false,
          }))
      )
    );
    // setNotifications(
    //   notifications.map((notification) => ({
    //     ...notification,
    //     isUnRead: false
    //   }))
    // );
  };

  const [alignment, setAlignment] = useState("unread");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Icon icon={bellFill} width={20} height={20} />
        </Badge>
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: { xs: 340, sm: "auto" },
            // maxHeight: ITEM_HEIGHT * 4.5,
            width: 360,
          },
        }}
      >
        <Toolbar>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value="unread">
              {intl.formatMessage({ id: "unread" })} ({totalUnRead})
            </ToggleButton>
            <ToggleButton value="all">
              {intl.formatMessage({ id: "all" })}
            </ToggleButton>
          </ToggleButtonGroup>
        </Toolbar>
        {(alignment === "unread" && totalUnRead === 0) && 
          <Typography sx={{ padding: 2 }}>
            {intl.formatMessage({ id: "no_notifications" })}
          </Typography>
        }
        {alignment === "unread" &&
          totalUnRead > 0 &&
          unreadNotifications.map((notification, i) => (
            <NotificationItem key={i} notification={notification} />
          ))}
        {(alignment === "unread" && totalUnRead > 0) && (
          <MenuItem sx={{ "&:hover": { backgroundColor: "transparent" } }}>
            <Button
              disabled={notifications.length < 1}
              onClick={() => handleMarkAllAsRead()}
              startIcon={<MarkEmailReadIcon />}
            >
              {intl.formatMessage({ id: "mark_all_read" })}
            </Button>
          </MenuItem>
        )}
        {alignment === "all" &&
          notifications.length > 0 &&
          notifications.map((notification, i) => (
            <NotificationItem key={i} notification={notification} />
          ))}
        {alignment === "all" && notifications.length === 0 && (
          <Typography sx={{ p: 2 }}>
            {intl.formatMessage({ id: "no_notifications" })}
          </Typography>
        )}
      </Menu>
    </>
  );
}
