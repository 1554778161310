import { initializeApp } from "firebase/app";
import {
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  // linkWithCredential,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";

import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  Timestamp,
  query,
  where,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_DEV_API_KEY,
  authDomain: process.env.REACT_APP_DEV_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
  projectId: process.env.REACT_APP_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_DEV_APP_ID,
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(
  process.env.NODE_ENV === "development" ? firebaseConfigDev : firebaseConfig
);

export const auth = getAuth(app);

export const db = getFirestore(app);

const functions = getFunctions(app);

export const acceptMembership = httpsCallable(functions, "dbMembershipsAccept");

export const addToCart = httpsCallable(functions, "dbCartAddToCart");

export const checkout = httpsCallable(functions, "dbCartCheckout");

export const fetchPickList = httpsCallable(functions, "dbOrdersFetchPickList");

export const batchMemberships = httpsCallable(functions, "dbMembershipsBatch");

export const batchProducts = httpsCallable(functions, "dbProductsBatch");

export const declineMembership = httpsCallable(
  functions,
  "dbMembershipsDecline"
);

export const deleteProducts = httpsCallable(functions, "dbProductsDelete");

export function removeUser() {
  return deleteUser(auth.currentUser);
}

export const fetchArchivedOrders = (vendorId) => {
  const orders = query(
    collection(db, "orders"),
    where("vendorId", "==", vendorId),
    where("status", "==", "archived")
  );
  return getDocs(orders);
};

export async function fetchDoc(collection, id) {
  const snap = await getDoc(doc(db, collection, id));
  if (snap.exists()) return { ...snap.data(), id };
  return undefined;
}

export const fetchOrderLines = (orderId) => {
  const lines = query(collection(db, `orders/${orderId}/lines`));
  return getDocs(lines);
};

export const fetchReserves = (planId) => {
  const reserves = query(
    collection(db, "products"),
    where("isAvailable", "==", true),
    where("plans", "array-contains", planId)
  );
  return getDocs(reserves);
};

export function forgotPassword(email) {
  return sendPasswordResetEmail(auth, email, {
    url: `http://localhost:3000/login`,
  });
}

export const geofire = require("geofire-common");

export function login(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logout() {
  return signOut(auth);
}

export const membersAdd = httpsCallable(functions, "dbMembersAdd");

export const membershipsCancel = httpsCallable(
  functions,
  "dbMembershipsCancel"
);

export const membershipsCancelInvite = httpsCallable(
  functions,
  "dbMembershipsCancelInvite"
);

export const membershipsInvite = httpsCallable(
  functions,
  "dbMembershipsInvite"
);

export function register(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function removeDoc(collection, id) {
  return deleteDoc(doc(db, collection, id));
}

export function resetPassword(oobCode, newPassword) {
  return confirmPasswordReset(auth, oobCode, newPassword).then((response) =>
    console.log({ response })
  );
}

export function signInWithFacebook() {
  const provider = new FacebookAuthProvider();
  return signInWithPopup(auth, provider);
}

export function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    display: "popup",
  });
  return signInWithPopup(auth, provider);
}

export const updateAgreements = httpsCallable(functions, "dbAgreementsUpdate");

export const updateDoc = (collection, id, payload) =>
  setDoc(doc(db, collection, id), payload, { merge: true });

export const toDate = (stamp) => {
  if (typeof stamp === "string") return stamp;
  if (stamp instanceof Timestamp) return stamp.toDate().toString();
  return Timestamp.now().toDate().toString();
};

export const now = () => Timestamp.now().toMillis();
