import { PURGE } from 'redux-persist';
import { createAsyncThunk, createSelector, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { logout, refreshSite, selectUserId, setSnackbar } from './index';
import * as api from '../apis/firebase';

const usersAdapter = createEntityAdapter()

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (_, { dispatch }) => {
    await api.removeUser();
    dispatch(logout()).then();
  }
);

export const resetUser = createAsyncThunk(
  "users/reset",
  async (uid, { dispatch }) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const snap = await api.fetchDoc("users", uid);
    dispatch(upsertOneUser(snap));
    return {
      ...snap,
    };
  }
);

export const updateUser = createAsyncThunk(
  'users/update',
  async ({ id, payload }, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const response = await api.updateDoc('users', id, payload);
    return response;
  }
);

export const users = createSlice({
  name: 'users',
  initialState: usersAdapter.getInitialState(),
  reducers: {
    upsertOneUser: {
      reducer: (state, action) => usersAdapter.upsertOne(state, action.payload),
      prepare: (data) => {
        if (data) {
          const { at, coordinates, g, geoPoint, position, updated, uid, ...rest } = data;
          return {
            payload: {              
              id: uid,
              uid,
              updated: api.toDate(updated),
              ...rest
            }          
          }
        }
      },
    },
    removeOneUser: usersAdapter.removeOne,
    removeAllUser: usersAdapter.removeAll
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        usersAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        usersAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        usersAdapter.removeAll(state);
      })
  },
});

export const { 
  upsertOneUser, 
  removeOneUser,
  removeAllUser
} = users.actions;

export const usersSelectors = usersAdapter.getSelectors((state) => state.users);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = usersSelectors;

export const selectUsers = state => state.users;

export const selectUserExtended = (state, userId) => Object.values(state.users.entities).filter(entity => entity.userId === userId);

export const selectProfile = createSelector(
  [selectUserId, selectEntities, state => state],
  (uid, entities, state) => Object.values(entities).find(entity => entity.uid === uid) ?? state
)

export default users.reducer;
