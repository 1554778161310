import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from "redux-persist/lib/storage";
import agreements from "./agreements";
import cart from "./cart";
import contacts from "./contacts";
import dialogs from "./dialogs";
import filters from "./filters";
import forms from "./forms";
import invitations from "./invitations";
import locations from "./locations";
import locale from "./locale";
import places from "./places";
import orders from "./orders";
import memberships from "./memberships";
import notifications from "./notifications";
import products from "./products";
import salesChannels from "./salesChannels";
import session from "./session";
import site from "./site";
import snackbars from "./snackbars";
import users from "./users";
import vendors from "./vendors";

const persistConfig = { key: "root", storage };
const agreementsPersistConfig = { key: "agreements", storage };
const contactsPersistConfig = { key: "contacts", storage };
const cartPersistConfig = { key: "cart", storage };
const invitationsPersistConfig = { key: "invitations", storage };
const localePersistConfig = { key: "locale", storage };
const locationsPersistConfig = { key: "locations", storage };
const sessionPersistConfig = { key: "session", storage };
const salesChannelsPersistConfig = { key: "salesChannels", storage };
const sitePersistConfig = { key: "site", storage };
const snackbarsPersistConfig = { key: "snackbars", storage };
const formsPersistConfig = { key: "forms", storage };
const filtersPersistConfig = { key: "filters", storage };
const placesPersistConfig = { key: "places", storage };
const ordersPersistConfig = { key: "orders", storage };
const membershipsPersistConfig = { key: "memberships", storage };
const notificationsPersistConfig = { key: "notifications", storage };
const productsPersistConfig = { key: "products", storage };
const usersPersistConfig = { key: "users", storage };
const vendorsPersistConfig = { key: "vendors", storage };

const reducers = combineReducers({
  agreements: persistReducer(agreementsPersistConfig, agreements),
  cart: persistReducer(cartPersistConfig, cart),
  contacts: persistReducer(contactsPersistConfig, contacts),
  dialogs,
  invitations: persistReducer(invitationsPersistConfig, invitations),
  locale: persistReducer(localePersistConfig, locale),
  locations: persistReducer(locationsPersistConfig, locations),
  filters: persistReducer(filtersPersistConfig, filters),
  forms: persistReducer(formsPersistConfig, forms),
  salesChannels: persistReducer(salesChannelsPersistConfig, salesChannels),
  session: persistReducer(sessionPersistConfig, session),
  site: persistReducer(sitePersistConfig, site),
  snackbars: persistReducer(snackbarsPersistConfig, snackbars),
  places: persistReducer(placesPersistConfig, places),
  orders: persistReducer(ordersPersistConfig, orders),
  memberships: persistReducer(membershipsPersistConfig, memberships),
  notifications: persistReducer(notificationsPersistConfig, notifications),
  products: persistReducer(productsPersistConfig, products),
  users: persistReducer(usersPersistConfig, users),
  vendors: persistReducer(vendorsPersistConfig, vendors),
});

const rootReducer = (state, action) => {
  if (action.type === "session/logout/fulfilled") {
    storage.removeItem("persist:agreements");
    storage.removeItem("persist:contacts");
    storage.removeItem("persist:cart");
    storage.removeItem("persist:invitations");
    storage.removeItem("persist:locations");
    storage.removeItem("persist:salesChannels");
    storage.removeItem("persist:session");
    storage.removeItem("persist:snackbars");
    storage.removeItem("persist:places");
    storage.removeItem("persist:orders");
    storage.removeItem("persist:memberships");
    storage.removeItem("persist:notifications");
    storage.removeItem("persist:products");
    storage.removeItem("persist:filters");
    storage.removeItem("persist:forms");
    storage.removeItem("persist:users");
    storage.removeItem("persist:vendors");
    // return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export let persistor = persistStore(store);

export default store;
