import { useSelector } from "react-redux";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import Admin from "./pages/Admin";
import Bugs from "./pages/Bugs";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import DashboardLayout from "./layouts/dashboard";
import LandingLayout from "./layouts/landing";
import Enrolment from "./pages/Enrolments/Enrolment";
import Enrolments from "./pages/Enrolments/Enrolments";
import Home from "./pages/Home";
import MembershipInvite from "./pages/Invites/MembershipInvite";
import Login from "./pages/Login";
import NotFound from "./pages/Page404";
import Plan from "./pages/Memberships/Plan";
import Product from "./pages/Home";
import Products from "./pages/Products";
import MembershipForm from "./pages/Memberships/Membership";
import Memberships from "./pages/Memberships/Memberships";
import Order from "./pages/Orders/Order";
import Orders from "./pages/Orders";
import Purchase from "./pages/Purchases/Purchase";
import Purchases from "./pages/Purchases";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import ShopMemberships from "./components/_shop/products/Memberships";
import ShopProducts from "./components/_shop/products/Products";
import ShopProduct from "./components/_shop/products/Product";
import User from "./pages/User";
import VendorForm from "./pages/VendorForm";
import { useLocation } from "react-router-dom";
import { selectIsLoggedIn } from "./store";

export default function Router() {
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  let routes = [];
  switch (true) {
    case isLoggedIn:
      routes = [
        {
          element: <DashboardLayout />,
          children: [
            { path: "/", element: <Products /> },
            { path: "admin", element: <Admin /> },
            { path: "cart", element: <Cart /> },
            { path: "checkout", element: <Checkout /> },
            {
              path: "enrolments",
              element: <Outlet />,
              children: [
                { path: "", element: <Enrolments /> }, // enrolments
                {
                  path: ":planId",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <Enrolment /> }, // enrolment
                  ],
                },
              ],
            },
            {
              path: "Info",
              element: <Outlet />,
              children: [
                { path: "", element: <Home /> },
                { path: "bugs", element: <Bugs /> },
              ],
            },
            { 
              path: "invitations/:inviteId/type/membership",
              element: <MembershipInvite />,
            },
            { path: "login", element: <Navigate to="/profile" /> },
            {
              path: "products",
              element: <Outlet />,
              children: [
                { path: "", element: <Products /> }, // products
                {
                  path: ":id",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <Product /> }, // product
                    { path: "edit", element: <Home /> }, // product edit
                  ],
                }, // product
              ],
            },
            { path: "/profile", element: <Profile /> },
            {
              path: "purchases",
              element: <Outlet />,
              children: [
                { path: "", element: <Purchases /> }, // purchases
                { path: ":id", element: <Purchase /> }, // purchase
              ],
            },
            {
              path: "users",
              element: <Outlet />,
              children: [
                { path: "", element: <Home /> }, // users
                {
                  path: ":id",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <User /> }, // user
                    { path: "edit", element: <Home /> }, // user edit
                    {
                      path: "memberships",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Memberships /> }, // users memberships
                        {
                          path: ":membershipId",
                          element: <Outlet />,
                          children: [
                            { path: "", element: <Product /> }, // product
                            { path: "edit", element: <Home /> }, // product edit
                          ],
                        }, // product
                      ],
                    },
                    {
                      path: "orders",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Orders /> }, // user orders
                        {
                          path: ":id",
                          element: <Outlet />,
                          children: [
                            { path: "", element: <Order /> }, // user order
                            { path: "edit", element: <Home /> }, // user order edit
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "vendors",
              element: <Outlet />,
              children: [
                { path: "", element: <Home /> }, // vendors
                {
                  path: ":vendorId",
                  element: <Outlet />,
                  children: [
                    { path: "", element: <Home /> }, // vendor
                    { path: "edit", element: <VendorForm /> }, // vendor edit
                    {
                      path: "memberships",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <ShopMemberships /> }, // vendors memberships
                        {
                          path: ":membershipId",
                          element: <Outlet />,
                          children: [
                            { path: "", element: <Home /> }, // vendor membership
                            { path: "edit", element: <MembershipForm /> }, // vendor membership edit
                            {
                              path: "members",
                              element: <Outlet />,
                              children: [
                                { path: "", element: <Memberships /> }, // users memberships
                                { path: ":memberId", element: <Outlet /> }, // product
                              ],
                            },
                            {
                              path: "plans",
                              element: <Outlet />,
                              children: [
                                { path: "", element: <Memberships /> }, // users memberships
                                { path: ":planId", element: <Plan /> }, // product
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "orders",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <Orders /> }, // vendors orders
                        {
                          path: ":orderId",
                          element: <Outlet />,
                          children: [
                            { path: "", element: <Order /> }, // vendor order
                            { path: "edit", element: <Order /> }, // order edit
                          ],
                        },
                      ],
                    },
                    {
                      path: "products",
                      element: <Outlet />,
                      children: [
                        { path: "", element: <ShopProducts /> }, // vendors products
                        {
                          path: ":id",
                          element: <Outlet />,
                          children: [
                            { path: "", element: <Home /> }, // vendor product
                            { path: "edit", element: <ShopProduct /> }, // vendor product edit
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" state={{ from: location.pathname }} replace /> },
      ];
      break;
    default:
      routes = [
        {
          element: <LandingLayout />,
          children: [
            { path: "/", element: <Home /> },
            { path: "login", element: <Login /> },
          ],
        },
        {
          path: "invitations/:inviteId/type/membership",
          element: <MembershipInvite />,
        },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ];
  }

  return useRoutes(routes);
}
