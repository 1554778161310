import { useSelector } from "react-redux";
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import { selectLinesByVendorId } from "../../store";
import Line from "./Line";

export default function CartVendor({ vendor }) {
  const { subTotal = 0, tax = 0, total = 0, vendorId, vendorName, vendorPhoto } = vendor;

  const lines = useSelector((state) => selectLinesByVendorId(state, vendorId));

  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <Avatar alt={vendorName} src={vendorPhoto} />
        </ListItemAvatar>
        <ListItemText primary={vendorName} />
      </ListItem>
      <List>
        {lines.map((line, i) => (
          <Line
            line={line}
            vendorId={vendorId}
            key={i}
          />
        ))}
      </List>
      <List>
        <ListItem>
          <ListItemSecondaryAction>
            <ListItemText
              primary={`${
                subTotal
                  ? `Subtotal $${parseFloat(subTotal).toFixed(2)}`
                  : `$0.00`
              }`}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <ListItemSecondaryAction>
            <ListItemText
              primary={`${
                tax ? `Tax $${parseFloat(tax).toFixed(2)}` : `Tax $0.00`
              }`}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem sx={{ marginTop: 2 }}>
          <ListItemSecondaryAction>
            <ListItemText
              primary={
                total
                  ? `${vendorName} Total $${parseFloat(total).toFixed(2)}`
                  : `Total $0.00`
              }
              style={{ borderTop: "1px solid grey" }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />
    </>
  );
}
