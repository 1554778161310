/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useEffect, useState, useReducer, useMemo, useCallback } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import { collection, doc } from "firebase/firestore";
import { db } from "../../../apis/firebase";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";
import { GridEditInputCell, DataGrid } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AvatarImageField from "../../AvatarImageField";
import DataGridImageEdit from "../../DataGridImageEdit";
import {
  batchProducts,
  deleteProducts,
  initMembership,
  salesChannelsSelectors,
  selectCategories,
  selectStatuses,
  selectWeightUnits,
  selectPlansByVendor,
  selectProductsByVariant,
  removeManyProducts,
  addOneOption,
  upsertOneOption,
  resetProduct,
  removeOneOption,
  selectTimeout,
  updateDoc,
  updateOneProduct,
  upsertOneProduct,
  upsertParentAndChildren,
} from "../../../store";
import { useNavigate } from "react-router-dom";

const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: theme.spacing(1),
  gridTemplateRows: "auto",
  gridTemplateAreas: `"main"
  "footer"
  "sidebar"`,
  [theme.breakpoints.up("sm")]: {
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateAreas: `"main main sidebar"
    "main main sidebar"
    "footer footer footer"`,
  },
  [theme.breakpoints.up("md")]: {
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateAreas: `"main main main sidebar"
    "main main main sidebar"
    "footer footer footer footer"`,
  },
}));

const CurrencyInput = ({ field, form, meta, ...props }) => {
  const intl = useIntl();
  const { currency, label } = props;
  const { name, value = 0 } = field;
  const { touched, errors } = form;
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <TextField
          {...field}
          {...props}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          fullWidth
          label={intl.formatMessage({ id: label })}
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={() => setShow(false)}
          value={value}
        />
      )}
      {!show && (
        <TextField
          fullWidth
          label={intl.formatMessage({ id: label })}
          onFocus={() => setShow(true)}
          value={intl.formatNumber(value, {
            style: "currency",
            currency: currency || "CAD",
          })}
        />
      )}
    </>
  );
};

const EMPTY_ARR = [];

const initialState = {
  optionHeadings: [],
  profit: 0.0,
  margin: 0,
  src: undefined,
  imageId: undefined,
  image: undefined,
  isVariantsTouched: false,
  deleteDialogOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "srcChanged":
      return {
        ...state,
        src: action.payload,
      };
    case "childOptionsChanged":
      const { childOptions = {} } = action.payload;
      const optionHeadings = Object.values(childOptions).map(
        ({ key, optionId }) => ({
          field: optionId,
          headerName: key,
          type: "string",
          width: 180,
          renderCell: ({ row }) => {
            return (
              <div onClick={(e) => e.preventDefault()}>
                {row?.options?.entities[optionId]?.value || ""}
              </div>
            );
          },
        })
      );
      return {
        ...state,
        optionHeadings,
      };
    case "priceOrCostPerItemChanged":
      const { price, costPerItem } = action.payload;
      const _price = price ? parseFloat(price) : 0;
      const _costPerItem = costPerItem ? parseFloat(costPerItem) : 0;
      const _profit = (parseFloat(_price) - parseFloat(_costPerItem)).toFixed(
        2
      );
      const _margin =
        parseFloat(_price) > 0
          ? ((parseFloat(_profit) / parseFloat(_price)) * 100).toFixed(2)
          : 0;
      return {
        ...state,
        margin: _margin,
        profit: _profit,
      };
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

export default function ProductForm({ product }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { vendorId } = product;

  const availableSalesChannels = useSelector(salesChannelsSelectors.selectAll);
  const availableCategories = useSelector(selectCategories);
  const weightUnits = useSelector(selectWeightUnits);
  const statuses = useSelector(selectStatuses);
  const productTimeout = useSelector(selectTimeout);
  const variants = useSelector((state) =>
    selectProductsByVariant(state, product.id)
  );

  const [{ margin, profit, deleteDialogOpen }, _dispatch] = useReducer(
    reducer,
    initialState
  );

  const handleDatePickerAvailableFromOnChange = (value) => {
    setFieldValue("availableFrom", value);
  };

  const handleDatePickerAvailableToOnChange = (value) => {
    setFieldValue("availableTo", value);
  };

  const {
    available = 0,
    availableTo,
    availableFrom,
    barcode = "",
    categories = [],
    childOptions = { id: [], entities: {} },
    continueSellingWhenOutOfStock = false,
    compareAtPrice = 0,
    costPerItem,
    description = "",
    displayAvailableInventoryToCustomers = false,
    hasVariants = false,
    id = "",
    isPhysical = false,
    isTaxed = false,
    maxPerOrder = "",
    memberships = [],
    name = "",
    options = { id: [], entities: {} },
    parentId = "",
    parentName = "",
    price = 0,
    productPhoto,
    productPhotoId = "",
    salesChannels = [],
    sku = "",
    subscriptions = [],
    status = "draft",
    tags = [],
    trackQuantity = false,
    uom = "",
    weight = 0,
    weightUnit = "",
    ...rest
  } = product;

  const ProductSchema = Yup.object().shape({
    parentName: Yup.string().required(intl.formatMessage({ id: "required" })),
    productPhoto: Yup.string().required(intl.formatMessage({ id: "required" })),
    price: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const formik = useFormik({
    initialValues: {
      availableFrom,
      availableTo,
      id,
      parentId,
      parentName,
      description,
      productPhoto,
      productPhotoId,
      price,
      uom,
      isTaxed,
      salesChannels,
      trackQuantity,
      continueSellingWhenOutOfStock,
      displayAvailableInventoryToCustomers,
      maxPerOrder,
      available,
      status,
      categories,
      tags,
      sku,
      barcode,
      isPhysical,
      weight,
      hasVariants,
      weightUnit,
      compareAtPrice,
      costPerItem,
      selectedMemberships: [],
      subscriptions,
      memberships,
      productName: name,
      options,
      childOptions,
      variants,
      ...rest,
    },
    validationSchema: ProductSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const { ids, entities } = childOptions;

  const plans = useSelector((state) => selectPlansByVendor(state, vendorId));

  useEffect(() => {
    _dispatch({ type: "srcChanged", payload: productPhoto });
    setFieldValue("productPhoto", productPhoto);
  }, [productPhoto]);

  useEffect(() => {
    const { variants, childOptions, productName, ...rest } = values;
    const timeoutId = setTimeout(
      () =>
        dispatch(
          upsertParentAndChildren({
            id,
            name: productName,
            ...rest,
          })
        ),
      productTimeout
    );
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    _dispatch({
      type: "priceOrCostPerItemChanged",
      payload: { price, costPerItem },
    });
  }, [price, costPerItem]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(batchProducts({ parentId: id, useSnackbar: true}));
  };

  const resetForm = () => {
    formik.resetForm();
    dispatch(resetProduct(id));
  };

  const handleCancelDialog = () =>
    _dispatch({ type: "stateChanged", payload: { deleteDialogOpen: false } });

  const handleOk = async () => {
    const deleteIds = [id, ...variants.map((variant) => variant.id)];
    dispatch(deleteProducts(deleteIds));
    await formik.resetForm();
    await dispatch(removeManyProducts(deleteIds));
    await _dispatch({
      type: "stateChanged",
      payload: { isVariantsTouched: false, deleteDialogOpen: false },
    });
    navigate(`/vendors/${vendorId}/products`);
  };

  const handleDelete = () => {
    _dispatch({ type: "stateChanged", payload: { deleteDialogOpen: true } });
  };

  const handleCheckboxSalesChannels = (v) => {
    if (values.salesChannels.indexOf(v) === -1) {
      setFieldValue("salesChannels", [...values.salesChannels, v]);
    } else {
      setFieldValue(
        "salesChannels",
        values.salesChannels.filter((sc) => sc !== v)
      );
    }
  };

  const handleRemoveOption = (optionId) => {
    _dispatch({ type: "stateChanged", payload: { isVariantsTouched: true } });
    dispatch(removeOneOption({ parentId: id, productId: id, optionId }));
  };
  const handleOnInputChangeOption = (payload) => {
    _dispatch({ type: "stateChanged", payload: { isVariantsTouched: true } });
    dispatch(upsertOneOption({ ...payload, parentId: id, productId: id }));
  };

  const handleAddOption = (e) => {
    _dispatch({ type: "stateChanged", payload: { isVariantsTouched: true } });
    dispatch(addOneOption({ productId: id }));
  };

  const handleCellDoubleClick = useCallback((a, b) => {
    _dispatch({ type: "stateChanged", payload: { isVariantsTouched: true } });
    const { field, id } = a;
    b.preventDefault();
    if (field === "productPhoto") {
      _dispatch({
        type: "stateChanged",
        payload: {
          imageId: true,
          image: id,
        },
      });
    }
  }, []);

  const processRowUpdate = useCallback(
    async (newRow) => {
      const { productPhoto, ...rest } = newRow;
      const response = await dispatch(upsertOneProduct(rest));
      return response.payload; // return the new row with the row id in it response.payload is { id, ...rest }
    },
    [dispatch]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log({ error, children: error.message, severity: "error" });
  }, []);

  const handleCellEditCommit = useCallback(
    ({ id, field, value }) => {
      _dispatch({ type: "stateChanged", payload: { isVariantsTouched: true } });
      if (field !== "productPhoto") {
        dispatch(upsertOneProduct({ [field]: value, id }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleAddNewItem = useCallback(async () => {
    const docRef = doc(collection(db, "memberships"));
    await dispatch(
      updateDoc({
        collection: "memberships",
        id: docRef.id,
        payload: {
          id: docRef.id,
          vendorId,
          parentId: docRef.id,
          status: "draft",
        },
      })
    );
    await dispatch(initMembership({ planId: docRef.id }));
    navigate(`/vendors/${vendorId}/memberships/${docRef.id}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: intl.formatMessage({ id: "name" }),
        type: "string",
        width: 180,
        editable: true,
      },
      {
        field: "productPhoto",
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        width: 120,
        editable: true,
        renderCell: ({ id: variantId, value }) => {
          return (
            <DataGridImageEdit
              name="productPhoto"
              disabled={!true}
              uid={variantId}
              change={({ downloadURL, fileName }) => {
                dispatch(
                  upsertOneProduct({
                    id: variantId,
                    productPhoto: downloadURL,
                    productPhotoId: fileName,
                  })
                );
              }}
              initialized={true}
              intl={intl}
              path={"products"}
              src={value}
              required
            />
          );
        },
      },
      {
        field: "price",
        headerName: intl.formatMessage({ id: "price" }),
        type: "number",
        width: 120,
        editable: true,
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              max: 10,
              min: 0,
            }}
          />
        ),
        valueFormater: ({ value = 0 }) =>
          intl.formatNumber(value, {
            style: "currency",
            currency: "CAD",
          }),
      },
      {
        field: "available",
        headerName: intl.formatMessage({ id: "available" }),
        type: "number",
        width: 120,
        editable: true,
      },
      ...Object.values(entities).map(({ key, optionId }) => ({
        field: optionId,
        headerName: key,
        type: "string",
        width: 180,
        renderCell: ({ row }) => {
          return (
            <div onClick={(e) => e.preventDefault()}>
              {row?.options?.entities[optionId]?.value || ""}
            </div>
          );
        },
      })),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [entities, ids]
  );

  const processProductPlanRowUpdate = useCallback(
    async (newRow) => {
      const response = await dispatch(updateOneProduct(newRow));
      return response.payload; // return the new row with the row id in it response.payload is { id, ...rest }
    },
    [dispatch]
  );

  const handleCheckboxChange = (event, params) => {
    const { plans = [], ...rest } = params.row;
    // params.colDef.field
    if (event.target.checked) {
      processProductPlanRowUpdate({
        ...rest,
        plans: [...plans, params.colDef.field],
      });
      dispatch(
        updateOneProduct({
          ...params.row,
          plans: [...plans, params.colDef.field],
        })
      );
    } else {
      const newPlans = plans.filter((plan) => plan !== params.colDef.field);
      processProductPlanRowUpdate({ ...rest, plans: newPlans });
      dispatch(
        updateOneProduct({
          ...rest,
          plans: newPlans,
        })
      );
    }
  };

  const renderProductPlansCheckboxEditInputCell = (params) => {
    const planId = params.colDef.field;
    const { plans = [] } = params.row;
    const isChecked = Boolean(plans.includes(planId));
    return (
      <Checkbox
        checked={isChecked}
        onClick={(e) => handleCheckboxChange(e, params)}
      />
    );
  };

  const planColumns = useMemo(
    () => [
      {
        headerName: intl.formatMessage({ id: "name" }),
        field: "name",
        type: "string",
      },
      ...plans.map(({ id: planId, name: planName, }) => {
        return {
          editable: true,
          field: `${planId}`,
          flex: 1,
          headerName: planName,
          renderCell: renderProductPlansCheckboxEditInputCell,
        };
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans]
  );

  return (
    <Container maxWidth="md">
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          onKeyPress={(e) => {
            e.key === "Enter" && e.preventDefault();
          }}
        >
          <RootStyle>
            <Box sx={{ gridArea: "main" }}>
              <Grid container>
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <TextField
                          type="text"
                          fullWidth
                          label={intl.formatMessage({ id: "name" })}
                          {...getFieldProps("productName")}
                          onChange={formik.handleChange}
                          value={values.productName}
                          error={Boolean(touched.name && errors.name)}
                          helperText={touched.name && errors.name}
                          placeholder={intl.formatMessage({
                            id: "placeholder_product_name",
                          })}
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          type={"text"}
                          fullWidth
                          label={intl.formatMessage({ id: "description" })}
                          {...getFieldProps("description")}
                          onChange={formik.handleChange}
                          value={values.description}
                          error={Boolean(
                            touched.description && errors.description
                          )}
                          helperText={touched.description && errors.description}
                          multiline
                          rows={3}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title={intl.formatMessage({ id: "media" })} />
                      <CardContent>
                        {id && (
                          <AvatarImageField
                            name="productPhoto"
                            disabled={!true}
                            uid={id}
                            change={({ downloadURL, fileName }) => {
                              setFieldValue("productPhoto", downloadURL);
                              setFieldValue("productPhotoId", fileName);
                            }}
                            initialized={true}
                            intl={intl}
                            path={"products"}
                            src={values.productPhoto}
                            required
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "pricing" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={8}>
                            <Field
                              name="price"
                              placeholder="0.00"
                              label="price"
                              component={CurrencyInput}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              label={intl.formatMessage({ id: "uom" })}
                              {...getFieldProps("uom")}
                              onChange={formik.handleChange}
                              value={values.uom}
                              error={Boolean(touched.uom && errors.uom)}
                              helperText={touched.uom && errors.uom}
                              placeholder={intl.formatMessage({
                                id: "placeholder_uom",
                              })}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name="costPerItem"
                              label="cost_per_item"
                              component={CurrencyInput}
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              name="compareAtPrice"
                              label="compare_at_price"
                              component={CurrencyInput}
                            />
                          </Grid>
                          <Grid item container spacing={2}>
                            <Grid item>
                              <Typography variant="h6" gutterBottom>
                                {intl.formatMessage({ id: "margin" })}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {margin !== "--" ? `${margin}%` : "--"}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" gutterBottom>
                                {intl.formatMessage({ id: "profit" })}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                {intl.formatNumber(profit, {
                                  style: "currency",
                                  currency: "CAD",
                                })}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("isTaxed")}
                                  checked={values.isTaxed || false}
                                  onChange={formik.handleChange}
                                  name="isTaxed"
                                  value={isTaxed}
                                />
                              }
                              label={intl.formatMessage({ id: "taxed_label" })}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "inventory" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <TextField
                              label={intl.formatMessage({ id: "sku_label" })}
                              {...getFieldProps("sku")}
                              onChange={formik.handleChange}
                              value={values.sku}
                              error={Boolean(touched.sku && errors.sku)}
                              helperText={touched.sku && errors.sku}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="barcode"
                              variant="outlined"
                              label={intl.formatMessage({
                                id: "barcode_label",
                              })}
                              value={values.barcode}
                              onChange={formik.handleChange}
                              size="small"
                              {...getFieldProps("barcode")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Card>
                              <CardHeader
                                title={
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        {...getFieldProps("trackQuantity")}
                                        checked={values.trackQuantity || false}
                                        onChange={formik.handleChange}
                                        name="trackQuantity"
                                        value={values.trackQuantity}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: "track_quantity_label",
                                    })}
                                  />
                                }
                              />
                              <CardContent>
                                {values.trackQuantity && (
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            {...getFieldProps(
                                              "continueSellingWhenOutOfStock"
                                            )}
                                            checked={
                                              values.continueSellingWhenOutOfStock ||
                                              false
                                            }
                                            onChange={formik.handleChange}
                                            name="continueSellingWhenOutOfStock"
                                            value={
                                              values.continueSellingWhenOutOfStock
                                            }
                                          />
                                        }
                                        label={intl.formatMessage({
                                          id: "continue_selling_when_out_of_stock_label",
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            {...getFieldProps(
                                              "displayAvailableInventoryToCustomers"
                                            )}
                                            checked={
                                              values.displayAvailableInventoryToCustomers ||
                                              false
                                            }
                                            onChange={formik.handleChange}
                                            name="displayAvailableInventoryToCustomers"
                                            value={
                                              values.displayAvailableInventoryToCustomers
                                            }
                                          />
                                        }
                                        label={intl.formatMessage({
                                          id: "availability_visible_label",
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        name="maxPerOrder"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{
                                          min: 0,
                                        }}
                                        label={intl.formatMessage({
                                          id: "max_per_order_label",
                                        })}
                                        helperText={intl.formatMessage({
                                          id: "max_per_order_helper_text",
                                        })}
                                        value={values.maxPerOrder || ""}
                                        onChange={formik.handleChange}
                                        size="small"
                                        {...getFieldProps("maxPerOrder")}
                                      />
                                    </Grid>
                                    <Grid item xs={12}>
                                      <TextField
                                        name="available"
                                        variant="outlined"
                                        type="number"
                                        inputProps={{
                                          min: 0,
                                        }}
                                        label={intl.formatMessage({
                                          id: "available_label",
                                        })}
                                        value={values.available}
                                        onChange={formik.handleChange}
                                        size="small"
                                        {...getFieldProps("available")}
                                      />
                                    </Grid>
                                  </Grid>
                                )}
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("isPhysical")}
                                  checked={values.isPhysical || false}
                                  onChange={formik.handleChange}
                                  name="isPhysical"
                                  value={values.isPhysical}
                                />
                              }
                              label={intl.formatMessage({
                                id: "is_physical_label",
                              })}
                            />
                          </Grid>
                          {isPhysical && (
                            <>
                              <Grid item xs={8}>
                                <TextField
                                  fullWidth
                                  name="weight"
                                  variant="outlined"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                  }}
                                  label={intl.formatMessage({
                                    id: "weight_label",
                                  })}
                                  helperText={intl.formatMessage({
                                    id: "weight_hint",
                                  })}
                                  value={values.weight}
                                  onChange={formik.handleChange}
                                  size="small"
                                  {...getFieldProps("weight")}
                                />
                              </Grid>
                              {weightUnits?.length > 0 && (
                                <Grid item xs={4}>
                                  <FormControl>
                                    <Select
                                      name="weightUnit"
                                      labelId="demo-simple-select-placeholder-label-label"
                                      id="demo-simple-select-placeholder-label"
                                      value={values.weightUnit}
                                      onChange={formik.handleChange}
                                      displayEmpty
                                    >
                                      {weightUnits.map((unit, i) => (
                                        <MenuItem key={i} value={unit}>
                                          {intl.formatMessage({ id: unit })}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              )}
                            </>
                          )}

                          <Grid item>
                            {values.isPhysical ? null : (
                              <Typography variant="body1" gutterBottom>
                                {intl.formatMessage({
                                  id: "not_physical_message",
                                })}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "sidebar" }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "product_status" })}
                      />
                      <CardContent>
                        <FormControl sx={{ mb: 2 }}>
                          <Select
                            name="status"
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={values.status || "draft"}
                            onChange={formik.handleChange}
                          >
                            {statuses.map((status, i) => (
                              <MenuItem key={i} value={status}>
                                {intl.formatMessage({ id: status })}
                              </MenuItem>
                            ))}
                          </Select>
                          {values.status === "draft" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "product_status_hint",
                              })}
                            </FormHelperText>
                          )}
                          {status === "active" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "product_status_active_hint",
                              })}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "organization" })}
                      />
                      <CardContent>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            {values.categories && availableCategories && (
                              <Autocomplete
                                multiple
                                id={"categories"}
                                options={
                                  availableCategories
                                    ? availableCategories.filter(
                                        (category) => category !== "all"
                                      )
                                    : EMPTY_ARR
                                }
                                getOptionLabel={(option) =>
                                  intl.formatMessage({ id: option })
                                }
                                onChange={(e, value) =>
                                  setFieldValue("categories", value)
                                }
                                value={values.categories || EMPTY_ARR}
                                renderTags={(value, getTagProps) =>
                                  value.map((option, index) => (
                                    <Chip
                                      variant="outlined"
                                      label={intl.formatMessage({ id: option })}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label={intl.formatMessage({
                                      id: `categories`,
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "add_category_placeholder",
                                    })}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id={"tags"}
                              onChange={(e, value) =>
                                setFieldValue("tags", value)
                              }
                              options={EMPTY_ARR}
                              freeSolo
                              value={values.tags || EMPTY_ARR}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    variant="outlined"
                                    label={option}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  variant="outlined"
                                  label={intl.formatMessage({ id: `tags` })}
                                  placeholder={intl.formatMessage({
                                    id: "add_tag_placeholder",
                                  })}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Divider />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid
                          item
                          container
                          spacing={2}
                          justifyContent="center"
                        >
                          <Grid item>
                            <LoadingButton
                              type="submit"
                              variant="contained"
                              loading={isSubmitting}
                              disabled={formik.isSubmitting}
                              startIcon={<SaveIcon />}
                            >
                              {intl.formatMessage({ id: "save" })}
                            </LoadingButton>
                          </Grid>
                          <Grid item>
                            <LoadingButton
                              variant="contained"
                              onClick={resetForm}
                              startIcon={<RefreshIcon />}
                            >
                              {intl.formatMessage({ id: "reset" })}
                            </LoadingButton>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={handleDelete}
                              startIcon={<DeleteIcon />}
                            >
                              {intl.formatMessage({ id: "delete" })}
                            </Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "footer" }}>
              <Grid container spacing={{ xs: 2 }}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "variants" })}
                    />
                    <CardContent>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.hasVariants}
                            onChange={formik.handleChange}
                            name="hasVariants"
                          />
                        }
                        label={intl.formatMessage({
                          id: "has_variants_label",
                        })}
                      />
                      {values.hasVariants && (
                        <Grid container>
                          <Divider />
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                              {intl.formatMessage({ id: "options" })}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid item container xs={12}>
                              {Object.values(entities).map(
                                ({ optionId }, index) => (
                                  <Grid container item xs={12} key={index}>
                                    <Grid
                                      item
                                      xs={12}
                                      sx={{ textAlign: "right", mt: 2 }}
                                    >
                                      <FormLabel
                                        sx={{
                                          ml: "auto",
                                          marginBottom: 1,
                                        }}
                                      >
                                        <Button
                                          color="secondary"
                                          onClick={() =>
                                            handleRemoveOption(optionId)
                                          }
                                        >
                                          {intl.formatMessage({
                                            id: "remove",
                                          })}
                                        </Button>
                                      </FormLabel>
                                    </Grid>
                                    <Grid item xs={4} sx={{ pr: 2 }}>
                                      <Autocomplete
                                        id={`option-${index}-name}`}
                                        options={
                                          entities &&
                                          index &&
                                          entities[`option${index}Keys`]
                                            ? entities[`option${index}Keys`]
                                            : EMPTY_ARR
                                        }
                                        onChange={(e, key) =>
                                          handleOnInputChangeOption({
                                            optionId,
                                            key,
                                          })
                                        }
                                        onBlur={(e) =>
                                          handleOnInputChangeOption({
                                            optionId,
                                            key: e.target.value,
                                          })
                                        }
                                        onKeyPress={(e) => {
                                          e.key === "Enter" &&
                                            e.preventDefault();
                                        }}
                                        value={entities[optionId]?.key || ""}
                                        freeSolo
                                        disableClearable
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({
                                              id: `option_${index}_label`,
                                            })}
                                            placeholder={intl.formatMessage({
                                              id: `add_variant_placeholder_${index}`,
                                            })}
                                            helperText={intl.formatMessage({
                                              id: "press_enter_to_update",
                                            })}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Autocomplete
                                        multiple
                                        id={`option-${index}-values`}
                                        onChange={(event, optionValues) =>
                                          handleOnInputChangeOption({
                                            optionId,
                                            optionValues,
                                          })
                                        }
                                        value={
                                          entities[optionId].optionValues || ""
                                        }
                                        freeSolo
                                        options={EMPTY_ARR}
                                        renderTags={(value, getTagProps) =>
                                          value.map((option, index) => (
                                            <Chip
                                              variant="outlined"
                                              label={option}
                                              {...getTagProps({ index })}
                                            />
                                          ))
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="outlined"
                                            label={intl.formatMessage({
                                              id: "add_option_value_label",
                                            })}
                                            placeholder={intl.formatMessage({
                                              id: `add_option_value_placeholder_${index}`,
                                            })}
                                            helperText={intl.formatMessage({
                                              id: "add_option_value_helper_text",
                                            })}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Divider />
                                  </Grid>
                                )
                              )}
                              {entities !== undefined &&
                                Object.keys(entities).length < 3 && (
                                  <Grid item xs={12}>
                                    <Button
                                      variant="outlined"
                                      onClick={() =>
                                        handleAddOption({ productId: id })
                                      }
                                      sx={{ m: 2 }}
                                    >
                                      {intl.formatMessage({
                                        id: "add_another_option",
                                      })}
                                    </Button>
                                  </Grid>
                                )}
                              <Grid item xs={12}>
                                {variants && variants.length > 0 && (
                                  <Card>
                                    <CardHeader
                                      title={intl.formatMessage({
                                        id: "variants",
                                      })}
                                    />
                                    <CardContent>
                                      <DataGrid
                                        name="variants"
                                        autoHeight
                                        rows={variants}
                                        columns={columns}
                                        processRowUpdate={processRowUpdate}
                                        onProcessRowUpdateError={
                                          handleProcessRowUpdateError
                                        }
                                        onCellEditCommit={handleCellEditCommit}
                                        onCellClick={(a, e) =>
                                          e.preventDefault()
                                        }
                                        onCellDoubleClick={(a, b, c) =>
                                          handleCellDoubleClick(a, b, c)
                                        }
                                      />
                                    </CardContent>
                                  </Card>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "sales_channels" })}
                    />
                    <CardContent>
                      {availableSalesChannels.map((salesChannel, i) => {
                        switch (salesChannel.id) {
                          case "online_shop":
                            return (
                              <Grid item xs={12} key={i}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values?.salesChannels?.indexOf(
                                          salesChannel.id
                                        ) !== -1
                                      }
                                      onChange={(e) =>
                                        handleCheckboxSalesChannels(
                                          salesChannel.id
                                        )
                                      }
                                      name={intl.formatMessage({
                                        id: salesChannel.salesChannelsTitleId,
                                      })}
                                    />
                                  }
                                  label={intl.formatMessage({
                                    id: salesChannel.salesChannelsTitleId,
                                  })}
                                />
                                {values?.salesChannels?.indexOf(
                                  salesChannel.id
                                ) !== -1 && (
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                      <Typography>
                                        {intl.formatMessage({
                                          id: "schedule_availability",
                                        })}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <Grid container spacing={2}>
                                          <Grid item xs={12} sm={6}>
                                            <DateTimePicker
                                              disablePast
                                              renderInput={(props) => (
                                                <TextField {...props} />
                                              )}
                                              inputFormat="yyyy/MM/dd hh:mm a"
                                              label={`${intl.formatMessage({
                                                id: "available",
                                              })} ${intl.formatMessage({
                                                id: "from",
                                              })}`}
                                              value={values.availableFrom}
                                              onChange={(newValue) => {
                                                handleDatePickerAvailableFromOnChange(
                                                  Math.floor(
                                                    Number(newValue.getTime())
                                                  )
                                                );
                                              }}
                                              components={{
                                                OpenPickerIcon: AccessTimeIcon,
                                              }}
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            <DateTimePicker
                                              disablePast
                                              renderInput={(props) => (
                                                <TextField {...props} />
                                              )}
                                              inputFormat="yyyy/MM/dd hh:mm a"
                                              label={`${intl.formatMessage({
                                                id: "available",
                                              })} ${intl.formatMessage({
                                                id: "to",
                                              })}`}
                                              value={values.availableTo}
                                              onChange={(newValue) => {
                                                handleDatePickerAvailableToOnChange(
                                                  Math.floor(
                                                    Number(newValue.getTime())
                                                  )
                                                );
                                              }}
                                              components={{
                                                OpenPickerIcon: AccessTimeIcon,
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            );
                          case "memberships":
                            return (
                              <Grid item xs={12} key={i}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values?.salesChannels?.indexOf(
                                          salesChannel.id
                                        ) !== -1
                                      }
                                      onChange={(e) =>
                                        handleCheckboxSalesChannels(
                                          salesChannel.id
                                        )
                                      }
                                      name={intl.formatMessage({
                                        id: salesChannel.salesChannelsTitleId,
                                      })}
                                    />
                                  }
                                  label={intl.formatMessage({
                                    id: salesChannel.salesChannelsTitleId,
                                  })}
                                />
                                {values?.salesChannels?.indexOf(
                                  salesChannel.id
                                ) !== -1 && (
                                  <>
                                    {plans?.length > 0 && (
                                      <DataGrid
                                        autoHeight
                                        name="products"
                                        rows={
                                          variants.length > 0
                                            ? variants
                                            : [product]
                                        }
                                        columns={planColumns}
                                      />
                                    )}
                                    {plans?.length < 1 && (
                                      <Button
                                        color="secondary"
                                        onClick={() => handleAddNewItem()}
                                        startIcon={<AddCircleIcon />}
                                      >
                                        {intl.formatMessage({
                                          id: "new",
                                        })}
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Grid>
                            );
                          default:
                            return (
                              <Grid item xs={12} key={i}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        values?.salesChannels?.indexOf(
                                          salesChannel.id
                                        ) !== -1
                                      }
                                      onChange={(e) =>
                                        handleCheckboxSalesChannels(
                                          salesChannel.id
                                        )
                                      }
                                      name={salesChannel.salesChannelsTitleId}
                                    />
                                  }
                                  label={intl.formatMessage({
                                    id: salesChannel.salesChannelsTitleId,
                                  })}
                                />
                              </Grid>
                            );
                        }
                      })}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </RootStyle>
        </Form>
      </FormikProvider>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "are_you_sure" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
