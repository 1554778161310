import { PURGE } from "redux-persist";
import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { logout } from "./index";
import * as api from "../apis/firebase";
import { removeOneInvitation, upsertOneInvitation } from "./index";

export const setCurrentPosition = createAction(
  "notifications/setCurrentPosition"
);

export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (notifications) => {
    const updatedNotifications = notifications.map((notification) => ({
      ...notification,
      isUnRead: false,
    }));

    const response = Promise.all(
      updatedNotifications.map(
        async (notification) =>
          await api.updateDoc("notifications", notification.id, notification)
      )
    );

    return response;
  }
);


const notificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.updated && b.updated
      ? a['updated'].localeCompare(b['updated'])
      : a['id'].localeCompare(b['id']),
});

export const deleteNotification = createAsyncThunk(
  "notifications/delete",
  async (id) => {
    const response = await api.removeDoc("notifications", id);
    return response;
  }
);

export const updateNotification = createAsyncThunk(
  "notifications/update",
  async ({ id, payload }) => {
    // const response = await api.updateDoc('notifications', id, payload);
    // return response;
  }
);

export const updateManyNotification = createAsyncThunk(
  "notifications/updateMany",
  async (notifications) => {
    console.log({ notifications });
    const response = Promise.all(
      notifications.map(
        async (notification) =>
          await api.updateDoc("notifications", notification.id, notification)
      )
    );
    return response;
  }
);


export const notifications = createSlice({
  name: "notifications",
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    upsertOneNotification: {
      reducer: (state, action) =>
        notificationsAdapter.upsertOne(state, action.payload),
      prepare: (data) => {
        const { at, updated, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    removeOneNotification: notificationsAdapter.removeOne,
    removeAllNotification: notificationsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        notificationsAdapter.removeAll(state);
      })
      .addCase(markAsRead.fulfilled, (state, action) => {
        action.payload.forEach((updatedNotification) => {
          notificationsAdapter.upsertOne(state, updatedNotification);
        });
      })      
      .addCase(updateManyNotification.fulfilled, (state, action) => {
        console.log({ action });
        // notificationsAdapter.upsertMany(state, action.payload)
      })
      .addCase(upsertOneInvitation, (state, action) => {
        notificationsAdapter.upsertOne(state, action.payload);
      })
      .addCase(removeOneInvitation, (state, action) => {
        notificationsAdapter.removeOne(state, action.payload);
      })
      .addCase(logout.fulfilled, (state) => {
        notificationsAdapter.removeAll(state);
      });
  },
});

export const {
  upsertOneNotification,
  removeOneNotification,
  removeAllNotification,
} = notifications.actions;

export const notificationsSelectors = notificationsAdapter.getSelectors(
  (state) => state.notifications
);

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  notificationsSelectors;

export const selectNotifications = (state) => state.notifications;


export const selectUnreadOrderCreatedNotifications = createSelector(
  [selectAll],
  (notifications) => {
    return notifications.filter(notification => 
      notification.type === 'order_created' && notification.isUnRead === true
    );
  }
);

export default notifications.reducer;
