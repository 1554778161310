import PropTypes from "prop-types";
import { forwardRef, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import {
  updateAgreement,
  selectIsLoadingTimeout,
  selectIsPageLoading,
  setSnackbar,
  selectSnackbar,
  dialogsSelectors,
  upsertOneDialog,
} from "../store";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Page = forwardRef(
  ({ pageId = "", children, showBackdrop = false, title = "", ...other }, ref) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();
    const dialogs = useSelector(dialogsSelectors.selectAll);
    const isLoading = useSelector(selectIsPageLoading);
    const isLoadingTimeout = useSelector(selectIsLoadingTimeout);

    useEffect(() => {
      if (isLoading) {
        setTimeout(() => {
          dispatch(setSnackbar({ pageLoading: 'idle' }));
        }, isLoadingTimeout)
      }
    }, [dispatch, isLoading, isLoadingTimeout])

    useEffect(() => {
      if (dialogs === undefined || dialogs.length < 1) return;
      dialogs.forEach(
        ({
          viewPages = [],
          isRedirect = false,
          redirectPage = "",
          isAccepted = false,
        }) => {
          if (!isAccepted && isRedirect && !viewPages.includes(pageId))
            navigate(`/${redirectPage}`);
        }
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogs, pageId]);

    const {
      key = new Date().getTime(),
      open = false,
      message = undefined,
      severity = "success",
      vertical = "bottom",
      horizontal = "center",
      autoHideDuration = 3000,
    } = useSelector(selectSnackbar);

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      dispatch(setSnackbar({ open: false }));
    };

    const handleOnChangeAgreement = (agreement) =>
      dispatch(updateAgreement(agreement));

    return (
      <>
        <Box ref={ref} {...other}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: 1 }}
          open={isLoading || showBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          key={key}
          autoHideDuration={autoHideDuration}
        >
          <Alert severity={severity}>{message}</Alert>
        </Snackbar>
        {dialogs
          .filter(
            ({
              acceptTextId,
              bodyTextId,
              agreementTitleTextId,
              declineTextId,
            }) =>
              acceptTextId &&
              bodyTextId &&
              agreementTitleTextId &&
              declineTextId
          )
          .map(
            (
              {
                acceptTextId,
                bodyTextId,
                agreementTitleTextId,
                declineAction = "sign-out",
                declineTextId,
                id,
                open,
              },
              i
            ) => (
              <Dialog
                key={i}
                open={open ? open : false}
                // onClose={() => dispatch({ type: 'SET_OPEN', payload: { selected: index, isOpen: false }})}
                onClose={() => upsertOneDialog({ id, data: { open: false } })}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {intl.formatMessage({
                    id: agreementTitleTextId,
                    defaultMessage: "",
                  })}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {intl.formatMessage({
                      id: bodyTextId,
                    })}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      handleOnChangeAgreement({
                        payload: {
                          isAccepted: false,
                          id,
                          declineAction,
                        },
                        isRejected: true,
                      })
                    }
                    color="primary"
                  >
                    {intl.formatMessage({
                      id: declineTextId,
                      defaultMessage: "decline",
                    })}
                  </Button>
                  {acceptTextId !== undefined && (
                    <Button
                      onClick={() =>
                        handleOnChangeAgreement({
                          payload: {
                            isAccepted: true,
                            id,
                          },
                        })
                      }
                      color="primary"
                    >
                      {intl.formatMessage({ id: acceptTextId })}
                    </Button>
                  )}
                </DialogActions>
              </Dialog>
            )
          )}
      </>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
