import { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { alpha } from '@mui/material/styles';
import { Box,
    Button,
    MenuItem,
   // ListItemIcon, 
   ListItemText, } from '@mui/material';
import { selectLanguages, selectUserLocale, updateUserLocale } from '../../store';
import MenuPopover from '../../components/MenuPopover';

export default function LanguagePopover() {
  const dispatch = useDispatch();
  const languages = useSelector(selectLanguages);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const lang = useSelector(selectUserLocale);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectLang = option => {
    dispatch(updateUserLocale({ locale: option }));
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        size="small"
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
        variant="text"
      >
        {/* <img src={lang.icon} alt={lang.label} style={{ width: 30, height: "100%" }}/> */}
        {lang.shortLabel ?? lang.label}
      </Button>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {languages.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === lang.value}
              onClick={() => handleSelectLang(option)}
              sx={{ py: 1, px: 2.5 }}
            >
              {/* <ListItemIcon>
              <img src={lang.icon} alt={lang.label} style={{ width: 30, height: "100%" }}/>
              </ListItemIcon> */}
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
