import { PURGE } from "redux-persist";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { logout, refreshSite, selectUserId } from "./index";
import * as api from "../apis/firebase";

export const setCurrentPosition = createAction("locations/setCurrentPosition");

export const setAutocomplete = createAction("locations/setAutocomplete");

const locationsAdapter = createEntityAdapter({
  selectId: (location) => location.authorId || '',
});

export const deleteLocation = createAsyncThunk(
  "locations/delete",
  async (id) => await api.removeDoc("locations", id)
);

export const updateLocation = createAsyncThunk(
  "locations/update",
  async ({ id, payload }) => {
    await api.updateDoc("locations", id, payload)
  }
);

export const locations = createSlice({
  name: "locations",
  initialState: locationsAdapter.getInitialState({
    position: { coords: { longitude: 0, latitude: 0 } },
    autocomplete: "",
  }),
  reducers: {
    upsertOneLocation: {
      reducer: (state, action) =>
        locationsAdapter.upsertOne(state, action.payload),
      prepare: (data) => {
        const { at, geoPoint, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    removeOneLocation: locationsAdapter.removeOne,
    removeAllLocation: locationsAdapter.removeAll,
    setAutocomplete: (state, action) => {
      state.autocomplete = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        locationsAdapter.removeAll(state);
      })
      .addCase(setCurrentPosition, (state, action) => {
        const {
          coords: { latitude, longitude },
        } = action.payload;
        state.position = {
          coords: {
            latitude,
            longitude,
          },
          place: {
            location: "",
            address2: "",
            locality: "",
            administrative_area_level_1: "",
            country: "Canada",
            postal_code: "",
          },
        };
      })
      .addCase(logout.fulfilled, (state) => {
        locationsAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        locationsAdapter.removeAll(state);
      });
  },
});

export const { upsertOneLocation, removeOneLocation, removeAllLocation } =
  locations.actions;

export const locationsSelectors = locationsAdapter.getSelectors(
  (state) => state.locations
);

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  locationsSelectors;

export const selectLocations = (state) => state.locations;

export const selectPosition = (state) => state.locations.position;

export const selectByAuthorId = (state, authorId) =>
  Object.values(state?.locations?.entities)?.find(
    (entity) => (entity.authorId === authorId)
  );

export const selectHasAddress = (state, authorId) =>
  Object.values(state?.locations?.entities)?.filter(
    (entity) =>
      (entity.authorId === authorId || entity.ownerId === authorId) &&
      !(entity.longitude === 0 && entity.latitude === 90)
  ).length > 0;

  export const selectHasAddressDefault = createSelector(
    [selectAll, (state) => selectUserId(state)],
    (locations, userId) =>
    locations && Object.values(locations)?.filter(
      (entity) =>
        (entity?.authorId === userId || entity?.ownerId === userId) &&
        !(entity?.longitude === 0 && entity?.latitude === 90)
    ).length > 0
  );

  export const selectDefaultAddress = createSelector(
    [selectAll, (state) => selectUserId(state)],
    (locations, userId) =>
    locations && Object.values(locations)?.filter(
      (entity) =>
        entity?.authorId === userId &&
        !(entity?.longitude === 0 && entity?.latitude === 90)
    )
  );


  export const selectUserLocation = createSelector(
    [selectEntities],
    (entities) => entities,
  )

  export const selectAutocomplete = state => state.locations.autocomplete

export default locations.reducer;
