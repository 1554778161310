import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container, Skeleton } from "@mui/material";
import Page from "../components/Page";
import { VendorForm as Form } from "../components/forms/VendorForm";
import { vendorsSelectors } from "../store";
import { useIntl } from "react-intl";

export default function VendorForm() {
  const { vendorId } = useParams();
  const intl = useIntl();
  const vendor = useSelector((state) =>
    vendorsSelectors.selectById(state, vendorId)
  );
  return (
    <Page
      pageId="vendor"
      title={vendor?.vendorName || intl.formatMessage({ id: "vendor" })}
    >
      <Container maxWidth="md">
        {vendor !== undefined ? (
          <Form vendor={vendor} />
        ) : (
          <Skeleton variant="rectangular" height={500}/>
        )}
      </Container>
    </Page>
  );
}
