import { PURGE } from "redux-persist";
import {
  createSelector,
  createSlice,
  createEntityAdapter,
  nanoid,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { logout, selectProductVendor, refreshSite } from "./index";
import * as api from "../apis/firebase";

export const batchProducts = createAsyncThunk(
  "forms/batchProducts",
  async (parentId, { getState }) => {
    const state = getState();    
    const { uid, displayName, photoURL } = state?.session?.user;
    if (!uid) return;
    const author = selectProductVendor(state, uid);
    console.log({ author });
    const {
      geohash = '',
      vendorPhoto,
      vendorName,
      vendorId,
    } = author;

    const oldVariants = Object.values(state?.products?.entities).filter(
      (product) => product.parentId === parentId
    );
    const newVariants = Object.values(state?.forms?.products?.entities).filter(
      (product) => product.parentId === parentId
    );

    const productsToCreate = newVariants
      .filter(({ id: newId }) =>
        oldVariants.every(({ id: oldId }) => oldId !== newId)
      )
      .map((p) => ({
        ...p,
        geohash,
        vendorId: vendorId || uid,
        vendorPhoto: vendorPhoto || photoURL,
        vendorName: vendorName || displayName,
      }));
    const productsToDelete = oldVariants.filter(({ id: oldId }) =>
      newVariants.every(({ id: newId }) => newId !== oldId)
    );
    const productsToUpdate = newVariants
      .filter(({ id: newId }) =>
        oldVariants.find(({ id: oldId }) => oldId === newId)
      )
      .map((p) => ({
        ...p,
        geohash,
        vendorId: vendorId || uid,
        vendorPhoto: vendorPhoto || photoURL,
        vendorName: vendorName || displayName,
      }));

    const batch = {
      productsToCreate,
      productsToUpdate,
      productsToDelete,
    };
    const response = await api.batchProducts(batch);
    return response;
  }
);

const formsAdapter = createEntityAdapter();

const productsAdapter = createEntityAdapter();

const optionsAdapter = createEntityAdapter();

const childOptionsAdapter = createEntityAdapter({
  selectId: (option) => option.optionId,
});

export const formsSelectors = formsAdapter.getSelectors(
  (state) => state.forms.products
);
export const optionsSelectors = optionsAdapter.getSelectors(
  (state) => state.forms.options
);

export const forms = createSlice({
  name: "forms",
  initialState: {
    products: productsAdapter.getInitialState(),
  },
  reducers: {
    upsertOneForm(state, action) {
      productsAdapter.upsertOne(state.products, {
        options: optionsAdapter.getInitialState(),
        ...action.payload,
        childOptions: childOptionsAdapter.getInitialState({
          ids: action.payload.childOptions?.ids
            ? [...action.payload?.childOptions?.ids]
            : [],
          entities: action.payload.childOptions?.entities
            ? { ...action.payload?.childOptions?.entities }
            : {},
        }),
      });
    },
    upsertOneVariant(state, action) {
      productsAdapter.upsertOne(state.products, action.payload);
    },
    removeOneForm: formsAdapter.removeOne,
    removeAllForm: formsAdapter.removeAll,
    // removeManyForm: formsAdapter.removeMany,
    removeManyForm(state, action) {
      formsAdapter.removeMany(state.products, [...action.payload]);
    },
    addOneOption(state, action) {
      const optionId = nanoid();
      const productId = action.payload.productId;
      const productEntry = state.products.entities[productId];
      if (productEntry) {
        childOptionsAdapter.addOne(productEntry.childOptions, {
          optionId,
          productId,
          key: "",
          optionValues: [],
        });
      }
    },
    upsertOneOption(state, action) {
      const productId = action.payload.productId;
      const productEntry = state.products.entities[productId];
      if (productEntry) {
        childOptionsAdapter.upsertOne(
          productEntry.childOptions,
          action.payload
        );
      }
      const variants = Object.values(productEntry?.childOptions?.entities || {})
        .map(({ key, optionValues, optionId }) =>
          optionValues.map((value) => ({ key, value, optionId }))
        )
        .reduce((a = [], b = []) =>
          b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
        )
        .map((variant) => {
          const arr = Array.isArray(variant) ? variant : [variant];
          const options = arr.reduce(
            (acc, curr) => ({
              ids: [...acc.ids, curr.optionId],
              entities: {
                ...acc.entities,
                [curr.optionId]: curr,
              },
            }),
            { ids: [], entities: {} }
          );
          const handle = arr.reduce(
            (acc, curr, j) =>
              acc.concat(`${j > 0 ? `-` : ""}${curr.value.replace(/\s/g, "")}`),
            ""
          );
          const id = arr.reduce(
            (acc, curr) => acc.concat(`-${curr.value.replace(/\s/g, "")}`),
            `${productId}`
          );
          const name = arr.reduce(
            (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.value}`),
            ""
          );
          return {
            ...productEntry,
            childOptions: { ids: [], entities: {} },
            handle,
            id,
            name,
            options,
            parentName: productEntry.name || "",
            parentId: productId,
          };
        });
      productsAdapter.setAll(state.products, [
        { ...productEntry, hasVariants: true, selectedVariantId: variants.length > 0 ? variants[0].id : productId},
        ...variants,
      ]);
    },
    removeOneOption(state, { payload: { productId, optionId } }) {
      const productEntry = state.products.entities[productId];
      if (productEntry) {
        childOptionsAdapter.removeOne(productEntry.childOptions, optionId);
        const variants = productEntry.childOptions.ids.length
          ? Object.values(productEntry.childOptions.entities)
              .map(({ key, optionValues, optionId }) =>
                optionValues.map((value) => ({ key, value, optionId }))
              )
              .reduce((a, b) =>
                b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
              )
              .map((variant) => {
                const arr = Array.isArray(variant) ? variant : [variant];
                const options = arr.reduce(
                  (acc, curr) => ({
                    ids: [...acc.ids, curr.optionId],
                    entities: {
                      ...acc.entities,
                      [curr.optionId]: curr,
                    },
                  }),
                  { ids: [], entities: {} }
                );
                const handle = arr.reduce(
                  (acc, curr, j) =>
                    acc.concat(
                      `${j > 0 ? `-` : ""}${curr.value.replace(/\s/g, "")}`
                    ),
                  ""
                );
                const id = arr.reduce(
                  (acc, curr) =>
                    acc.concat(`-${curr.value.replace(/\s/g, "")}`),
                  `${productId}`
                );
                const name = arr.reduce(
                  (acc, curr, j) =>
                    acc.concat(`${j > 0 ? ` / ` : ""}${curr.value}`),
                  ""
                );
                return {
                  ...productEntry,
                  childOptions: { ids: [], entities: {} },
                  handle,
                  id,
                  name,
                  options,
                  parentId: productId,
                };
              })
          : [];
        productsAdapter.setAll(state.products, [{...productEntry, selectedVariantId: variants.length > 0 ? variants[0].id : productId}, ...variants]);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        formsAdapter.removeAll(state);
        productsAdapter.removeAll(state.products);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        formsAdapter.removeAll(state);
        productsAdapter.removeAll(state.products);
      })
      .addCase(logout.fulfilled, (state) => {
        productsAdapter.removeAll(state);
      })
      .addCase(batchProducts.fulfilled, (state, action) => {
        const {
          productsCreated = [],
          productsUpdated = [],
          productsDeleted = [],
        } = action?.payload?.data || [];
        formsAdapter.removeMany(state.products, [
          ...productsCreated.map(({ id }) => id),
          ...productsUpdated.map(({ id }) => id),
          ...productsDeleted.map(({ id }) => id),
        ]);
      });
  },
});

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  formsSelectors;

export const selectForms = (state) => state.forms.products;

export const {
  upsertOneForm,
  removeOneForm,
  removeManyForm,
  removeAllForm,
  addOneOption,
  upsertOneOption,
  removeOneOption,
  upsertOneVariant,
} = forms.actions;

export const selectFormProductsByVariant = createSelector(
  [selectAll, (state, parentId) => parentId],
  (products, parentId) =>
    products.filter(
      (product) =>
        parentId === product.parentId && product.id !== product.parentId
    )
);

export default forms.reducer;
