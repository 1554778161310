// import 'simplebar/src/simplebar.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { store, persistor } from './store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';

var getGlobal = function () {
  // the only reliable means to get the global object is
  // `Function('return this')()`
  // However, this causes CSP violations in Chrome apps.
  // eslint-disable-next-line no-restricted-globals
  if (typeof self !== 'undefined') { return self; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  throw new Error('unable to locate global object');
};

if (typeof globalThis === 'undefined') {
  getGlobal().globalThis = getGlobal();
}

Sentry.init({
  dsn: "https://5ae083e22212433586d2e59f3a9fff6a@o4505559150821376.ingest.sentry.io/4505559155212288",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "https:yourserver.io/api/"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(  <React.StrictMode>
  <HelmetProvider>    
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>          
          <BrowserRouter>
            <App />
          </BrowserRouter> 
      </PersistGate>
    </Provider>
  </HelmetProvider>
</React.StrictMode>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
