/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useEffect, useReducer, useRef } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AvatarImageField from "../../AvatarImageField";
import { locationsSelectors, updateLocation, updateUser } from "../../../store";
// import { google } from "../../../apis/google";

const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: theme.spacing(1),
  gridTemplateRows: "auto",
  gridTemplateAreas: `"main"
  "footer"
  "sidebar"`,
  [theme.breakpoints.up("sm")]: {
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateAreas: `"main main sidebar"
    "main main sidebar"
    "footer footer footer"`,
  },
  [theme.breakpoints.up("md")]: {
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateAreas: `"main main main sidebar"
    "main main main sidebar"
    "footer footer footer footer"`,
  },
}));

const initialState = {
  src: undefined,
  imageId: undefined,
  image: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case "srcChanged":
      return {
        ...state,
        src: action.payload,
      };
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

const NORTHPOLE = {
  longitude: 0.0,
  latitude: 90.0,
};

export default function UserForm({ user }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mapElement = useRef();
  // const mapObject = useRef();
  // const autocomplete = useRef();
  // const circleRef = useRef(null);

  // const map = new google.maps.Map(document.getElementById("map"));
  // const marker = new google.maps.Marker({ map: map, draggable: false });

  const [{ src }, _dispatch] = useReducer(reducer, initialState);

  const { displayName, email, range, uid, photoURL, photoURLId } = user;

  const {
    latitude,
    longitude,
    location,
    address2,
    locality,
    administrative_area_level_1,
    country = "Canada",
    postal_code,
  } = useSelector((state) => locationsSelectors.selectById(state, uid));

  const UserSchema = Yup.object().shape({
    displayName: Yup.string().required(intl.formatMessage({ id: "required" })),
    email: Yup.string().required(intl.formatMessage({ id: "required" })),
    photoURL: Yup.string().required(intl.formatMessage({ id: "required" })),
    location: Yup.string().required(intl.formatMessage({ id: "required" })),
    // price: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const formik = useFormik({
    initialValues: {
      address2,
      administrative_area_level_1,
      country,
      displayName,
      email,
      latitude,
      longitude,
      location,
      locality,
      postal_code,
      range,
      uid,
      photoURL,
      photoURLId,
    },
    validationSchema: UserSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    dirty,
    setFieldValue,
    // handleSubmit,
    getFieldProps,
  } = formik;

  useEffect(() => {
    _dispatch({ type: "srcChanged", payload: photoURL });
  }, [photoURL]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      latitude = NORTHPOLE.latitude,
      longitude = NORTHPOLE.longitude,
      location = "",
      address2 = "",
      locality = "",
      administrative_area_level_1 = "",
      country = "",
      postal_code = "",
      ...rest
    } = values;
    dispatch(updateUser({ id: uid, payload: rest }));
    dispatch(
      updateLocation({
        id: uid,
        payload: {
          latitude,
          longitude,
          location,
          address2,
          locality,
          administrative_area_level_1,
          country,
          postal_code,
        },
      })
    );
  };

  const resetForm = () => {
    formik.resetForm();
  };

 return (
    <Container maxWidth="md">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <RootStyle>
            <Box sx={{ gridArea: "main" }}>
              <Grid container>
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "profile" })}
                      />
                      <CardContent>
                        <Grid item container spacing={2}>
                          <Grid item xs={12} md={4}>
                            {uid && (
                              <AvatarImageField
                                name="photoURL"
                                variant="rounded"
                                disabled={!true}
                                uid={uid}
                                change={({ downloadURL, fileName }) => {
                                  setFieldValue("photoURL", downloadURL);
                                  setFieldValue("photoURLId", fileName);
                                  _dispatch({
                                    type: "srcChanged",
                                    payload: downloadURL,
                                  });
                                }}
                                initialized={true}
                                intl={intl}
                                path={"users"}
                                src={src}
                                required
                              />
                            )}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Grid container item spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  type="text"
                                  fullWidth
                                  label={intl.formatMessage({
                                    id: "displayName",
                                  })}
                                  {...getFieldProps("displayName")}
                                  onChange={formik.handleChange}
                                  value={values.displayName}
                                  error={Boolean(
                                    touched.displayName && errors.displayName
                                  )}
                                  helperText={
                                    touched.displayName && errors.displayName
                                  }
                                  placeholder={intl.formatMessage({
                                    id: "placeholder_display_name",
                                  })}
                                  sx={{ mb: 2 }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  type="text"
                                  fullWidth
                                  label={intl.formatMessage({ id: "email" })}
                                  {...getFieldProps("email")}
                                  onChange={formik.handleChange}
                                  value={values.email}
                                  error={Boolean(touched.email && errors.email)}
                                  helperText={touched.email && errors.email}
                                  placeholder={intl.formatMessage({
                                    id: "email_placeholder",
                                  })}
                                  sx={{ mb: 2 }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "location" })}
                        subheader={intl.formatMessage({
                          id: "location_subheader",
                        })}
                        // action={
                        //   <Button variant="contained">
                        //     {intl.formatMessage({ id: "use_my_location" })}
                        //   </Button>
                        // }
                      />
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <TextField
                                  id="location"
                                  name="location"
                                  value={values.location || ""}
                                  type="text"
                                  fullWidth
                                  label={intl.formatMessage({ id: "address" })}
                                  placeholder={intl.formatMessage({
                                    id: "address_1_placeholder",
                                  })}
                                  {...getFieldProps("location")}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  name="address2"
                                  value={values.address2 || ""}
                                  type="text"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  label={intl.formatMessage({
                                    id: "address_2",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "address_2_placeholder",
                                  })}
                                  {...getFieldProps("address2")}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  id="locality"
                                  name="locality"
                                  value={values.locality || ""}
                                  type="text"
                                  fullWidth
                                  onChange={formik.handleChange}
                                  error={Boolean(touched.name && errors.name)}
                                  label={intl.formatMessage({ id: "city" })}
                                  placeholder={intl.formatMessage({
                                    id: "city_placeholder",
                                  })}
                                  InputLabelProps={{
                                    shrink: values.locality ? true : false,
                                  }}
                                  {...getFieldProps("locality")}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  id="administrative_area_level_1"
                                  name="administrative_area_level_1"
                                  value={
                                    values.administrative_area_level_1 || ""
                                  }
                                  type="text"
                                  label={intl.formatMessage({
                                    id: "administrative_area_level_1",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "administrative_area_level_1_placeholder",
                                  })}
                                  {...getFieldProps(
                                    "administrative_area_level_1"
                                  )}
                                  InputLabelProps={{
                                    shrink: values.administrative_area_level_1
                                      ? true
                                      : false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  id="postal_code"
                                  name="postal_code"
                                  value={values.postal_code || ""}
                                  type="text"
                                  label={intl.formatMessage({
                                    id: "postal_code",
                                  })}
                                  placeholder={intl.formatMessage({
                                    id: "postal_code_placeholder",
                                  })}
                                  {...getFieldProps("postal_code")}
                                  InputLabelProps={{
                                    shrink: values.postal_code ? true : false,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  id="country"
                                  name="country"
                                  value={values.country || ""}
                                  type="text"
                                  fullWidth
                                  label={intl.formatMessage({ id: "country" })}
                                  placeholder={intl.formatMessage({
                                    id: "country_placeholder",
                                  })}
                                  {...getFieldProps("country")}
                                  InputLabelProps={{
                                    shrink: values.country ? true : false,
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div
                              id="map"
                              ref={mapElement}
                              style={{ height: 300 }}
                            ></div>
                            <Grid item xs={12}>
                              <Typography id="input-slider" gutterBottom>
                                {intl.formatMessage({ id: "range" })}
                              </Typography>
                              <Slider
                                name="range"
                                {...getFieldProps("range")}
                                defaultValue={25}
                                value={values.range}
                                valueLabelDisplay="on"
                                onChange={formik.handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "sidebar" }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  {dirty && (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid
                            item
                            container
                            spacing={2}
                            justifyContent="center"
                          >
                            <Grid item>
                              <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                disabled={formik.isSubmitting}
                              >
                                {intl.formatMessage({ id: "save" })}
                              </LoadingButton>
                            </Grid>
                            <Grid item>
                              <LoadingButton
                                variant="contained"
                                onClick={resetForm}
                              >
                                {intl.formatMessage({ id: "reset" })}
                              </LoadingButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
          </RootStyle>
        </Form>
      </FormikProvider>
    </Container>
  );
}
