import { createAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { doc, updateDoc } from "firebase/firestore";
import { PURGE } from "redux-persist";
import * as api from "../apis/firebase";

const { db } = api;

const initialState = {
  status: "idle",
  isLoggedIn: null,
  user: {
    uid: undefined,
    email: undefined,
    displayName: undefined,
    photoURL: undefined,
  },
};

export const upsertUserSession = createAction(
  "session/upsertUserSession",
  function prepare(user) {
    const {
      displayName,
      email,
      emailVerified,
      isAnonymous,
      phoneNumber,
      photoURL,
      providerId,
      uid,
    } = user;
    return {
      payload: {
        displayName,
        email,
        emailVerified,
        isAnonymous,
        phoneNumber,
        photoURL,
        providerId,
        uid,
      },
    };
  }
);

export const wipeout = createAsyncThunk(
  "session/wipeout",
  async (_, { dispatch }) => {
    dispatch({
      type: PURGE,
      key: "agreements",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "cart",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "invitations",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "locations",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "session",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "places",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "orders",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "memberships",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "notifications",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "products",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "forms",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "users",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "vendors",
      result: () => null,
    });
    dispatch({
      type: PURGE,
      key: "snackbars",
      result: () => null,
    });
    return null;
  }
);

export const createUserWithEmailAndPassword = createAsyncThunk(
  "session/createUserWithEmailAndPassword",
  async (payload, { rejectWithValue }) => {
    if (!payload?.email || !payload?.password) return;
    try {
      const response = await api.register(payload.email, payload.password);
      const { user, providerId } = response;
      const { displayName, email, photoURL, uid } = user;
      return { user: { displayName, email, photoURL, uid }, providerId };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "session/logout",
  async (_, { dispatch }) => {
    const response = await api.logout();
    dispatch(wipeout());
    return response;
  }
);

export const forgotPassword = createAsyncThunk(
  "session/forgotPassword",
  async (email) => {
    if (!email) return;
    const response = await api.forgotPassword(email);
    return response;
    // const { user, providerId } = response;
    // const { displayName, email, photoURL, uid } = user;
    // return { user: { displayName, email, photoURL, uid }, providerId };
  }
);

export const signInWithEmailAndPassword = createAsyncThunk(
  "session/signInWithEmailAndPassword",
  async (payload, { dispatch, rejectWithValue }) => {
    if (!payload?.email || !payload?.password) return;
    try {
      const response = await api.login(payload.email, payload.password);
      const { user, providerId } = response;
      const { displayName, email, photoURL, uid } = user;
      return { user: { displayName, email, photoURL, uid }, providerId };
    } catch (error) {
      const { code } = error;
      switch (code) {
        case "auth/user-not-found":
          try {
            await dispatch(createUserWithEmailAndPassword(payload));
            const response = await api.login(payload.email, payload.password);
            const { user, providerId } = response;
            const { displayName, email, photoURL, uid } = user;
            if (!user) {
              throw new Error("User not found"); // Throw an error to be caught by the outer catch block
            }
            return { user: { displayName, email, photoURL, uid }, providerId };
          } catch (e) {
            console.log({ e });
            return rejectWithValue(e);
          }
        case "auth/wrong-password": {
          return rejectWithValue(code);
        }
        default: {
          return rejectWithValue(code);
        }
      }
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "session/signInWithFacebook",
  async () => {
    const response = await api.signInWithFacebook();
    const { user, providerId } = response;
    const { displayName, email, photoURL, uid } = user;
    return { user: { displayName, email, photoURL, uid }, providerId };
  }
);

export const signInWithGoogle = createAsyncThunk(
  "session/signInWithGoogle",
  async () => {
    const response = await api.signInWithGoogle();
    const { user, providerId } = response;
    const { displayName, email, photoURL, uid } = user;
    return { user: { displayName, email, photoURL, uid }, providerId };
  }
);

export const updateUserLocale = createAsyncThunk(
  "session/updateUserLocale",
  async (data) => {
    const { locale, userId } = data;
    if (userId) {
      const userRef = doc(db, "user", userId);
      await updateDoc(userRef, { locale });
    }
    return locale;
  }
);

export const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    storeInvitationUrl: (state, action) => {
      state.invitationUrl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state.status = "idle";
        state.user = null;
        state.isLoggedIn = false;
      })
      .addCase(upsertUserSession, (state, action) => {
        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          phoneNumber,
          photoURL,
          providerId,
          uid,
        } = action.payload;
        if (state?.user) {
          state.user.displayName = displayName;
          state.user.email = email;
          state.user.emailVerified = emailVerified;
          state.user.isAnonymous = isAnonymous;
          state.user.phoneNumber = phoneNumber;
          state.user.photoURL = photoURL;
          state.user.providerId = providerId;
          state.user.uid = uid;
        }
      })
      .addCase(signInWithEmailAndPassword.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.user;
        state.providerId = action.payload.providerId;
        state.isLoggedIn = action.payload.user !== undefined;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.user;
        state.providerId = action.payload.providerId;
        state.isLoggedIn = action.payload.user !== undefined;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.user;
        state.providerId = action.payload.providerId;
        state.isLoggedIn = action.payload.user !== undefined;
      })
      .addCase(updateUserLocale.fulfilled, (state, action) => {
        state.status = "idle";
        state.locale = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logout.fulfilled, (state) => {
        state.status = "idle";
        state.user = null;
        state.isLoggedIn = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.status = "idle";
        state.isLoggedIn = false;
      });
  },
});

export const {
 storeInvitationUrl
} = session.actions;

export const selectCount = (state) => state.session.value;

export const selectInvitationUrl = (state) => state.session.invitationUrl;

export const selectIsLoggedIn = (state) => state.session.isLoggedIn;

export const selectUserId = (state) => state.session?.user?.uid;

export const selectUser = (state) => state.session.user;

export const selectUserEmail = (state) => state.session?.user?.email;

export const selectUserName = (state) => state.session?.user?.displayName;

export const selectUserPhoto = (state) => state.session?.user?.photoURL;

const selectSession = (state) => state.session;

export const selectUserLocale = createSelector(
  selectSession,
  (session) =>
    session?.locale ?? {
      value: "en-US",
      label: "English",
      icon: "/static/icons/ic_flag_ca.svg",
      importName: "enUS",
    }
);
export const selectUserLocaleValue = (state) =>
  state.session?.locale?.value ?? "en-US";

export default session.reducer;
