/* eslint-disable react/style-prop-object */
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Card,
  CardContent,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../components/Page";
import { selectUserId, selectOrdersByCustomerId } from "../../store";
import { useIntl } from "react-intl";
import {
  fulfillmentStatusChip,
  statusChip,
  paymentStatusChip,
} from "../../utils/chips";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileView from "./MobileView";

const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return isMobile;
};

export default function Purchases() {
  const intl = useIntl();
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const purchases = useSelector((state) =>
    selectOrdersByCustomerId(state, userId)
  );
  const isMobile = useIsMobile();

  const columns = useMemo(
    () => [
      {
        field: "created",
        headerName: intl.formatMessage({ id: "created" }),
        type: "date",
        flex: 1,
        valueGetter: ({ value }) => {
          console.log({ value });
          return value && new Date(value);
        },
      },
      {
        field: "id",
        headerName: intl.formatMessage({ id: "id" }),
        flex: 1,
        // valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: "vendorName",
        headerName: intl.formatMessage({ id: "vendorName" }),
        type: "string",
        flex: 1,
        valueFormater: ({ value }) =>
          value ? value : intl.formatMessage({ id: "no_name" }),
      },
      {
        field: "vendorPhoto",
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        renderCell: ({ value }) => <Avatar src={value} />,
      },
      {
        field: "quantity",
        headerName: intl.formatMessage({ id: "quantity" }),
        type: "number",
        flex: 1,
      },
      {
        field: "total",
        headerName: intl.formatMessage({ id: "total" }),
        type: "number",
        flex: 1,
        valueFormatter: ({ value = 0 }) =>
          intl.formatNumber(value, {
            style: "currency",
            currency: "CAD",
          }),
      },
      {
        field: "status",
        headerName: intl.formatMessage({ id: "status" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value ? statusChip(value, intl.formatMessage({ id: value })) : "",
      },
      {
        field: "paymentStatus",
        headerName: intl.formatMessage({ id: "payment" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value
            ? paymentStatusChip(value, intl.formatMessage({ id: value }))
            : "",
      },
      {
        field: "fulfillmentStatus",
        headerName: intl.formatMessage({ id: "fulfillment" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value
            ? fulfillmentStatusChip(value, intl.formatMessage({ id: value }))
            : "",
      },
      {
        field: "actions",
        type: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        cellClassName: "actions",
        flex: 1,
        getActions: ({ id }) => {
          return [
            <IconButton aria-label="detail" onClick={() => navigate(id)}>
              <NavigationIcon />
            </IconButton>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Page title={intl.formatMessage({ id: "purchases" })}>
      <Container maxWidth="lg">
        <Typography>{intl.formatMessage({ id: "purchases" })}</Typography>
        {isMobile ? (
          <MobileView purchases={purchases} />
        ) : (
          <Card>
            <CardContent>
              <DataGrid
                name="purchases"
                autoHeight
                rows={purchases}
                columns={columns}
              />
            </CardContent>
          </Card>
        )}
      </Container>
    </Page>
  );
}
