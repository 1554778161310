/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useEffect, useState, useReducer } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik, Form, FormikProvider, Field } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarImageField from "../../components/AvatarImageField";
import MembersTable from "./MembersTable";
import {
  batchMemberships,
  deleteMemberships,
  resetMembership,
  removeManyMembershipPlan,
  salesChannelsSelectors,
  selectCategories,
  selectMembershipPlansByParentId,
  selectProductsByVendorIdByUserSessionId,
  selectReservesParentsByPlanId,
  selectStatuses,
  selectTimeout,
  upsertOneMembershipPlan,
} from "../../store";

const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: theme.spacing(1),
  gridTemplateRows: "auto",
  gridTemplateAreas: `"main"
  "footer"
  "sidebar"`,
  [theme.breakpoints.up("sm")]: {
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateAreas: `"main main sidebar"
    "main main sidebar"
    "footer footer footer"`,
  },
  [theme.breakpoints.up("md")]: {
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateAreas: `"main main main sidebar"
    "main main main sidebar"
    "footer footer footer footer"`,
  },
}));

const CurrencyInput = ({ field, form, meta, ...props }) => {
  const intl = useIntl();
  const { currency, label } = props;
  const { name, value = 0 } = field;
  const { touched, errors } = form;
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <TextField
          {...field}
          {...props}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          fullWidth
          label={intl.formatMessage({ id: label })}
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={() => setShow(false)}
          value={value}
        />
      )}
      {!show && (
        <TextField
          fullWidth
          label={intl.formatMessage({ id: label })}
          onFocus={() => setShow(true)}
          value={intl.formatNumber(value, {
            style: "currency",
            currency: currency || "CAD",
          })}
        />
      )}
    </>
  );
};

const EMPTY_ARR = [];

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const initialState = {
  tierHeadings: EMPTY_ARR,
  profit: 0.0,
  margin: 0,
  src: undefined,
  imageId: undefined,
  image: undefined,
  isPlansTouched: false,
  deleteDialogOpen: false,
  left: EMPTY_ARR,
  right: EMPTY_ARR,
  checked: EMPTY_ARR,
  leftChecked: EMPTY_ARR,
  productIds: EMPTY_ARR,
  rightChecked: EMPTY_ARR,
};

function reducer(state, action) {
  switch (action.type) {
    case "srcChanged":
      return {
        ...state,
        src: action.payload,
      };
    case "childTiersChanged":
      const { childTiers = {} } = action.payload;
      const tierHeadings = Object.values(childTiers).map(({ key, tierId }) => ({
        field: tierId,
        headerName: key,
        type: "string",
        width: 180,
        renderCell: ({ row }) => {
          return (
            <div onClick={(e) => e.preventDefault()}>
              {row?.tiers?.entities[tierId]?.value || ""}
            </div>
          );
        },
      }));
      return {
        ...state,
        tierHeadings,
      };
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

export default function PlanForm({ plan }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const availableCategories = useSelector(selectCategories);
  const availableSalesChannels = useSelector(salesChannelsSelectors.selectAll);
  const statuses = useSelector(selectStatuses);
  const formTimeout = useSelector(selectTimeout);

  const handleDatePickerAvailableFromOnChange = (value) => {
    setFieldValue("availableFrom", value);
  };

  const handleDatePickerAvailableToOnChange = (value) => {
    setFieldValue("availableTo", value);
  };

  const plans = useSelector((state) =>
    selectMembershipPlansByParentId(state, plan.id)
  );

  const products = useSelector((state) =>
    selectProductsByVendorIdByUserSessionId(state)
  );
  const productIds = products.map(({ id }) => id);
  const reserves = useSelector((state) =>
    selectReservesParentsByPlanId(state, plan.id)
  );

  const [{ checked, left, deleteDialogOpen, right }, _dispatch] = useReducer(
    reducer,
    initialState
  );

  const {
    available = 0,
    availableFrom,
    availableTo,
    begins,
    categories = EMPTY_ARR,
    childLengths = { ids: EMPTY_ARR, entities: {} },
    childTiers = { ids: EMPTY_ARR, entities: {} },
    continueSellingWhenOutOfStock = false,
    compareAtPrice = 0,
    days,
    description = "",
    customBegins,
    customExpires,
    expires,
    displayAvailableInventoryToCustomers = false,
    hasPlans = false,
    id = "",
    isRenewable = false,
    isTaxed = false,
    lengthName = "",
    maxPerOrder = "",
    name = "",
    parentId = "",
    parentName = "",
    price = 0,
    membershipPhoto,
    membershipPhotoId = "",
    salesChannels = EMPTY_ARR,
    status = "draft",
    tags = EMPTY_ARR,
    tierDescription = "",
    tierName = "",
    trackQuantity = false,
    vendorId,
    ...rest
  } = plan;

  const MembershipSchema = Yup.object().shape({
    parentName: Yup.string().required(intl.formatMessage({ id: "required" })),
    membershipPhoto: Yup.string().required(
      intl.formatMessage({ id: "required" })
    ),
    price: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const formik = useFormik({
    initialValues: {
      available,
      availableFrom,
      availableTo,
      categories,
      childLengths,
      childTiers,
      compareAtPrice,
      continueSellingWhenOutOfStock,
      description,
      displayAvailableInventoryToCustomers,
      hasPlans,
      id,
      isTaxed,
      maxPerOrder,
      membershipPhoto,
      membershipPhotoId,
      name,
      parentId,
      parentName,
      price,
      salesChannels,
      status,
      tags,
      trackQuantity,
      ...rest,
    },
    validationSchema: MembershipSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    dirty,
    setFieldValue,
    getFieldProps,
  } = formik;

  useEffect(() => {
    _dispatch({ type: "srcChanged", payload: membershipPhoto });
  }, [membershipPhoto]);

  useEffect(() => {
    const { plans, childLengths, childTiers, ...rest } = values;
    const timeoutId = setTimeout(
      () => 
        dispatch(
          upsertOneMembershipPlan({
            id: plan.id,
            ...rest,
          })
        ),
      formTimeout
    );
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(batchMemberships({ parentId: parentId, reserveIds: right }));
  };

  const resetForm = () => {
    formik.resetForm();
    // await dispatch(resetPlans({ parentId: id }));
    dispatch(resetMembership(id));
    // dispatch(removeManyMembershipPlan([id, ...plans.map((plan) => plan.id)]));
    _dispatch({ type: "stateChanged", payload: { isPlansTouched: false } });
  };

  const handleCancelDialog = () =>
    _dispatch({ type: "stateChanged", payload: { deleteDialogOpen: false } });

  const handleOk = async () => {
    const deleteIds = [id, ...plans.map((plan) => plan.id)];
    await dispatch(deleteMemberships(deleteIds));
    formik.resetForm();
    dispatch(removeManyMembershipPlan(deleteIds));
    await _dispatch({
      type: "stateChanged",
      payload: { isPlansTouched: false, deleteDialogOpen: false },
    });
    navigate(`/vendors/${vendorId}/products`);
  };

  const handleDelete = () => {
    _dispatch({ type: "stateChanged", payload: { deleteDialogOpen: true } });
  };

  const handleCheckboxSalesChannels = (v) => {
    if (values.salesChannels.indexOf(v) === -1) {
      setFieldValue("salesChannels", [...values.salesChannels, v]);
    } else {
      setFieldValue(
        "salesChannels",
        values.salesChannels.filter((sc) => sc !== v)
      );
      setFieldValue("availableFrom", null);
      setFieldValue("availableTo", null);
    }
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    _dispatch({
      type: "stateChanged",
      payload: {
        checked: newChecked,
      },
    });
  };

  const handleAllRight = () => {
    _dispatch({
      type: "stateChanged",
      payload: {
        right: right.concat(left),
        left: EMPTY_ARR,
      },
    });
  };

  const handleCheckedRight = () => {
    _dispatch({
      type: "stateChanged",
      payload: {
        right: right.concat(leftChecked),
        left: not(left, leftChecked),
        checked: not(checked, leftChecked),
      },
    });
  };

  const handleCheckedLeft = () => {
    _dispatch({
      type: "stateChanged",
      payload: {
        right: not(right, rightChecked),
        left: left.concat(rightChecked),
        checked: not(checked, rightChecked),
      },
    });
  };

  const handleAllLeft = () => {
    _dispatch({
      type: "stateChanged",
      payload: {
        right: EMPTY_ARR,
        left: left.concat(right),
      },
    });
  };

  const numberOfChecked = (products) => intersection(checked, products).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      _dispatch({
        type: "stateChanged",
        payload: { checked: not(checked, items) },
      });
    } else {
      _dispatch({
        type: "stateChanged",
        payload: { checked: union(checked, items) },
      });
    }
  };

  useEffect(() => {
    _dispatch({
      type: "stateChanged",
      payload: {
        right: reserves,
        left: not(productIds, reserves),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(reserves), JSON.stringify(productIds)]);

  const customList = (title, items = EMPTY_ARR) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={false}
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all products selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List dense component="div" role="list">
        {items.map((value, i) => (
          <ListItem
            key={i}
            role="listitem"
            button
            onClick={handleToggle(value)}
          >
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ "aria-labelledby": value }}
              />
            </ListItemIcon>
            <ListItemText
              id={value}
              primary={
                products ? products?.find(({ id }) => id === value)?.name : ""
              }
            />
          </ListItem>
        ))}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Container maxWidth="md">
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <RootStyle>
            <Box sx={{ gridArea: "main" }}>
              <Grid container>
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader
                        title={values.name}
                        subheader={
                          <Link
                            component={RouterLink}
                            underline="none"
                            variant="subtitle2"
                            to={`/vendors/${vendorId}/memberships/${parentId}/edit`}
                          >
                            {parentName}
                          </Link>
                        }
                      />
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="text"
                              fullWidth
                              label={`${intl.formatMessage({
                                id: "plan",
                              })} ${intl.formatMessage({ id: "name" })}`}
                              {...getFieldProps("name")}
                              onChange={formik.handleChange}
                              value={values.name}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              placeholder={intl.formatMessage({
                                id: "placeholder_membership_name",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type={"text"}
                              fullWidth
                              label={intl.formatMessage({ id: "description" })}
                              {...getFieldProps("description")}
                              onChange={formik.handleChange}
                              value={values.description}
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              helperText={
                                touched.description && errors.description
                              }
                              multiline
                              rows={3}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title={intl.formatMessage({ id: "media" })} />
                      <CardContent>
                        {id && (
                          <AvatarImageField
                            name="membershipPhoto"
                            disabled={!true}
                            uid={id}
                            change={({ downloadURL, fileName }) => {
                              setFieldValue("membershipPhoto", downloadURL);
                              setFieldValue("membershipPhotoId", fileName);
                            }}
                            initialized={true}
                            intl={intl}
                            path={"memberships"}
                            src={values.membershipPhoto}
                            required
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "pricing" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={8}>
                            <Field
                              name="price"
                              placeholder="0.00"
                              label="price"
                              component={CurrencyInput}
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("isTaxed")}
                                  checked={values.isTaxed || false}
                                  onChange={formik.handleChange}
                                  name="isTaxed"
                                  value={isTaxed}
                                />
                              }
                              label={intl.formatMessage({ id: "taxed_label" })}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "inventory" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("trackQuantity")}
                                  checked={values.trackQuantity || false}
                                  onChange={formik.handleChange}
                                  name="trackQuantity"
                                  value={values.trackQuantity}
                                />
                              }
                              label={intl.formatMessage({
                                id: "track_quantity_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps(
                                    "continueSellingWhenOutOfStock"
                                  )}
                                  checked={
                                    values.continueSellingWhenOutOfStock ||
                                    false
                                  }
                                  onChange={formik.handleChange}
                                  name="continueSellingWhenOutOfStock"
                                  value={values.continueSellingWhenOutOfStock}
                                />
                              }
                              label={intl.formatMessage({
                                id: "continue_selling_when_out_of_stock_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps(
                                    "displayAvailableInventoryToCustomers"
                                  )}
                                  checked={
                                    values.displayAvailableInventoryToCustomers ||
                                    false
                                  }
                                  onChange={formik.handleChange}
                                  name="displayAvailableInventoryToCustomers"
                                  value={
                                    values.displayAvailableInventoryToCustomers
                                  }
                                />
                              }
                              label={intl.formatMessage({
                                id: "availability_visible_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="maxPerOrder"
                              plan="outlined"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              label={intl.formatMessage({
                                id: "max_per_order_label",
                              })}
                              helperText={intl.formatMessage({
                                id: "max_per_order_helper_text",
                              })}
                              value={values.maxPerOrder}
                              onChange={formik.handleChange}
                              size="small"
                              {...getFieldProps("maxPerOrder")}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="available"
                              plan="outlined"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              label={intl.formatMessage({
                                id: "available_label",
                              })}
                              value={values.available}
                              onChange={formik.handleChange}
                              size="small"
                              {...getFieldProps("available")}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "sidebar" }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "item_status" })}
                      />
                      <CardContent>
                        <FormControl sx={{ mb: 2 }}>
                          <Select
                            name="status"
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={values.status || "draft"}
                            onChange={formik.handleChange}
                          >
                            {statuses.map((status, i) => (
                              <MenuItem key={i} value={status}>
                                {intl.formatMessage({ id: status })}
                              </MenuItem>
                            ))}
                          </Select>
                          {status === "draft" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "item_status_hint",
                              })}
                            </FormHelperText>
                          )}
                          {status === "active" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "item_status_active_hint",
                              })}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Typography variant="subtitle" gutterBottom>
                          {intl.formatMessage({ id: "sales_channels" })}
                        </Typography>
                        {availableSalesChannels.map((salesChannel, i) => {
                          switch (salesChannel.id) {
                            case "memberships":
                              break;
                            case "online_shop":
                              return (
                                <Grid item xs={12} key={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values?.salesChannels?.indexOf(
                                            salesChannel.id
                                          ) !== -1
                                        }
                                        onChange={(e) =>
                                          handleCheckboxSalesChannels(
                                            salesChannel.id
                                          )
                                        }
                                        name={intl.formatMessage({
                                          id: salesChannel.salesChannelsTitleId,
                                        })}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: salesChannel.salesChannelsTitleId,
                                    })}
                                  />
                                  {values?.salesChannels?.indexOf(
                                    salesChannel.id
                                  ) !== -1 && (
                                    <Stack gap={1}>
                                      <Typography>
                                        {intl.formatMessage({
                                          id: "schedule_availability",
                                        })}
                                      </Typography>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "from",
                                          })}`}
                                          value={values.availableFrom}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableFromOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                          sx={{ mb: 2 }}
                                        />
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "to",
                                          })}`}
                                          value={values.availableTo}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableToOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Stack>
                                  )}
                                </Grid>
                              );
                            default:
                              return (
                                <Grid item xs={12} key={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values?.salesChannels?.indexOf(
                                            salesChannel.id
                                          ) !== -1
                                        }
                                        onChange={(e) =>
                                          handleCheckboxSalesChannels(
                                            salesChannel.id
                                          )
                                        }
                                        name={intl.formatMessage({
                                          id: salesChannel.salesChannelsTitleId,
                                        })}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: salesChannel.salesChannelsTitleId,
                                    })}
                                  />
                                  {values?.salesChannels?.indexOf(
                                    salesChannel.id
                                  ) !== -1 && (
                                    <Stack gap={1}>
                                      <Typography>
                                        {intl.formatMessage({
                                          id: "schedule_availability",
                                        })}
                                      </Typography>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "from",
                                          })}`}
                                          value={values.availableFrom}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableFromOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "to",
                                          })}`}
                                          value={values.availableTo}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableToOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Stack>
                                  )}
                                </Grid>
                              );
                          }
                          return "";
                        })}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "organization" })}
                      />
                      <CardContent>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            {values.categories && availableCategories && (
                              <Autocomplete
                                multiple
                                id={"categories"}
                                options={
                                  availableCategories
                                    ? availableCategories.filter(
                                        (category) => category !== "all"
                                      )
                                    : EMPTY_ARR
                                }
                                getOptionLabel={(tier) =>
                                  intl.formatMessage({ id: tier })
                                }
                                onChange={(e, value) =>
                                  setFieldValue("categories", value)
                                }
                                value={
                                  ["memberships", ...values.categories] ||
                                  EMPTY_ARR
                                }
                                renderTags={(value, getTagProps) =>
                                  value.map((tier, index) => (
                                    <Chip
                                      plan="outlined"
                                      label={intl.formatMessage({ id: tier })}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    plan="outlined"
                                    label={intl.formatMessage({
                                      id: `categories`,
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "add_category_placeholder",
                                    })}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id={"tags"}
                              onChange={(e, value) =>
                                setFieldValue("tags", value)
                              }
                              options={EMPTY_ARR}
                              freeSolo
                              value={values.tags || EMPTY_ARR}
                              renderTags={(value, getTagProps) =>
                                value.map((tier, index) => (
                                  <Chip
                                    plan="outlined"
                                    label={tier}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  plan="outlined"
                                  label={intl.formatMessage({ id: `tags` })}
                                  placeholder={intl.formatMessage({
                                    id: "add_tag_placeholder",
                                  })}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  {(dirty || plans.length > 0) && (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid
                            item
                            container
                            spacing={2}
                            justifyContent="center"
                          >
                            <Grid item>
                              <LoadingButton
                                type="submit"
                                plan="contained"
                                loading={isSubmitting}
                                disabled={formik.isSubmitting}
                                startIcon={<SaveIcon />}
                              >
                                {intl.formatMessage({ id: "save" })}
                              </LoadingButton>
                            </Grid>
                            <Grid item>
                              <LoadingButton
                                plan="contained"
                                onClick={resetForm}
                                startIcon={<RefreshIcon />}
                              >
                                {intl.formatMessage({ id: "reset" })}
                              </LoadingButton>
                            </Grid>
                            <Grid item>
                              <Button
                                plan="contained"
                                color="error"
                                onClick={handleDelete}
                                startIcon={<DeleteIcon />}
                              >
                                {intl.formatMessage({ id: "delete" })}
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "footer" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title={intl.formatMessage({ id: "tier" })} />
                    <CardContent>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <ListItemText
                            primary={intl.formatMessage({ id: "name" })}
                            secondary={tierName}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={intl.formatMessage({ id: "description" })}
                            secondary={tierDescription}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader title={intl.formatMessage({ id: "length" })} />
                    <CardContent>
                      <List
                        sx={{
                          width: "100%",
                          maxWidth: 360,
                          bgcolor: "background.paper",
                        }}
                      >
                        <ListItem>
                          <ListItemText
                            primary={intl.formatMessage({ id: "name" })}
                            secondary={lengthName}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={intl.formatMessage({ id: "begins" })}
                            secondary={
                              begins === "" || !begins
                                ? intl.formatMessage({ id: "anytime" })
                                : begins
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={intl.formatMessage({ id: "expires" })}
                            secondary={
                              expires === "no_expiry"
                                ? intl.formatMessage({ id: "no_expiry" })
                                : expires
                            }
                          />
                        </ListItem>
                        {days && (
                          <ListItem>
                            <ListItemText
                              primary={intl.formatMessage({ id: "days" })}
                              secondary={days}
                            />
                          </ListItem>
                        )}
                        {customBegins && (
                          <ListItem>
                            <ListItemText
                              primary={intl.formatMessage({
                                id: "custom_begins",
                              })}
                              secondary={customBegins}
                            />
                          </ListItem>
                        )}
                        {customExpires && (
                          <ListItem>
                            <ListItemText
                              primary={intl.formatMessage({
                                id: "custom_expires",
                              })}
                              secondary={customExpires}
                            />
                          </ListItem>
                        )}

                        <ListItem>
                          <ListItemText
                            primary={`${intl.formatMessage({
                              id: "is_renewable",
                            })}?`}
                            secondary={
                              isRenewable
                                ? intl.formatMessage({ id: "yes" })
                                : intl.formatMessage({ id: "no" })
                            }
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "products" })}
                      subheader={intl.formatMessage({
                        id: "membership_products_subheader",
                      })}
                    />
                    <CardContent>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item>
                          {customList(
                            intl.formatMessage({
                              id: "available",
                            }),
                            left
                          )}
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllRight}
                              disabled={left.length === 0}
                              aria-label="move all right"
                            >
                              ≫
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedRight}
                              disabled={leftChecked.length === 0}
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleCheckedLeft}
                              disabled={rightChecked.length === 0}
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                            <Button
                              sx={{ my: 0.5 }}
                              variant="outlined"
                              size="small"
                              onClick={handleAllLeft}
                              disabled={right.length === 0}
                              aria-label="move all left"
                            >
                              ≪
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>
                          {customList(
                            intl.formatMessage({
                              id: "selected",
                            }),
                            right
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "members" })}
                      subheader={intl.formatMessage({
                        id: "members_subheader",
                      })}
                    />
                    <CardContent>
                      <MembersTable
                        docName={"member"}
                        collectionName={"members"}
                        items={EMPTY_ARR}
                        membership={plan}
                        membershipStatus={status}
                        plans={
                          plans?.length > 0
                            ? plans
                            : [
                                {
                                  name: plan.name,
                                  planId: plan.id,
                                },
                              ]
                        }
                        statuses={statuses}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </RootStyle>
        </Form>
      </FormikProvider>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "are_you_sure" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
