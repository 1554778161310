import { PURGE } from "redux-persist";
import {
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import {
  addMember,
  addToCart,
  batchMemberships,
  batchProducts,
  confirmCheckout,
  deleteDocs,
  deleteOrders,
  deleteProduct,
  deleteProducts,
  deleteUser,
  fetchPickList,
  forgotPassword,
  logout,
  membershipsAccept,
  membershipsCancel,
  membershipsCancelInvite,
  membershipsDecline,
  membershipsInvite,
  signInWithEmailAndPassword,
  updateAgreement,
  updateDoc,
  updateOrders,
  updateProduct,
  updateLocation,
  updateUser,
} from "./index";

const initialState = {
  key: new Date().getTime(),
  open: false,
  message: undefined,
  severity: "success",
  vertical: "bottom",
  horizontal: "center",
  autoHideDuration: 6000,
  isLoading: [],
  pageLoading: 'idle',
};

const fulfilledReducer = (state, action) => {
  state.message = "success";
  state.key = new Date().getTime();
  state.open = true;
  state.severity = "success";
  state.pageLoading = 'idle';
};

const pendingReducer = (state, action) => {
  state.message = "pending";
  state.key = new Date().getTime();
  state.open = true;
  state.severity = "warning";
  state.pageLoading = 'loading';
};

const rejectionReducer = (state, action) => {
  state.message = action?.error?.message || "error";
  state.key = new Date().getTime();
  state.open = true;
  state.severity = "error";
  state.pageLoading = 'idle';
};

export const snackbars = createSlice({
  name: "snackbars",
  initialState,
  reducers: {
    setSnackbar: (state, { payload }) => ({ ...state, ...payload }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        state = initialState;
      })
      .addCase(logout.fulfilled, (state) => {
        state = initialState;
      })
      .addCase(addToCart.pending, (state, action) => {
        state.isLoading = ['cart'];
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.isLoading = state.isLoading.filter(i => i !== "cart");
      })
      .addCase(signInWithEmailAndPassword.rejected, (state, action) => {
        console.log({ action });
        state.key = new Date().getTime();
        state.open = true;
        state.severity = "error";
        state.pageLoading = 'idle';
        switch (action.payload) {
          case "auth/user-not-found": {
            state.message = 'User not found';
            break;
          }
          case "auth/wrong-password": {
            state.message = 'Wrong Password';
            break;
          }
          default: {
            state.message = action?.payload ? action?.payload : 'Rejected';
          }
        }
      })
      .addMatcher(
        isFulfilled(
          addMember,
          addToCart,
          batchMemberships,
          batchProducts,
          confirmCheckout,
          deleteDocs,
          deleteOrders,
          deleteProduct,
          deleteProducts,          
          deleteUser,
          fetchPickList,
          forgotPassword,
          membershipsAccept,
          membershipsCancel,
          membershipsCancelInvite,
          membershipsDecline,
          membershipsInvite,
          signInWithEmailAndPassword,
          updateAgreement,
          updateDoc,
          updateLocation,
          updateOrders,
          updateProduct,
          updateUser,
        ),
        fulfilledReducer
      )
      .addMatcher(
        isPending(
          addMember,
          // addToCart,
          batchMemberships,
          batchProducts,
          confirmCheckout,
          deleteDocs,
          deleteOrders,
          deleteProduct,
          deleteProducts,
          deleteUser,
          fetchPickList,
          forgotPassword,
          membershipsAccept,
          membershipsCancel,
          membershipsCancelInvite,
          membershipsDecline,
          membershipsInvite,
          signInWithEmailAndPassword,
          updateAgreement,
          updateDoc,
          updateLocation,
          updateOrders,
          updateProduct,
          updateUser,
        ),
        pendingReducer
      )
      .addMatcher(
        isRejected(
          addMember,
          addToCart,
          batchMemberships,
          batchProducts,
          confirmCheckout,
          deleteDocs,
          deleteOrders,
          deleteProduct,
          deleteProducts,
          deleteUser,
          fetchPickList,
          forgotPassword,
          membershipsAccept,
          membershipsCancel,
          membershipsCancelInvite,
          membershipsDecline,
          membershipsInvite,
          updateAgreement,
          updateDoc,
          updateLocation,
          updateOrders,
          updateProduct,
          updateUser,
        ),
        rejectionReducer
      );
  },
});

export const { setSnackbar } = snackbars.actions;

export const selectSnackbar = (state) => state.snackbars;

export const selectIsLoading = (state, page) =>
  state?.snackbars?.isLoading && state.snackbars.isLoading.includes(page);

export const selectIsPageLoading = (state) =>
  (state?.snackbars?.pageLoading && state.snackbars.pageLoading === 'loading') ?? false;

export default snackbars.reducer;
