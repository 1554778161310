import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import StorefrontIcon from "@mui/icons-material/Storefront";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
import sidebarConfig from "./SidebarConfig";
import {
  markAsRead,
  selectUnreadOrderCreatedNotifications,
  selectUser,
  selectVendorByOwnerId,
  usersSelectors,
  selectIsLoggedIn,
} from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const theme = useTheme();
  const [open, setOpen] = useState();
  const handleClick = () => {
    setOpen(!open);
  };

  const handleProfileClick = () => {
    navigate("/profile");
  };
  
  const handleVendorClick = () => {
    
   
  navigate(`/vendors/${vendor.vendorId}/edit`);
  };
  const { uid = "", displayName = "" } = useSelector(selectUser) || {
    email: undefined,
    uid: undefined,
    displayName: undefined,
  };

  const { photoURL, displayName: userName } = useSelector((state) =>
    usersSelectors.selectById(state, uid)
  ) || {
    photoURL: undefined,
    displayName: undefined,
  };
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { pathname } = useLocation();

  const vendor = useSelector((state) => selectVendorByOwnerId(state, uid));

  const unreadOrderCreatedNotifications = useSelector(selectUnreadOrderCreatedNotifications);
  
  const handleMarkAsRead =() => {
    dispatch(markAsRead(unreadOrderCreatedNotifications))
  }

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <Logo />
        </Box>
      </Box>

      <List sx={{ mb: 5, mx: 2.5 }}>
        <ListItem
          secondaryAction={
            <IconButton edge="end" aria-label="expand" onClick={handleClick}>
              {isLoggedIn && open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          }
          sx={{
            borderRadius: theme.shape.borderRadiusSm,
            backgroundColor: theme.palette.grey[200],
          }}
        >
          <ListItemAvatar>
            <Avatar src={photoURL ? photoURL : photoURL} alt="photoURL" />
          </ListItemAvatar>
          <ListItemText
            primary={
              isLoggedIn ? (
                <Box sx={{ ml: 2, overflow: "clip", textOverflow: "ellipsis" }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.primary" }}
                  >
                    {userName ? userName : displayName}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ ml: 2 }}>
                  <Button
                    fullWidth
                    color="inherit"
                    variant="outlined"
                    onClick={() => navigate("/login")}
                  >
                    {intl.formatMessage({ id: "sign_in" })}
                  </Button>
                </Box>
              )
            }
          />
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List>
            <ListItemButton sx={{ pl: 4 }} onClick={handleProfileClick}>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={intl.formatMessage({ id: "profile" })} />
            </ListItemButton>
            {vendor?.vendorId && (
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={handleVendorClick}
              >
                <ListItemIcon>
                  <StorefrontIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    vendor?.vendorName
                      ? vendor.vendorName
                      : intl.formatMessage({ id: "vendor" })
                  }
                />
              </ListItemButton>
            )}

            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate(`/purchases`)}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={intl.formatMessage({ id: "purchases" })} />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate(`/enrolments`)}
            >
              <ListItemIcon>
                <CardMembershipIcon />
              </ListItemIcon>
              <ListItemText
                primary={intl.formatMessage({ id: "enrolments" })}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      {vendor?.vendorId && (
        <NavSection navConfig={sidebarConfig(vendor.vendorId, unreadOrderCreatedNotifications?.length, handleMarkAsRead)} />
      )}

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default React.memo(DashboardSidebar);