import { styled } from "@mui/material/styles";
import { Stack, Container, Typography } from "@mui/material";
import Page from "../components/Page";
import AuthSocial from "../components/authentication/AuthSocial";
import { useIntl } from "react-intl";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const intl = useIntl();
  return (
    <RootStyle title={intl.formatMessage({ id: "sign_in_to_locazoa" })}>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/register">
          Get started
        </Link>
      </AuthLayout> */}
      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="column" sx={{ mb: 5 }} justifyContent="center">
            <Typography variant="h4" gutterBottom>
              {intl.formatMessage({ id: "sign_in_to_locazoa" })}
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
          </Stack>
          <AuthSocial />

          {/* <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="register">
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
