import { useEffect, } from "react";
import { useParams, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../components/Page';
import MembershipForm from './MembershipForm';
import {
  membershipPlansSelectors,
  removeOneMemberPlan,
  removeOneReserve,
  upsertOneMemberPlan,
  upsertOneReserve
} from '../../store';
import { useIntl } from 'react-intl';
import { db, } from "../../apis/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export default function Memberships() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  
  useEffect(() => {
    if (id) {
      const q0 = query(
        collection(db, `memberships/${id}/reserves`),
        where("planId", "==", id),
      );
      onSnapshot(q0, (snapshot) => {
        snapshot
          .docChanges()
          .forEach(({ type, doc }) =>
            type === "removed"
              ? dispatch(removeOneReserve(doc.id))
              : dispatch(
                  upsertOneReserve({
                    ...doc.data(),
                    id: doc.id,
                  })
                )
          );
      });
      const q1 = query(
        collection(db, `memberships/${id}/members`),
        where("membershipId", "==", id),
      );
      onSnapshot(q1, (snapshot) => {
        snapshot
          .docChanges()
          .forEach(({ type, doc }) =>
            type === "removed"
              ? dispatch(removeOneMemberPlan(doc.id))
              : dispatch(
                  upsertOneMemberPlan({
                    ...doc.data(),
                    id: doc.id,
                  })
                )
          );
      });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  // const navigate = useNavigate();
  // const [membership, setMembership] = useState();

  // useEffect(() => {
  //   async function getDoc(id) {
  //     console.log({ id });
  //     const { payload } = await dispatch(fetchDoc({collection: 'memberships', id}));
  //     if (payload) setMembership(payload);
  //     else navigate('/404/');
  //   }
  //   getDoc(id);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id])
  const membership = useSelector(state => membershipPlansSelectors.selectById(state, id));
  return (
    <Page title={(membership?.id) || intl.formatMessage({ id: 'membership' })}>
      {membership !== undefined ? <MembershipForm membership={membership} />
        :<div>loading</div>
      }      
    </Page>
  );
}