import { PURGE } from "redux-persist";
import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
  nanoid,
} from "@reduxjs/toolkit";
import { collection, getDocs, query, where } from "firebase/firestore";
import {
  logout,
  batchProducts,
  refreshSite,
  setSnackbar,
  selectProductVendor,
} from "./index";
import * as api from "../apis/firebase";

export const addMember = createAsyncThunk(
  "memberships/addMember",
  async ({ membershipId, planId, email, displayName }, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const memberId = nanoid(20);
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.membersAdd({
      displayName,
      email,
      memberId,
      membershipId,
      memberName: displayName,
      planId,
    });
    return response;
  }
);

export const batchMemberships = createAsyncThunk(
  "memberships/batchMemberships",
  async ({ parentId, products }, { dispatch, getState }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));

    const { uid, displayName, photoURL } = getState()?.session?.user ?? {};
    if (!uid) return;

    const author = selectProductVendor(getState(), uid);
    const { geohash = "", vendorPhoto, vendorName, vendorId } = author;

    const parent = membershipPlansSelectors.selectById(getState(), parentId);
    const plans = selectMembershipPlansByParentId(getState(), parentId);
    const allPlans = [parent, ...plans];

    const newPlans = allPlans.map((p) => ({
      ...p,
      availableFrom: p.availableFrom ?? Date.now(),
      membershipId: parentId,
      geohash,
      vendorId: vendorId ?? uid,
      vendorPhoto: vendorPhoto ?? photoURL,
      vendorName: vendorName ?? displayName,
    }));

    const members = Object.values(
      getState()?.memberships?.members?.entities ?? {}
    )
      .filter((member) => member.membershipId === parentId)
      .map((m) => ({
        ...m,
        vendorId,
      }));

    const parentIds = Array.from(
      new Set(products.map((product) => product.parentId))
    );

    try {
      await Promise.all(
        parentIds.map(async (parentId) => {
          await dispatch(batchProducts({ parentId, useSnackbar: false }));
        })
      );
    } catch {
      return null;
    }

    const batch = {
      plans: newPlans,
      products: [],
      members,
    };

    const response = await api.batchMemberships(batch);
    return response;
  }
);

export const deleteMemberships = createAsyncThunk(
  "memberships/deleteMany",
  async (ids = []) => {
    const response = Promise.all(
      ids.map(async (planId) => await api.removeDoc("memberships", planId))
    );
    return response;
  }
);

export const deleteManyMembers = createAsyncThunk(
  "memberships/deleteManyMembers",
  async (ids = [], { getState, dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const state = getState();
    const members = ids.map((id) => membersSelectors.selectById(state, id));
    const response = await Promise.all(
      members.map(async (member) => {
        await api.removeDoc(
          `memberships/${member.membershipId}/members`,
          member.id
        );
        await api.removeDoc(`memberships/${member.planId}/members`, member.id);
        dispatch(removeOneMemberPlan(member.id));
      })
    );
    // const response = Promise.all(
    //   savedMembers
    //     .filter((m) => m !== undefined)
    //     .map(async (planId) => await api.removeDoc("memberships", planId))
    // );
    return {
      ids,
      ...response,
    };
  }
);

export const membershipsAccept = createAsyncThunk(
  "memberships/accept",
  async (
    {
      body,
      email,
      inviteId,
      memberId,
      membershipId,
      note = "",
      planId,
      userId,
      userPhoto,
      vendorId,
      vendorName,
      vendorPhoto,
    },
    { dispatch }
  ) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.acceptMembership({
      body,
      email,
      inviteId,
      memberId,
      membershipId,
      note,
      planId,
      userId,
      userPhoto,
      vendorId,
      vendorName,
      vendorPhoto,
    });
    return response;
  }
);

export const membershipsCancel = createAsyncThunk(
  "memberships/cancel",
  async (payload, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.membershipsCancel(payload);
    return response;
  }
);

export const membershipsCancelInvite = createAsyncThunk(
  "memberships/cancelInvite",
  async (
    {
      memberId,
      membershipId,
      planId,
      inviteId,
      vendorId,
      vendorName,
      vendorPhoto,
    },
    { dispatch }
  ) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.membershipsCancelInvite({
      membershipId,
      planId,
      memberId,
      inviteId,
      vendorId,
      vendorName,
      vendorPhoto,
    });
    return response;
  }
);

export const membershipsDecline = createAsyncThunk(
  "memberships/decline",
  async ({ inviteId }, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.declineMembership({
      inviteId,
    });
    return response;
  }
);

export const membershipsInit = createAsyncThunk(
  "memberships/init",
  async ({ collectionName, docRefId, vendorId }, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.updateDoc({
      collection: collectionName,
      id: docRefId,
      payload: {
        id: docRefId,
        vendorId,
        parentId: docRefId,
        status: "draft",
      },
    });
    return response;
  }
);

export const membershipsInvite = createAsyncThunk(
  "memberships/invite",
  async (payload, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.membershipsInvite(payload);
    return response;
  }
);

export const resetMembership = createAsyncThunk(
  "memberships/reset",
  async (parentId, { dispatch }) => {
    dispatch(resetPlans({ parentId }));
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const q = query(
      collection(api.db, "memberships"),
      where("parentId", "==", parentId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dispatch(upsertOneMembershipPlan({ id: doc.id, ...doc.data() }));
    });
    return {
      ...querySnapshot,
    };
  }
);

const childLengthsAdapter = createEntityAdapter({
  selectId: (length) => length.lengthId,
});

const childTiersAdapter = createEntityAdapter({
  selectId: (tier) => tier.tierId,
});

const enrolmentsAdapter = createEntityAdapter();

const membersAdapter = createEntityAdapter();

const plansAdapter = createEntityAdapter();

const reservesAdapter = createEntityAdapter();

export const enrolmentsSelectors = enrolmentsAdapter.getSelectors(
  (state) => state.memberships.enrolments
);

export const membershipPlansSelectors = plansAdapter.getSelectors(
  (state) => state.memberships.plans
);

export const membersSelectors = membersAdapter.getSelectors(
  (state) => state.memberships.members
);

export const reservesSelectors = reservesAdapter.getSelectors(
  (state) => state.memberships.reserves
);

export const planSelected = createAction("memberships/planSelected");

const initialState = {
  enrolments: enrolmentsAdapter.getInitialState(),
  members: membersAdapter.getInitialState(),
  plans: plansAdapter.getInitialState(),
  reserves: reservesAdapter.getInitialState(),
};

export const memberships = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    addOneMembershipPlan: {
      reducer: (state, action) => {
        state.plans = state.plans ?? plansAdapter.getInitialState();
        plansAdapter.addOne(state.plans, action.payload);
      },
      prepare: (data) => {
        const lengthId = nanoid(20);
        const planId = nanoid(20);
        const tierId = nanoid(20);
        return {
          id: planId,
          planId,
          childLengths: childLengthsAdapter.getInitialState({
            ids: [lengthId],
            entities: {
              [lengthId]: {
                begins: "anytime",
                days: "",
                expires: "days",
                isRenewable: false,
                key: "",
                lengthId,
                name: "",
                planId,
              },
            },
          }),
          childTiers: childTiersAdapter.getInitialState({
            ids: [tierId],
            entities: {
              [tierId]: {
                description: "",
                discount: 0,
                key: "",
                name: "",
                planId,
                tierId,
              },
            },
          }),
          ...data,
        };
      },
    },
    addMembership: {
      reducer: (state, action) => {
        const planId = action.payload.planId;
        const planEntry = state?.plans?.entities[planId];
        if (planEntry) {
          childTiersAdapter.addOne(planEntry.childTiers, action.payload);
          childLengthsAdapter.addOne(planEntry.childLengths, action.payload);
        }
      },
      prepare: (data) => {
        const lengthId = nanoid(20);
        const planId = nanoid(20);
        const tierId = nanoid(20);
        return {
          id: planId,
          planId,
          childLengths: childLengthsAdapter.getInitialState({
            ids: [lengthId],
            entities: {
              [lengthId]: {
                begins: "anytime",
                days: "",
                expires: "days",
                isRenewable: false,
                key: "",
                lengthId,
                name: "",
                planId,
              },
            },
          }),
          childTiers: childTiersAdapter.getInitialState({
            ids: [tierId],
            entities: {
              [tierId]: {
                description: "",
                discount: 0,
                key: "",
                name: "",
                planId,
                tierId,
              },
            },
          }),
          ...data,
        };
      },
    },
    initMembership(state, { payload }) {
      const { planId } = payload;
      const lengthId = nanoid(20);
      const tierId = nanoid(20);
      const newPlans = [
        {
          childLengths: childLengthsAdapter.getInitialState({
            ids: [lengthId],
            entities: {
              [lengthId]: {
                begins: "anytime",
                days: 365,
                expires: "no_expiry",
                isRenewable: false,
                key: "",
                lengthId,
                name: "Open",
                planId,
              },
            },
          }),
          childTiers: childTiersAdapter.getInitialState({
            ids: [tierId],
            entities: {
              [tierId]: {
                description: "",
                discount: 0,
                key: "",
                name: "Standard",
                planId,
                tierId,
              },
            },
          }),
          id: planId,
          maxPerOrder: "",
          planId,
          selectedPlanId: `${planId}-Standard-Open`,
        },
        {
          begins: "anytime",
          childLengths: { ids: [], entities: {} },
          childTiers: { ids: [], entities: {} },
          days: 365,
          expires: "no_expiry",
          handle: "Standard-Open",
          id: `${planId}-Standard-Open`,
          isRenewable: false,
          length: {
            name: "Open",
            expires: "no_expiry",
          },
          lengthName: "Open",
          lengthId,
          tier: { name: "Standard" },
          tierId,
          tierDescription: "",
          tierDiscount: 0,
          tierName: "Standard",
          maxPerOrder: "",
          membershipId: planId,
          name: "Standard / Open",
          parentId: planId,
          parentName: "",
        },
      ];
      plansAdapter.upsertMany(state.plans, newPlans);
    },
    removeOneEnrolment(state, action) {
      enrolmentsAdapter.removeOne(state.enrolments, action.payload);
    },
    removeOneMembershipPlan(state, action) {
      plansAdapter.removeOne(state, action.payload);
    },
    resetPlans(state, action) {
      const plansToDelete = Object.values(state.plans.entities).filter(
        (plan) =>
          plan.parentId === action.payload.parentId && plan.id !== plan.parentId
      );
      plansAdapter.removeMany(
        state.plans,
        plansToDelete.map((plan) => plan.id)
      );
    },

    upsertMembershipAndPlans: {
      reducer: (state, action) => {
        const {
          available,
          id,
          childLengths,
          childTiers,
          length,
          tier,
          name,
          nameText,
          membershipPhoto: parentMembershipPhoto,
          membershipPhotoId,
          membershipId,
          planId,
          price,
          ...rest
        } = action.payload;

        const oldPlans = Object.values(state.plans.entities).filter(
          (entity) => entity.parentId === id && entity.id !== entity.parentId
        );
        const numberOfPlans = oldPlans?.length || 1;
        const newPlans = oldPlans.map(({ id: childId, membershipPhoto }) =>
          numberOfPlans > 1
            ? {
                id: childId,
                ...rest,
                membershipPhoto: membershipPhoto || parentMembershipPhoto,
                parentName: name,
              }
            : {
                id: childId,
                ...rest,
                available,
                price,
                membershipPhoto: membershipPhoto || parentMembershipPhoto,
                parentName: name,
              }
        );
        console.log({ newPlans, price, numberOfPlans });
        plansAdapter.upsertMany(state.plans, [action.payload, ...newPlans]);
      },
      prepare: (data) => {
        const { at, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    upsertOneEnrolment: {
      reducer: (state, action) => {
        if (state.enrolments === undefined) {
          state.enrolments = enrolmentsAdapter.getInitialState();
        }
        enrolmentsAdapter.upsertOne(state.enrolments, action.payload);
      },
      prepare: (data) => {
        const { updated, created, at, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    upsertOneMembershipPlan: {
      reducer: (state, { payload }) => {
        plansAdapter.upsertOne(state.plans, payload);
      },
      prepare: (data) => {
        const { geoPoint, created, at, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    upsertOneReserve: {
      reducer: (state, action) => {
        if (!state.reserves?.entities) {
          state.reserves = reservesAdapter.getInitialState();
        }
        reservesAdapter.upsertOne(state.reserves, action.payload);
      },
      prepare: (data) => {
        const { at, updated, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    addOneMemberPlan: {
      reducer: (state, action) => {
        if (!state.plans?.entities) {
          state.members = membersAdapter.getInitialState();
        }
        membersAdapter.upsertOne(state.members, action.payload);
      },
      prepare: (data) => {
        const memberId = nanoid();
        const { at, ...rest } = data;
        return {
          payload: {
            ...rest,
            id: memberId,
            memberId,
            status: "contact",
          },
        };
      },
    },
    upsertOneMemberPlan: {
      reducer: (state, action) => {
        if (!state.plans?.entities) {
          state.members = membersAdapter.getInitialState();
        }
        membersAdapter.upsertOne(state.members, action.payload);
      },
      prepare: (data) => {
        const { at, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    removeAllMembershipPlans(state) {
      plansAdapter.removeAll(state.plans);
    },
    removeOneMemberPlan(state, action) {
      membersAdapter.removeOne(state.members, action.payload);
    },
    removeOneReserve(state, action) {
      reservesAdapter.removeOne(state.reserves, action.payload);
    },
    removeManyMemberPlan(state, action) {
      membersAdapter.removeMany(state.members, [...action.payload]);
    },
    removeAllMembershipPlan: plansAdapter.removeAll,
    removeManyMembershipPlan(state, action) {
      plansAdapter.removeMany(state.plans, [...action.payload]);
    },
    addOneMembershipLength: {
      reducer: (state, action) => {
        const planId = action.payload.planId;
        const planEntry = state.plans.entities?.[planId];
        if (planEntry) {
          if (!planEntry.childLengths) {
            planEntry.childLengths = {
              ids: [],
              entities: {},
            };
          }
          childLengthsAdapter.addOne(planEntry.childLengths, action.payload);
          const childTierEntities = planEntry?.childTiers?.entities || {};
          if (Object.keys(childTierEntities)?.length < 1) {
            addOneMembershipTier(state.plans, { planId });
          }
        }
      },
      prepare: (data) => {
        const lengthId = nanoid();
        return {
          payload: {
            begins: "anytime",
            days: "",
            expires: "days",
            isRenewable: false,
            key: "",
            lengthId,
            name: "",
            ...data,
          },
        };
      },
    },
    upsertOneMembershipLength: {
      reducer: (state, { payload }) => {
        const { planId, parentId } = payload;
        const planEntry = state.plans?.entities[planId];
        if (!planEntry) return;
        childLengthsAdapter.upsertOne(planEntry.childLengths, payload);
        const {
          parentName = "",
          childTiers = {},
          childLengths = {},
          ...rest
        } = planEntry;

        const currentPlans = Object.values(state.plans.entities).filter(
          (plan) => plan.parentId === parentId && plan.id !== plan.parentId
        );
        const currentPlanMap = currentPlans.reduce((acc, plan) => {
          acc[plan.handle] = plan;
          return acc;
        }, {});

        const plans = [
          Object.values(childTiers?.entities),
          Object.values(childLengths?.entities),
        ]
          .reduce((a = [], b = []) =>
            b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
          )
          .map((plan) => {
            const arr = Array.isArray(plan) ? plan : [plan];
            const tier = arr.find((item) => item.tierId) || {};
            const length = arr.find((item) => item.lengthId) || {};
            const handle = arr.reduce(
              (acc, curr, j) =>
                acc.concat(
                  `${j > 0 ? `-` : ""}${curr.name.replace(/\s/g, "")}`
                ),
              ""
            );
            const id = arr.reduce(
              (acc, curr) => acc.concat(`-${curr.name.replace(/\s/g, "")}`),
              `${planId}`
            );
            const name = arr.reduce(
              (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.name}`),
              ""
            );
            const newPlan = arr.reduce((acc, curr) => ({ ...acc, ...curr }));

            // Preserve existing plan if handle and name match
            if (currentPlanMap[handle]) {
              return currentPlanMap[handle];
            }
            return {
              ...newPlan,
              ...rest,
              begins: length.begins,
              childLengths: { ids: [], entities: {} },
              childTiers: { ids: [], entities: {} },
              customBegins: length.customBegins,
              customExpires: length.customExpires,
              days: length.days,
              expires: length.expires,
              handle,
              id,
              isRenewable: length.isRenewable,
              name,
              length,
              lengthName: length.name,
              parentName,
              parentId: planId,
              planId: id,
              membershipId: planId,
              tier,
            };
          });
        const plansToDelete = Object.values(state.plans.entities).filter(
          (plan) =>
            plan.parentId === parentId &&
            plan.id !== plan.parentId &&
            !plans.find((p) => p.id === plan.id)
        );

        plansAdapter.removeMany(
          state.plans,
          plansToDelete.map((plan) => plan.id)
        );

        plansAdapter.setMany(state.plans, [
          {
            ...planEntry,
            hasPlans: true,
            selectedPlanId: plans.length > 0 ? plans[0].id : planId,
          },
          ...plans,
        ]);
      },
      prepare: (data) => {
        const { customExpires, customBegins, ...rest } = data;
        return {
          payload: {
            customExpires: customExpires ? customExpires : "",
            customBegins: customBegins ? customBegins : "",
            ...rest,
          },
        };
      },
    },
    addOneMembershipTier: {
      reducer: (state, action) => {
        const planId = action.payload.planId;
        const planEntry = state?.plans?.entities?.[planId];
        if (planEntry) {
          if (!planEntry.childTiers) {
            planEntry.childTiers = {
              ids: [],
              entities: {},
            };
          }
          childTiersAdapter.addOne(planEntry.childTiers, action.payload);
          const childLengthsEntities = planEntry?.childLengths?.entities || {};
          if (Object.keys(childLengthsEntities)?.length < 1) {
            addOneMembershipLength(state.plans, { planId });
          }
        }
      },
      prepare: (data) => {
        const tierId = nanoid(20);
        return {
          payload: {
            tierId,
            name: "",
            description: "",
            discount: 0,
            key: "",
            ...data,
          },
        };
      },
    },
    upsertOneMembershipTier(state, action) {
      const { payload } = action;
      const { planId, parentId } = payload;
      const planEntry = state.plans?.entities[planId];
      if (!planEntry) return { state };

      if (planEntry.childTiers === undefined) {
        plansAdapter.upsertOne(planEntry, {
          childTiers: { ids: [], entities: {} },
        });
      }
      childTiersAdapter.upsertOne(planEntry.childTiers, action.payload);
      const {
        name: parentName = "",
        childTiers = {},
        childLengths = {},
        ...rest
      } = planEntry;

      const currentPlans = Object.values(state.plans.entities).filter(
        (plan) => plan.parentId === parentId && plan.id !== plan.parentId
      );
      const currentPlanMap = currentPlans.reduce((acc, plan) => {
        acc[plan.handle] = plan;
        return acc;
      }, {});

      const plans = [
        Object.values(childTiers?.entities),
        Object.values(childLengths?.entities),
      ]
        .reduce((a = [], b = []) =>
          b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
        )
        .map((plan) => {
          const arr = Array.isArray(plan) ? plan : [plan];
          const tier = arr.find((item) => item.tierId) || {};
          const length = arr.find((item) => item.lengthId) || {};
          const handle = arr.reduce(
            (acc, curr, j) =>
              acc.concat(`${j > 0 ? `-` : ""}${curr.name.replace(/\s/g, "")}`),
            ""
          );
          const id = arr.reduce(
            (acc, curr) => acc.concat(`-${curr.name.replace(/\s/g, "")}`),
            `${planId}`
          );
          const name = arr.reduce(
            (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.name}`),
            ""
          );
          const newPlan = arr.reduce((acc, curr) => ({ ...acc, ...curr }));

          // Preserve existing plan if handle and name match
          if (currentPlanMap[handle]) {
            return currentPlanMap[handle];
          }

          return {
            ...newPlan,
            ...rest,
            begins: length.begins,
            childLengths: { ids: [], entities: {} },
            childTiers: { ids: [], entities: {} },
            customBegins: length.customBegins,
            customExpires: length.customExpires,
            days: length.days,
            expires: length.expires,
            handle,
            id,
            isRenewable: length.isRenewable,
            name,
            length,
            lengthName: length.name,
            parentName,
            parentId: planId,
            planId: id,
            membershipId: planId,
            tier,
            tierName: tier.name,
            tierDiscount: tier.discount,
            tierDescription: tier.description,
          };
        });

      //...
      // Insert your existing code above here.

      const plansToDelete = Object.values(state.plans.entities).filter(
        (plan) =>
          plan.parentId === parentId &&
          plan.id !== plan.parentId &&
          !plans.find((p) => p.id === plan.id)
      );

      plansAdapter.removeMany(
        state.plans,
        plansToDelete.map((plan) => plan.id)
      );

      plansAdapter.setMany(state.plans, [
        {
          ...planEntry,
          hasPlans: true,
          selectedPlanId: plans.length > 0 ? plans[0].id : planId,
        },
        ...plans,
      ]);
    },
    removeOneMembershipLength(state, action) {
      const { planId, lengthId, parentId } = action.payload;
      const planEntry = state.plans?.entities[planId];
      childTiersAdapter.removeOne(planEntry.childLengths, lengthId);
      const childTiers = Object.values(planEntry?.childTiers?.entities);
      const childLengths = Object.values(planEntry?.childLengths?.entities);
      const { available, isTaxed, membershipPhoto, price } = planEntry;
      const currentPlans = Object.values(state.plans.entities).filter(
        (plan) => plan.parentId === parentId && plan.id !== plan.parentId
      );
      const currentPlanMap = currentPlans.reduce((acc, plan) => {
        acc[plan.handle] = plan;
        return acc;
      }, {});
      const plans = [childTiers, childLengths]
        .reduce((a = [], b = []) =>
          b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
        )
        .map((plan) => {
          const arr = Array.isArray(plan) ? plan : [plan];
          const tier = arr.find((item) => item.tierId) || {};
          const length = arr.find((item) => item.lengthId) || {};
          const handle = arr.reduce(
            (acc, curr, j) =>
              acc.concat(`${j > 0 ? `-` : ""}${curr.name.replace(/\s/g, "")}`),
            ""
          );
          const id = arr.reduce(
            (acc, curr) => acc.concat(`-${curr.name.replace(/\s/g, "")}`),
            `${planId}`
          );
          const name = arr.reduce(
            (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.name}`),
            ""
          );
          const newPlan = arr.reduce((acc, curr) => ({ ...acc, ...curr }));

          if (currentPlanMap[handle]) {
            return currentPlanMap[handle];
          }

          return {
            ...newPlan,
            available,
            isTaxed,
            membershipPhoto,
            price,
            begins: length.begins,
            childLengths: { ids: [], entities: {} },
            childTiers: { ids: [], entities: {} },
            customBegins: length.customBegins,
            customExpires: length.customExpires,
            days: length.days,
            expires: length.expires,
            handle,
            id,
            name,
            length,
            lengthName: length.name,
            parentName: planEntry.name || "",
            parentId: planId,
            planId,
            membershipId: planId,
            tier,
            tierName: tier.name,
            tierDiscount: tier.discount,
            tierDescription: tier.description,
          };
        });
      const plansToDelete = Object.values(state.plans.entities).filter(
        (plan) =>
          plan.parentId === parentId &&
          plan.id !== plan.parentId &&
          !plans.find((p) => p.id === plan.id)
      );

      plansAdapter.removeMany(
        state.plans,
        plansToDelete.map((plan) => plan.id)
      );

      plansAdapter.setMany(state.plans, [
        {
          ...planEntry,
          hasPlans: true,
          selectedPlanId: plans.length > 0 ? plans[0].id : planId,
        },
        ...plans,
      ]);
    },
    removeOneMembershipTier(state, action) {
      const { planId, tierId, parentId } = action.payload;
      const planEntry = state?.plans?.entities[planId];
      if (planEntry) {
        childTiersAdapter.removeOne(planEntry.childTiers, tierId);
        const childTiers = Object.values(planEntry?.childTiers?.entities);
        const childLengths = Object.values(planEntry?.childLengths?.entities);
        const { available, isTaxed, membershipPhoto, price } = planEntry;
        const currentPlans = Object.values(state.plans.entities).filter(
          (plan) => plan.parentId === parentId && plan.id !== plan.parentId
        );
        const currentPlanMap = currentPlans.reduce((acc, plan) => {
          acc[plan.handle] = plan;
          return acc;
        }, {});
        const plans = [childTiers, childLengths]
          .reduce((a = [], b = []) =>
            b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
          )
          .map((plan) => {
            const arr = Array.isArray(plan) ? plan : [plan];
            const tier = arr.find((item) => item.tierId) || {};
            const length = arr.find((item) => item.lengthId) || {};
            const handle = arr.reduce(
              (acc, curr, j) =>
                acc.concat(
                  `${j > 0 ? `-` : ""}${curr.name.replace(/\s/g, "")}`
                ),
              ""
            );
            const id = arr.reduce(
              (acc, curr) => acc.concat(`-${curr.name.replace(/\s/g, "")}`),
              `${planId}`
            );
            const name = arr.reduce(
              (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.name}`),
              ""
            );
            const newPlan = arr.reduce((acc, curr) => ({ ...acc, ...curr }));

            if (currentPlanMap[handle]) {
              return currentPlanMap[handle];
            }

            return {
              ...newPlan,
              available,
              isTaxed,
              membershipPhoto,
              price,
              begins: length.begins,
              childLengths: { ids: [], entities: {} },
              childTiers: { ids: [], entities: {} },
              customBegins: length.customBegins,
              customExpires: length.customExpires,
              days: length.days,
              expires: length.expires,
              handle,
              id,
              name,
              length,
              parentName: planEntry.name || "",
              parentId: planId,
              planId,
              membershipId: planId,
              tier,
              tierName: tier.name,
              tierDiscount: tier.discount,
              tierDescription: tier.description,
            };
          });

        const plansToDelete = Object.values(state.plans.entities).filter(
          (plan) =>
            plan.parentId === parentId &&
            plan.id !== plan.parentId &&
            !plans.find((p) => p.id === plan.id)
        );

        plansAdapter.removeMany(
          state.plans,
          plansToDelete.map((plan) => plan.id)
        );

        plansAdapter.setMany(state.plans, [
          {
            ...planEntry,
            hasPlans: true,
            selectedPlanId: plans.length > 0 ? plans[0].id : planId,
          },
          ...plans,
        ]);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        enrolmentsAdapter.removeAll(state.enrolments);
        membersAdapter.removeAll(state.members);
        plansAdapter.removeAll(state.plans);
        reservesAdapter.removeAll(state.reserves);
      })
      .addCase(addMember.pending, (state, action) => {
        console.log({ pending: action });
        // membersAdapter.upsertOne(state.members, action.payload);
        return state;
      })
      .addCase(addMember.fulfilled, (state, action) => {
        if (action.payload?.data) {
          membersAdapter.upsertOne(state.members, action.payload.data);
        }
        return state;
      })
      .addCase(addMember.rejected, (state, { payload }) => {
        console.log({ rejected: payload, state });
        return state;
      })
      .addCase(deleteManyMembers.fulfilled, (state, action) => {
        membersAdapter.removeMany(state.members, [...action.payload.ids]);
      })
      .addCase(membershipsInvite.fulfilled, (state, action) => {
        console.log({ action });
      })
      .addCase(membershipsInvite.rejected, (state, action) => {
        console.error({ action });
      })
      .addCase(resetMembership.fulfilled, (state) => {
        // membersAdapter.removeAll(state.members);
        // plansAdapter.removeAll(state.plans);
        // reservesAdapter.removeAll(state.reserves);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        membersAdapter.removeAll(state.members);
        plansAdapter.removeAll(state.plans);
        reservesAdapter.removeAll(state.reserves);
      })
      .addCase(logout.fulfilled, (state) => {
        if (state.enrolments) enrolmentsAdapter.removeAll(state.enrolments);
        membersAdapter.removeAll(state.members);
        plansAdapter.removeAll(state.plans);
        reservesAdapter.removeAll(state.reserves);
      })
      .addCase(batchMemberships.fulfilled, (state, action) => {})
      .addCase(planSelected, (state, { payload }) => {
        const { planId, lengthId, tierId } = payload;
        console.log({ planId, lengthId, tierId });
        const planEntry = state.plans.entities[planId];
        if (planEntry) {
          for (const childLength of planEntry.childLengths.ids) {
            childLengthsAdapter.upsertOne(planEntry.childLengths, {
              lengthId: childLength,
              selected: lengthId,
            });
          }
          for (const childTier of planEntry.childTiers.ids) {
            childTiersAdapter.upsertOne(planEntry.childTiers, {
              tierId: childTier,
              selected: tierId,
            });
          }
          const selectedPlanId = Object.values(state.plans.entities).find(
            (p) => p.tier.tierId === tierId && p.length.lengthId === lengthId
          )?.id;
          if (selectedPlanId) {
            plansAdapter.upsertOne(state.plans, {
              id: planId,
              selectedPlanId,
            });
          }
        }
      });
  },
});

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  membershipPlansSelectors;

export const selectMembershipsPlans = (state) => state.membership.plans;

export const {
  addOneMemberPlan,
  addOneMembershipLength,
  addOneMembershipTier,
  initMembership,
  removeAllMembershipPlan,
  removeOneMemberPlan,
  removeOneMembershipPlan,
  removeManyMembershipPlan,
  removeOneReserve,
  upsertOneEnrolment,
  upsertOneMemberPlan,
  upsertOneMembershipLength,
  upsertOneMembershipTier,
  resetPlans,
  removeOneEnrolment,
  removeOneMembershipLength,
  removeOneMembershipTier,
  removeManyMemberPlan,
  upsertMembershipAndPlans,
  upsertOneMembershipPlan,
  upsertOneReserve,
} = memberships.actions;

export const selectMembershipPlanPlansByParentId = createSelector(
  [membershipPlansSelectors.selectAll, (state, parentId) => parentId],
  (plans, parentId) =>
    plans.filter(
      (plan) => parentId === plan.parentId && plan.id !== plan.parentId
    )
);

const selectPlans = (state) => membershipPlansSelectors.selectAll(state);

export const selectActiveMembershipsByParent = createSelector(
  [selectPlans, (state, parentId) => parentId],
  (plans, parentId) =>
    plans.filter(
      ({ id, parentId, status = "draft" }) =>
        id === parentId && status === "active"
    )
);

export const selectMembershipPlansByParentId = createSelector(
  [selectPlans, (state, parentId) => parentId],
  (plans, parentId) =>
    plans.filter(
      (plan) => parentId === plan.parentId && plan.id !== plan.parentId
    )
);

export const selectMembershipPlansByVariant = createSelector(
  membershipPlansSelectors.selectAll,
  (plans) => plans.filter(({ id, parentId }) => id === parentId)
);

export const selectMembershipsByVendor = createSelector(
  [membershipPlansSelectors.selectAll, (state, vendorId) => vendorId],
  (plans, vendorId) => plans.filter((plan) => plan.vendorId === vendorId)
);

export const selectMembershipMastersByVendor = createSelector(
  [membershipPlansSelectors.selectAll, (state, vendorId) => vendorId],
  (plans, vendorId) =>
    plans.filter(
      (plan) => plan.vendorId === vendorId && plan.id === plan.parentId
    )
);

export const selectPlansByVendor = createSelector(
  [membershipPlansSelectors.selectAll, (state, vendorId) => vendorId],
  (plans, vendorId) =>
    plans.filter(
      (plan) => plan.vendorId === vendorId && plan.id !== plan.parentId
    )
);

export const selectReservesByPlanId = createSelector(
  [reservesSelectors.selectAll, (state, planId) => planId],
  (reserves, planId) =>
    reserves
      .filter((reserve) => planId === reserve.planId)
      .map(({ productId }) => productId)
);

export const selectReservesParentsByPlanId = createSelector(
  [reservesSelectors.selectAll, (state, planId) => planId],
  (reserves, planId) =>
    reserves
      .filter(
        (reserve) =>
          planId === reserve.planId && reserve.id === reserve.parentId
      )
      .map(({ productId }) => productId)
);

export const selectMemberPlansByMembershipId = createSelector(
  [membersSelectors.selectAll, (state, membershipId) => membershipId],
  (plans, membershipId) =>
    plans.filter((plan) => membershipId === plan?.membershipId)
);

export default memberships.reducer;
