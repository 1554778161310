import { PURGE } from "redux-persist";
import {
  createSelector,
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { logout, refreshSite, setSnackbar } from "./index";
import * as api from "../apis/firebase";

export const deleteOrders = createAsyncThunk(
  "orders/deleteOrders",
  async (selectedOrders = []) => {
    const response = Promise.all(
      selectedOrders.map(
        async (orderId) => await api.removeDoc("orders", orderId)
      )
    );
    return response;
  }
);

export const fetchOrderLines = createAsyncThunk(
  "orders/fetchOrderLines",
  async (orderId, { dispatch }) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const querySnapshot = await api.fetchOrderLines(orderId);
    querySnapshot.forEach((doc) => {
      dispatch(
        upsertOneOrderLine({ ...doc.data(), id: doc.id, at: "", updated: "" })
      );
    });
  }
);

export const fetchPickList = createAsyncThunk(
  'orders/fetchPickList',
  async (orderIds, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const response = await api.fetchPickList(orderIds);
    return response;
  }
);


export const updateOrders = createAsyncThunk(
  "orders/batch",
  async (payload, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const {
      selectedOrders = [],
      selectedFulfillmentStasus = "",
      selectedstatus = "",
      selectedPaymentStatus = "",
    } = payload;
    const ordersToUpdate = selectedOrders.map((id) => {
      const newOrder = { id };
      selectedstatus && (newOrder.status = selectedstatus);
      selectedFulfillmentStasus &&
        (newOrder.fulfillmentStatus = selectedFulfillmentStasus);
      selectedPaymentStatus && (newOrder.paymentStatus = selectedPaymentStatus);
      return newOrder;
    });
    const response = Promise.all(
      ordersToUpdate.map(
        async (order) => await api.updateDoc("orders", order.id, order)
      )
    );
    return response;
  }
);

const linesAdapter = createEntityAdapter();

const ordersAdapter = createEntityAdapter();

export const linesSelectors = linesAdapter.getSelectors(
  (state) => state.orders.lines
);

export const ordersSelectors = ordersAdapter.getSelectors(
  (state) => state.orders.orders
);

export const orders = createSlice({
  name: "orders",
  initialState: {
    lines: linesAdapter.getInitialState(),
    orders: ordersAdapter.getInitialState(),
  },
  reducers: {
    upsertOneOrder: {
      reducer: (state, action) => {
        ordersAdapter.upsertOne(state.orders, action.payload);
      },
      prepare: (data) => {        
        const { at, updated, ...rest } = data;
        return {
          payload: {
            ...rest,
          }
        }
      }
    },
    upsertOneOrderLine: {
      reducer: (state, action) => {
        linesAdapter.upsertOne(state.lines, action.payload);
      },
      prepare: (data) => {        
        const { at, updated, created, ...rest } = data;
        return {
          payload: {
            ...rest,
          }
        }
      }
    },
    removeOneOrderLine(state, { payload }) {
      linesAdapter.removeOne(state.lines, payload);
    },
    removeOneOrder(state, { payload }) {
      ordersAdapter.removeOne(state.orders, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        linesAdapter.removeAll(state.lines);
        ordersAdapter.removeAll(state.orders);
      })
      .addCase(fetchOrderLines.fulfilled, (state, payload) => {
        console.log({ payload });
        // linesAdapter.setMany(state.lines, payload);
      })
      .addCase(fetchOrderLines.pending, (state, payload) => {
        console.log({ payload });
      })
      .addCase(fetchOrderLines.rejected, (state, payload) => {
        console.log({ payload });
      })
      // .addCase(fetchPickList.fulfilled, (state, payload) => {
      //   console.log({ payload });
      //   // linesAdapter.setMany(state.lines, payload);
      // })
      // .addCase(fetchPickList.pending, (state, payload) => {
      //   console.log({ payload });
      // })
      // .addCase(fetchPickList.rejected, (state, payload) => {
      //   console.log({ payload });
      // })
      .addCase(refreshSite.fulfilled, (state) => {
        linesAdapter.removeAll(state.lines);
        ordersAdapter.removeAll(state.orders);
      })
      .addCase(logout.fulfilled, (state) => {
        linesAdapter.removeAll(state.lines);
        ordersAdapter.removeAll(state.orders);
      });
  },
});

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  linesSelectors;

export const selectCount = createSelector(linesSelectors.selectAll, (items) =>
  items.reduce((count, item) => count + item.quantity, 0)
);

export const selectLinesByCustomerId = createSelector(
  [
    (state) => linesSelectors.selectAll(state),
    (state, customerId) => customerId,
  ],
  (items, customerId) => items.filter((item) => item.customerId === customerId)
);

export const selectLinesByOrderId = createSelector(
  [(state) => linesSelectors.selectAll(state), (state, orderId) => orderId],
  (items, orderId) => items.filter((item) => item.orderId === orderId)
);

export const selectOrdersByCustomerId = createSelector(
  [
    (state) => ordersSelectors.selectAll(state),
    (state, customerId) => customerId,
  ],
  (items, customerId) => items.filter((item) => item.customerId === customerId)
);

export const selectOrdersByVendorId = createSelector(
  [(state) => ordersSelectors.selectAll(state), (state, vendorId) => vendorId],
  (items, vendorId) => items.filter((item) => item.vendorId === vendorId)
);

export const selectLine = (state, product) =>
  linesSelectors.selectById(state, product.id)
    ? linesSelectors.selectById(state, product.id)
    : product;

export const {
  removeOneOrderLine,
  removeOneOrder,
  upsertOneOrderLine,
  upsertOneOrder,
} = orders.actions;

export default orders.reducer;
