import { Chip } from "@mui/material";

export const fulfillmentStatusChip = (fulfillmentStatus, label) => {
  switch (fulfillmentStatus) {
    case "unfulfilled":
      return <Chip variant="outlined" size="small" label={label} />;
    case "fulfilled":
      return <Chip color="success" size="small" label={label} />;
    default:
      return <Chip variant="outlined" size="small" label={label} />;
  }
};

export const statusChip = (status, label) => {
  switch (status.toString().toLowerCase()) {
    case "archived":
      return <Chip size="small" label={label} />;
    case "cancelled":
      return <Chip variant="filled" color="error" size="small" label={label} />;
    case "open":
      return <Chip variant="outlined" size="small" label={label} />;
    case "completed":
      return <Chip color="success" size="small" label={label} />;
    default:
      return <Chip variant="outlined" size="small" label={label} />;
  }
};

export const paymentStatusChip = (paymentStatus, label) => {
  switch (paymentStatus) {
    case "expired":
      return <Chip color="error" size="small" label={label} />;
    case "unpaid":
      return <Chip variant="outlined" size="small" label={label} />;
    case "overdue":
      return <Chip color="error" size="small" label={label} />;
    case "paid":
      return <Chip color="success" size="small" label={label} />;
    default:
      return <Chip variant="outlined" size="small" label={label} />;
  }
};
