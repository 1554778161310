import {
  createAsyncThunk,
} from "@reduxjs/toolkit";

import * as api from "../apis/firebase";

export const fetchDoc = createAsyncThunk(
  "docs/get",
  async ({ collection, id }) => {
    const response = await api.fetchDoc(collection, id);
    return response;
  }
);

export const updateDoc = createAsyncThunk(
  "docs/update",
  async ({ collection, id, payload }) => {
    const response = await api.updateDoc(collection, id, payload);
    return response;
  }
);

export const deleteDocs = createAsyncThunk(
  "docs/deleteDocs",
  async ({ collection, docs = []}) => {
    const response = Promise.all(
      docs.map(
        async (id) => await api.removeDoc(collection, id)
      )
    );
    return response;
  }
);
