import React from 'react';
import {useIntl} from "react-intl";
import {
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
  } from "@mui/material";

const Line = ({ line }) => {
	const intl = useIntl();
	const {
		currency = "CAD",
		isError,
		name,
		itemPhoto,
		subTotal,
		price,
		quantity,
		statuses,
		tax,
		taxName,
		salesChannel = 'online_shop',
		...rest
	} = line;

	const helperText = Array.isArray(rest?.helperText) ? rest.helperText.reduce(
		(acc, curr) =>
		  acc.concat(
			curr["isIntl"]
			  ? intl.formatMessage({ id: curr["isIntl"] })
			  : curr
		  ),
		""
	  ) : (rest?.helperText ? intl.formatMessage({ id: rest.helperText}) : '');

	return (
		<ListItem divider>
			<Grid container>
				<Grid item xs={4} sm={2} sx={{ display: 'flex', alignItems: 'center' }}>
					<ListItemAvatar>
						<Avatar
							alt={name}
							src={itemPhoto}
						/>
					</ListItemAvatar>
				</Grid>
				<Grid item xs={8} sm={3} sx={{ display: 'flex', alignItems: 'center' }}>
					<ListItemText
						primary={name}
						secondary={salesChannel === 'subscription' ? `from subscription` : null}
					/>
				</Grid>
				<Grid item xs={8} sm={3} sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
					<ListItemText
						primary={`${quantity} x ${intl.formatNumber(price, {
							style: "currency",
							currency: currency || "CAD",
						  })}`}
						secondary={helperText}
					/>
				</Grid>
				<Grid item xs={4} sm={3} sx={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
					<ListItemText
						primary={`= $${parseFloat(subTotal).toFixed(2)}`}
						secondary={tax > 0 ? `+$${parseFloat(tax).toFixed(2)} ${taxName}` : null}
					/>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
					<ListItemText
						primary={`${isError ? `${statuses}`: ``}`}
					/>
				</Grid>
			</Grid>
		</ListItem>
	)
}

export default Line