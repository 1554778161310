/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { forwardRef, useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import CancelIcon from "@mui/icons-material/Cancel";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Stack,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  addMember,
  contactsSelectors,
  deleteManyMembers,
  membershipsCancel,
  membershipsCancelInvite,
  membershipsInvite,
  selectMemberPlansByMembershipId,
} from "../../store";
import { useIntl } from "react-intl";
import { fetchDoc } from "../../apis/firebase";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
  return (
    <Toolbar>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mt={2}
        sx={{ width: "100%" }}
      >
        <Box>
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? "visible" : "hidden" }}
                  onClick={props.clearSearch}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
          <IconButton aria-label="Save">
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={props.membershipstatus !== "active"}
            aria-label="Invite"
            onClick={() => props.handleDeleteItems()}
          >
            <ContactMailIcon />
          </IconButton>
          <IconButton
            disabled={!props.isRowsSelected}
            aria-label="Delete"
            onClick={() => props.handleDeleteItems()}
          >
            <DeleteIcon />
          </IconButton>
          {/* <IconButton
            disabled={!props.isRowsSelected}
            aria-label="Delete"
            onClick={() => props.handleDeleteItems()}
          >
            <DeleteIcon />
          </IconButton> */}
        </Box>
        <Box>
          <Button
            variant="contained"
            to="#"
            startIcon={<AddIcon />}
            onClick={props.handleAddNewItem}
          >
            {props.newItemLabel}
          </Button>
        </Box>
      </Stack>
    </Toolbar>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleAddNewItem: PropTypes.func.isRequired,
  handleDeleteItems: PropTypes.func.isRequired,
  membershipstatus: PropTypes.bool.isRequired,
  newItemLabel: PropTypes.string.isRequired,
  isRowsSelected: PropTypes.bool.isRequired,
};

const CancelButton = forwardRef(function CancelButton(props, ref) {
  return (
    <span {...props} ref={ref}>
      <IconButton
        aria-label="cancel-invite"
        disabled={props.membershipstatus !== "active"}
      >
        <CancelScheduleSendIcon />
      </IconButton>
    </span>
  );
});

export default function MembersTable({
  collectionName,
  membership,
  membershipstatus,
  plans = [],
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { membershipId, vendorId, vendorName, vendorPhoto } = membership;
  const members = useSelector((state) =>
    selectMemberPlansByMembershipId(state, membershipId)
  );
  const contacts = useSelector((state) => contactsSelectors.selectAll(state));
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [cancelInviteDialogOpen, setCancelInviteDialogOpen] = useState(false);
  const [cancelInviteMembership, setCancelInviteMembership] = useState(false);
  const [cancelMembershipDialogOpen, setCancelMembershipDialogOpen] =
    useState(false);
  const [cancelMembership, setCancelMembership] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [inviteMembership, setInviteMembership] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [rows, setRows] = useState(members);
  const [searchText, setSearchText] = useState("");
  const [isRowsSelected, setIsRowsSelected] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState(plans);

  

  const MemberSchema = Yup.object().shape({
    plan: Yup.string().required(intl.formatMessage({ id: "required" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "invalid_email" }))
      .required(intl.formatMessage({ id: "required" })),
  });

  useEffect(() => {
    async function fetch() {
      const newRows = [];
      for (const plan of plans) {
        if (plan?.id) {
          const p = await fetchDoc("memberships", plan.id);
          if (p) newRows.push(p);
        }
      }
      setFilteredPlans(newRows);
    }
    if (plans.length > 0) fetch();
  }, [plans]);

  const formik = useFormik({
    initialValues: {
      email: "",
      displayName: "",
      plan: plans[0]?.id,
    },
    validationSchema: MemberSchema,
  });

  

  const {
    errors,
    getFieldProps,
    isSubmitting,
    setFieldValue,
    touched,
    values,
  } = formik;

  useEffect(() => {
    setRows(members);
  }, [members]);

  useEffect(() => {
    setIsRowsSelected(Boolean(selectedRows.length > 0));
  }, [selectedRows]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleOnEditRowsModelChange = useCallback((model) => {
    setSelectedRows(model);
  }, []);

  const handleCancelDialog = () => {
    setCancelInviteDialogOpen(false);
    setDialogOpen(false);
    setAddDialogOpen(false);
    setInviteDialogOpen(false);
    setCancelMembershipDialogOpen(false);
  };

  const handleOk = async () => {
    dispatch(deleteManyMembers(selectedItems));
    setDialogOpen(false);
  };

  const handleDeleteItems = useCallback(() => {
    setDialogOpen(true);
    setSelectedItems(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleDeleteItem = useCallback((id) => {
    setDialogOpen(true);
    setSelectedItems([id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!Boolean(touched.email && errors.email)) {
      dispatch(
        addMember({
          email: values.email,
          displayName: values.displayName,
          membershipId,
          planId: values.plan,
        })
      );
      formik.resetForm();
      setAddDialogOpen(false);
    }
  };

  const handleAddNewItem = useCallback(async () => {
    setAddDialogOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOkInvite = async () => {
    await dispatch(membershipsInvite(inviteMembership));
    setInviteDialogOpen(false);
    setInviteMembership(undefined);
  };

  const handleOkCancelMembership = async () => {
    await dispatch(membershipsCancel(cancelMembership));
    setCancelMembershipDialogOpen(false);
    setCancelMembership(undefined);
  };

  const handleMembershipCancelMembership = ({
    membershipId,
    planId,
    inviteId,
    memberId,
    userId,
    userPhoto,
  }) => {
    setCancelMembership({
      membershipId,
      planId,
      inviteId,
      memberId,
      userId,
      userPhoto,
      vendorId,
      vendorName,
      vendorPhoto,
    });
    setCancelMembershipDialogOpen(true);
  };

  const handleOkCancelInvite = async () => {
    await dispatch(membershipsCancelInvite(cancelInviteMembership));
    setCancelInviteDialogOpen(false);
    setInviteMembership(undefined);
  };

  const handleMembershipCancelInvite = ({
    membershipId,
    planId,
    inviteId,
    memberId,
  }) => {
    setCancelInviteMembership({
      membershipId,
      planId,
      inviteId,
      memberId,
      vendorId,
      vendorName,
      vendorPhoto,
    });
    setCancelInviteDialogOpen(true);
  };

  const handleMembershipInvite = (row) => {
    const p = plans.find((plan) => plan.id === values.plan);
    const selectedPlan = p ?? {};
    setInviteMembership({
      membershipId,
      note: "",
      ...row,
      ...selectedPlan,
      vendorId,
      vendorName,
      vendorPhoto,
    });
    setInviteDialogOpen(true);
  };

  const columns = useMemo(
    () => [
            {
        field: `photoURL`,
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        renderCell: ({ value }) => {
          return <Avatar src={value} />;
        },
        width: 55, // Set a fixed width for the Image column (adjust as needed)
      },
      {
        field: "displayName",
        headerName: `${intl.formatMessage({ id: "name" })}`,
        valueFormatter: ({ value }) =>
          value ? value : intl.formatMessage({ id: "no_name" }),
        flex: 1, // Takes up the rest of the available width equally
      },
      {
        field: "email",
        headerName: `${intl.formatMessage({ id: "email" })}`,
        valueFormatter: ({ value }) =>
          value ? value : intl.formatMessage({ id: "no_name" }),
        flex: 1, // Takes up the rest of the available width equally
      },
      {
        field: "planId",
        headerName: `${intl.formatMessage({ id: "plan" })}`,
        renderCell: ({ value }) =>
          `${plans.find((plan) => plan.id === value)?.name}`,
        flex: 1, // Takes up the rest of the available width equally
      },
      {
        field: "status",
        headerName: intl.formatMessage({ id: "status" }),
        width: 100, // Set a fixed width for the Status column (adjust as needed)
      },
      {
        field: "actions",
        type: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        cellClassName: "actions",
        getActions: ({ id, row }) => {
          if (row?.status === "invited") {
            return [
              <Tooltip title={intl.formatMessage({ id: "cancel_invite" })}>
                <CancelButton
                  membershipstatus={membershipstatus}
                  onClick={() => handleMembershipCancelInvite(row)}
                />
              </Tooltip>,
              <Tooltip title={intl.formatMessage({ id: "delete_member" })}>
                <span>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteItem(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            ];
          } else if (row?.status === "enrolled") {
            return [
              <Tooltip title={intl.formatMessage({ id: "cancel" })}>
                <span>
                  <IconButton
                    aria-label="cancel-membership"
                    disabled={membershipstatus !== "active"}
                    onClick={() => handleMembershipCancelMembership(row)}
                  >
                    <CancelIcon />
                  </IconButton>
                </span>
              </Tooltip>,
              <Tooltip title={intl.formatMessage({ id: "delete_member" })}>
                <span>
                  <IconButton
                    aria-label="Delete"
                    onClick={() => handleDeleteItem(id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>,
            ];
          } else {
            return [
              <IconButton
                aria-label="Contact"
                disabled={membershipstatus !== "active"}
                onClick={() => handleMembershipInvite(row)}
              >
                <ContactMailIcon />
              </IconButton>,
              <IconButton
                aria-label="Delete"
                onClick={() => handleDeleteItem(id)}
              >
                <DeleteIcon />
              </IconButton>,
            ];
          }
        },
        flex: "none", // Takes up only as much width as needed
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows, membershipstatus]
  );
  
  return (
    <>
      {filteredPlans.length < 1 ? (
        <Typography paragraph>
          {intl.formatMessage({ id: 'no_plans_saved' })}
        </Typography>
      ) : (
        <>
          <DataGrid
            autoHeight
            checkboxSelection
            columns={columns}
            slotProps={{
              toolbar: {
                isRowsSelected: isRowsSelected,
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(""),
                handleAddNewItem: () => handleAddNewItem(),
                handleDeleteItems: () => handleDeleteItems(),
                membershipstatus: Boolean(membershipstatus),
                newItemLabel: intl.formatMessage({ id: "new_member" }),
              },
            }}
            slots={{ toolbar: QuickSearchToolbar }}
            loading={isSubmitting}
            name={collectionName}
            onRowSelectionModelChange={handleOnEditRowsModelChange}
            rows={rows}
          />
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={dialogOpen}
          >
            <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
            <DialogContent>
              {intl.formatMessage({ id: "are_you_sure" })}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDialog}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
              <Button onClick={handleOk}>Ok</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={addDialogOpen}
          >
            <DialogTitle>
              {intl.formatMessage({ id: "add_member" })}
            </DialogTitle>
            <DialogContent>
              <FormikProvider value={formik}>
                <Form onSubmit={handleSubmit}>
                  <Grid container spacing={3} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <FormControl>
                        <TextField
                          id="displayName"
                          error={Boolean(
                            touched.displayName && errors.displayName
                          )}
                          fullWidth
                          name="displayName"
                          type="text"
                          helperText={
                            Boolean(touched.displayName && errors.displayName)
                              ? intl.formatMessage({ id: "invalid" })
                              : ""
                          }
                          label={`${intl.formatMessage({
                            id: "member",
                          })} ${intl.formatMessage({ id: "name" })}`}
                          onChange={formik.handleChange}
                          value={values.displayName}
                          InputLabelProps={{
                            shrink: values.displayName ? true : false,
                          }}
                          required
                          {...getFieldProps("displayName")}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <Autocomplete
                          id="email"
                          fullWidth
                          name="email"
                          freeSolo
                          onChange={(e, v) => setFieldValue("email", v)}
                          options={contacts.map((contact) => contact ? contact.email : "")}
                          required
                          renderInput={(params) => (
                            <TextField
                              name="email"
                              fullWidth
                              {...params}
                              onChange={formik.handleChange}
                              label={intl.formatMessage({ id: "email" })}
                              required
                            />
                          )}
                          sx={{ width: 300 }}
                        />
                        
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel id="plan">
                        {intl.formatMessage({ id: "select_a_plan" })}
                      </InputLabel>
                      <Select
                        name="plan"
                        autoWidth
                        error={Boolean(touched.plan && errors.plan)}
                        labelId="plan"
                        id="plan"
                        value={values.plan}
                        label={intl.formatMessage({ id: "select_a_plan" })}
                        onChange={formik.handleChange}
                        required
                      >
                        {filteredPlans
                        .filter(plan => plan.name !== undefined)
                        .map((plan, i) => (
                          <MenuItem key={i} value={plan.id}>
                            {plan.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDialog}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
              <Button onClick={handleSubmit}>
                {intl.formatMessage({ id: "submit" })}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={inviteDialogOpen}
          >
            <DialogTitle>
              {intl.formatMessage({ id: "membership_invite" })}
            </DialogTitle>
            <DialogContent>
              {intl.formatMessage({ id: "are_you_sure" })}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDialog}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
              <Button onClick={handleOkInvite}>
                {intl.formatMessage({ id: "submit" })}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={cancelInviteDialogOpen}
          >
            <DialogTitle>
              {intl.formatMessage({ id: "membership_cancel_invite" })}
            </DialogTitle>
            <DialogContent>
              {intl.formatMessage({ id: "are_you_sure" })}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDialog}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
              <Button onClick={handleOkCancelInvite}>
                {intl.formatMessage({ id: "submit" })}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
            maxWidth="xs"
            open={cancelMembershipDialogOpen}
          >
            <DialogTitle>{intl.formatMessage({ id: "cancel_membership" })}</DialogTitle>
            <DialogContent>
              {intl.formatMessage({ id: "are_you_sure" })}
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCancelDialog}>
                {intl.formatMessage({ id: "cancel" })}
              </Button>
              <Button onClick={handleOkCancelMembership}>
                {intl.formatMessage({ id: "submit" })}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
