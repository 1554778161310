import { useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import { UserForm } from '../components/forms/UserForm';
import { usersSelectors } from '../store';
import { useIntl } from 'react-intl';

export default function Product() {
  const { id } = useParams();
  const intl = useIntl();
  const user = useSelector(state => usersSelectors.selectById(state, id));
  return (
    <Page title={(id && user && user.id && user.name) || intl.formatMessage({ id: 'user' })}>
      {user !== undefined ? <UserForm user={user} />
        :<Navigate to="/404" />
      }    
    </Page>
  );
}