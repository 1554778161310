import { useEffect, } from "react";
import { useParams, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../components/Page';
import MembershipForm from './MembershipForm';
import {
  membershipPlansSelectors,
  removeOneMemberPlan,
  removeOneReserve,
  upsertOneMemberPlan,
  upsertOneReserve
} from '../../store';
import { useIntl } from 'react-intl';
import { db, } from "../../apis/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export default function Product() {
  const { membershipId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (!membershipId) return;
    const q0 = onSnapshot(query(
      collection(db, `memberships/${membershipId}/reserves`),
      where("planId", "==", membershipId),
    ), (snapshot) => {
      snapshot
        .docChanges()
        .forEach(({ type, doc }) =>
          type === "removed"
            ? dispatch(removeOneReserve(doc.id))
            : dispatch(
                upsertOneReserve({
                  ...doc.data(),
                  id: doc.id,
                })
              )
        );
    });
    
    const q1 = onSnapshot(query(
      collection(db, `memberships/${membershipId}/members`),
      where("membershipId", "==", membershipId),
    ), (snapshot) => {
      snapshot
        .docChanges()
        .forEach(({ type, doc }) =>
          type === "removed"
            ? dispatch(removeOneMemberPlan(doc.id))
            : dispatch(
                upsertOneMemberPlan({
                  ...doc.data(),
                  id: doc.id,
                })
              )
        );
    });
    return () => {
      q0();
      q1();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membershipId]);

  // const navigate = useNavigate();
  // const [membership, setMembership] = useState();

  // useEffect(() => {
  //   async function getDoc(id) {
  //     console.log({ id });
  //     const { payload } = await dispatch(fetchDoc({collection: 'memberships', id}));
  //     if (payload) setMembership(payload);
  //     else navigate('/404/');
  //   }
  //   getDoc(id);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id])
  const membership = useSelector(state => membershipPlansSelectors.selectById(state, membershipId));
  return (
    <Page title={(membership?.id) || intl.formatMessage({ id: 'membership' })}>
      {membership !== undefined ? <MembershipForm membership={membership} />
        :<div>loading</div>
      }      
    </Page>
  );
}
