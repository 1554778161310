import { Grid } from "@mui/material";
import React, { Suspense } from "react";

const ShopProductCard = React.lazy(() => import("./ProductCard").then(module => ({ default: module.default })).catch(() => ({ default: () => <div>Error loading ShopProductCard</div> })));
const MembershipCard = React.lazy(() => import("./MembershipCard").then(module => ({ default: module.default })).catch(() => ({ default: () => <div>Error loading MembershipCard</div> })));

function ProductList({ products = [], memberships = [], ...other }) {
  const items = React.useMemo(
    () => [...products, ...memberships],
    [products, memberships]
  );

  return (
    <Grid
      container
      spacing={2}
      {...other}
      alignItems="stretch"
    >
      {items.map((item, i) => (
        <Grid
          item
          xs={12}
          sm={4}
          lg={3}
          key={i}
          sx={{ display: "flex" }}
        >
          {" "}
          {item.membershipId ? (
            <Suspense fallback={<div>Loading MembershipCard...</div>}>
              <MembershipCard
                membership={item}
                index={i}
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </Suspense>
          ) : (
            <Suspense fallback={<div>Loading ShopProductCard...</div>}>
              <ShopProductCard
                product={item}
                index={i}
                sx={{ display: "flex", justifyContent: "center" }}
              />
            </Suspense>
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default ProductList;
