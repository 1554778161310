import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  // IconButton,
  Typography,
} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import Page from "../../components/Page";
import { ProductCartWidget } from "../../components/_dashboard/products";
import {
  enrolmentsSelectors,
  selectProductsByEnrolmentIdByParent,
} from "../../store";
import ShopProductCard from "../../components/_dashboard/products/ProductCard";

export default function Enrolment() {
  const { planId } = useParams();
  const invitation = useSelector((state) =>
    enrolmentsSelectors.selectById(state, planId)
  );

  const reserves = useSelector((state) =>
    selectProductsByEnrolmentIdByParent(state, planId)
  );

  const items = React.useMemo(
    () => reserves,
    [reserves]
  );

  const {
    description,
    // membershipPhoto,
    name,
    parentName,
    vendorPhoto,
  } = invitation ?? {
    email: "",
    memberId: "",
    membershipId: "",
    planId: "",
    userId: "",
  };
  // const membershipCard = {
  //   description,
  //   name,
  //   productPhoto: membershipPhoto,
  //   price,
  //   vendorName,
  //   vendorPhoto,
  // }

  return (
    <Page title={parentName}>
      <Container maxWidth="lg">
        <Card>
          <CardHeader
            avatar={<Avatar aria-label="vendor" src={vendorPhoto} />}
            title={parentName}
            subheader={name}
            action={
              <Chip
                icon={<VerifiedIcon />}
                label="Activated!"
                color="success"
              />
            }
          />
          <CardContent>
            <Typography paragraph>{description}</Typography>
            {reserves?.length > 0 && (
              <Grid container spacing={3}>
                {items
                  .filter((reserve) => reserve.id === reserve.parentId)
                  .map((reserve, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={i}
                      sx={{ display: "flex" }}
                    >
                      <MemoizedShopProductCard
                        product={reserve}
                        index={i}
                        sx={{ display: "flex", justifyContent: "center" }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </CardContent>
        </Card>
        <ProductCartWidget />
      </Container>
    </Page>
  );
}

const MemoizedShopProductCard = React.memo(ShopProductCard);
