import { PURGE } from "redux-persist";
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import { logout, refreshSite, setSnackbar } from "./index";
import * as api from "../apis/firebase";
const vendorsAdapter = createEntityAdapter({
  selectId: (vendor) => vendor.vendorId || '',
  // other configurations
});

export const deleteVendor = createAsyncThunk(
  "vendors/delete",
  async (id, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.removeDoc("vendors", id);
    return response;
  }
);

export const getVendorsByLatLngRadiusInM = createAsyncThunk(
  "vendors/getProductsByGeoHash",
  async (payload, { dispatch }) => {
    const { lat = 90, lng = 0, radiusInM = 2500 } = payload;
    const center = [lat, lng];
    const bounds = api.geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = query(
        collection(api.db, "vendors"),
        where("status", "==", "active"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
      promises.push(getDocs(q));
    }

    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const { geoPoint } = doc.data();
            if (geoPoint?._lat && geoPoint?._long) {
              const { _lat, _long } = geoPoint;
              const distanceInKm = api.geofire.distanceBetween(
                [_lat, _long],
                center
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc);
              }
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        for (const doc of matchingDocs) {
          dispatch(upsertOneVendor({ id: doc.id, ...doc.data() }));
        }
      });
  }
);

export const updateVendor = createAsyncThunk(
  "vendors/update",
  async ({ id, payload }, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.updateDoc('vendors', id, payload);
    return response;
  }
);

export const vendors = createSlice({
  name: "vendors",
  initialState: vendorsAdapter.getInitialState(),
  reducers: {
    upsertOneVendor: {
      reducer: (state, action) => {
        vendorsAdapter.upsertOne(state, action.payload);
      },
      prepare: (data) => {
        const { at, updated, created, geoPoint, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    removeOneVendor: vendorsAdapter.removeOne,
    removeAllVendor: vendorsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        vendorsAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        vendorsAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        vendorsAdapter.removeAll(state);
      });
  },
});

export const { upsertOneVendor, removeOneVendor, removeAllVendor } =
  vendors.actions;

export const vendorsSelectors = vendorsAdapter.getSelectors(
  (state) => state.vendors
);

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  vendorsSelectors;

  const vendorsEntitiesSelector = state => state.vendors?.entities || {};

  export const selectVendors = createSelector(
    vendorsEntitiesSelector,
    entities => Object.values(entities)
  );

export const selectVendorIds = (state) =>
  state?.vendors?.ids ? state.vendors.ids : [];

export const selectVendorByOwnerId = (state, ownerId) =>
  Object.values(state.vendors.entities).find(
    (entity) => ownerId === entity.ownerId
  );

export const selectVendorIdByOwnerId = (state, ownerId) =>
  Object.values(state.vendors.entities).find(
    (entity) => ownerId === entity.ownerId
  )?.vendorId;

export const selectVendorIdBySessionUserId = createSelector(
  [(state) => state, (state) => state.session?.user?.uid],
  (state, ownerId) =>
    Object.values(state.vendors.entities).find(
      (entity) => ownerId === entity.ownerId
    )?.vendorId
);

export const selectVendorLocations = createSelector(
  [(state) => Object.values(state.vendors.entities), (state) => Object.values(state.locations.entities)],
  (vendors, locations) =>
    vendors.map((vendor) => ({
      ...vendor,
      ...locations.find((location) => location.ownerId === vendor.vendorId),
    }))
);

export const selectProductVendor = (state, ownerId) =>
  selectVendorByOwnerId(state, ownerId)
    ? selectVendorByOwnerId(state, ownerId)
    : state.users.entities[ownerId];

export default vendors.reducer;
