/* eslint-disable react/style-prop-object */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useReducer,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useReactToPrint } from "react-to-print";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Navigation";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import Page from "../../components/Page";
import { useIntl } from "react-intl";
import {
  // fetchOrderLines,
  fetchArchivedOrders,
  toDate,
  now,
} from "../../apis/firebase";
import {
  deleteOrders,
  // selectArchivedOrdersByVendorId,
  selectFulfillmentStatuses,
  selectstatuses,
  selectPaymentStatuses,
  selectUserId,
  selectVendorIdByOwnerId,
  selectOrdersByVendorId,
  updateOrders,
  fetchPickList,
} from "../../store";
import {
  fulfillmentStatusChip,
  statusChip,
  paymentStatusChip,
} from "../../utils/chips";
import MobileView from "./MobileView";
import { useNavigate } from "react-router-dom";

const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return isMobile;
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
  return (
    <Box
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{
          width: {
            xs: 1,
            sm: "auto",
          },
          m: (theme) => theme.spacing(1, 0.5, 1.5),
          "& .MuiSvgIcon-root": {
            mr: 0.5,
          },
          "& .MuiInput-underline:before": {
            borderBottom: 1,
            borderColor: "divider",
          },
        }}
      />
    </Box>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

const initialState = {
  pickListOrders: {},
  pickListCategories: {},
  pickListDialogOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "clearPicklist": {
      return {
        ...state,
        pickList: { orders: {}, categories: {} },
      };
    }
    case "updatePicklist": {
      const { orders = {}, categories = {} } = action.payload;
      return {
        ...state,
        pickListOrders: orders,
        pickListCategories: categories,
      };
    }
    case "setState": {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      throw new Error();
  }
}

export default function Orders() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [
    { pickListOrders, pickListCategories, pickListDialogOpen },
    _dispatch,
  ] = useReducer(reducer, initialState);
  const userId = useSelector(selectUserId);
  const vendorId = useSelector((state) =>
    selectVendorIdByOwnerId(state, userId)
  );
  const orders = useSelector((state) =>
    selectOrdersByVendorId(state, vendorId)
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const fulfillmentStatuses = useSelector(selectFulfillmentStatuses);
  const statuses = useSelector(selectstatuses);
  const paymentStatuses = useSelector(selectPaymentStatuses);

  const [selectedRows, setSelectedRows] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedFulfillmentStasus, setSelectedFulfillmentStasus] =
    useState("");
  const [selectedstatus, setSelectedstatus] = useState("");
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState(orders);
  const [value, setValue] = useState(0);
  const [archives, setArchives] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  useEffect(() => {
    setRows(orders);
  }, [orders]);

  useEffect(() => {
    setRows(rows);
  }, [rows]);

  const handleOnEditRowsModelChange = useCallback((model) => {
    setSelectedRows(model);
  }, []);

  useEffect(() => {
    if (value === 1 && vendorId) {
      const fetchData = async () => {
        const querySnapshot = await fetchArchivedOrders(vendorId);
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push({
            ...doc.data(),
            id: doc.id,
            at: doc.data().at ? toDate(doc.data().at) : now(),
            created: doc.data().created ? toDate(doc.data().created) : now(),
            updated: doc.data().updated ? toDate(doc.data().updated) : now(),
          });
        });
        setArchives(data);
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, vendorId]);

  const handleCancelDialog = () => setDialogOpen(false);

  const handleOk = async () => {
    await dispatch(
      updateOrders({
        selectedOrders,
        selectedstatus,
        selectedPaymentStatus,
        selectedFulfillmentStasus,
      })
    );
    if (selectedstatus !== "archived" && value === 1) {
      if (selectedstatus === "completed") setValue(2);
      else setValue(0);
    }
    setDialogOpen(false);
    setSelectedstatus("");
    setSelectedFulfillmentStasus("");
    setSelectedPaymentStatus("");
    setSelectedOrders([]);
    setSelectedRows([]);
  };

  const handleOkDelete = async () => {
    await dispatch(deleteOrders(selectedRows));
    setDeleteDialogOpen(false);
    setSelectedstatus("");
    setSelectedFulfillmentStasus("");
    setSelectedPaymentStatus("");
    setSelectedOrders([]);
    setSelectedRows([]);
  };

  const handleUpdateOrders = useCallback(() => {
    setDialogOpen(true);
    setSelectedOrders(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleCreatePickList = async () => {
    const orderIds = orders
      .filter((order) => selectedRows.some((id) => id === order.id))
      .map((order) => order.id);
    const response = await dispatch(fetchPickList(orderIds));
    await _dispatch({
      type: "updatePicklist",
      payload: response?.payload?.data?.pickList || [],
    });
    _dispatch({ type: "setState", payload: { pickListDialogOpen: true } });
  };

   const handleDelete = useCallback(() => {
    setDeleteDialogOpen(true);
    setSelectedOrders(selectedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const isMobile = useIsMobile();

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: intl.formatMessage({ id: "order_id" }),
        type: "string",
        renderCell: ({ value }) => (
          <Typography
            onClick={() =>
              navigate(`/vendors/${vendorId}/orders/${value}/edit`)
            }
          >
            {value}
          </Typography>
        ),
      },
      {
        field: "created",
        headerName: intl.formatMessage({ id: "created" }),
        type: "date",
        flex: 1,
        valueGetter: ({ value }) => value && new Date(value),
      },
      {
        field: "customerName",
        headerName: intl.formatMessage({ id: "customer_name" }),
        type: "string",
        flex: 1,
        valueFormater: ({ value }) =>
          value ? value : intl.formatMessage({ id: "no_name" }),
      },
      {
        field: "vendorPhoto",
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        renderCell: ({ value }) => <Avatar src={value} />,
      },
      {
        field: "quantity",
        headerName: intl.formatMessage({ id: "quantity" }),
        type: "number",
        flex: 1,
      },
      {
        field: "total",
        headerName: intl.formatMessage({ id: "total" }),
        type: "number",
        flex: 1,
        valueFormatter: ({ value = 0 }) =>
          intl.formatNumber(value, {
            style: "currency",
            currency: "CAD",
          }),
      },
      {
        field: "status",
        headerName: intl.formatMessage({ id: "status" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value ? statusChip(value, intl.formatMessage({ id: value })) : "",
      },
      {
        field: "paymentStatus",
        headerName: intl.formatMessage({ id: "payment" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value
            ? paymentStatusChip(value, intl.formatMessage({ id: value }))
            : "",
      },
      {
        field: "fulfillmentStatus",
        headerName: intl.formatMessage({ id: "fulfillment" }),
        type: "string",
        flex: 1,
        renderCell: ({ value }) =>
          value
            ? fulfillmentStatusChip(value, intl.formatMessage({ id: value }))
            : "",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Page title={intl.formatMessage({ id: "orders" })}>
      <Container maxWidth="xl">
        <Card>
          <CardContent>
            <Toolbar sx={{ p: 3 }}>
              <Stack direction="row">
                <FormGroup
                  component="fieldset"
                  row
                  sx={{ alignItems: "center" }}
                >
                  <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel id="demo-simple-select-label">
                      {intl.formatMessage({ id: "order_status" })}
                    </InputLabel>
                    <Select
                      autoWidth
                      name="status"
                      label={intl.formatMessage({ id: "order_status" })}
                      labelId="statusLabel"
                      id="status"
                      value={selectedstatus}
                      onChange={(e) => setSelectedstatus(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>{intl.formatMessage({ id: "clear_choice" })}</em>
                      </MenuItem>
                      {statuses.map((status, i) => (
                        <MenuItem key={i} value={status}>
                          {intl.formatMessage({ id: status })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel id="demo-simple-select-label">
                      {intl.formatMessage({ id: "payment_status" })}
                    </InputLabel>
                    <Select
                      autoWidth
                      name="paymentStatus"
                      label={intl.formatMessage({ id: "payment_status" })}
                      labelId="paymentStatusLabel"
                      id="paymentStatusLabel"
                      value={selectedPaymentStatus}
                      onChange={(e) => setSelectedPaymentStatus(e.target.value)}
                    >
                      <MenuItem value="">
                        <em>{intl.formatMessage({ id: "clear_choice" })}</em>
                      </MenuItem>
                      {paymentStatuses.map((status, i) => (
                        <MenuItem key={i} value={status}>
                          {intl.formatMessage({ id: status })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: 140 }}>
                    <InputLabel id="fulfillmentStatusLabel">
                      {intl.formatMessage({ id: "fulfillment_status" })}
                    </InputLabel>
                    <Select
                      autoWidth
                      name="fulfillmentStatus"
                      label={intl.formatMessage({ id: "fulfillment_status" })}
                      labelId="fulfillmentStatusLabel"
                      id="fulfillmentStatus"
                      value={selectedFulfillmentStasus}
                      onChange={(e) =>
                        setSelectedFulfillmentStasus(e.target.value)
                      }
                    >
                      <MenuItem value="">
                        <em>{intl.formatMessage({ id: "clear_choice" })}</em>
                      </MenuItem>
                      {fulfillmentStatuses.map((status, i) => (
                        <MenuItem key={i} value={status}>
                          {intl.formatMessage({ id: status })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {selectedRows.length > 0 &&
                    (selectedFulfillmentStasus !== "" ||
                      selectedstatus !== "" ||
                      selectedPaymentStatus !== "") && (
                      <IconButton
                        edge="end"
                        color="inherit"
                        aria-label="save"
                        onClick={() => handleUpdateOrders()}
                        sx={{ m: 1 }}
                      >
                        <SaveIcon />
                      </IconButton>
                    )}
                  <Tooltip title={intl.formatMessage({ id: `print` })}>
                    <span sx={{ marginTop: "auto", marginBottom: "auto" }}>
                      <IconButton
                        disabled={selectedRows.length < 1}
                        aria-label="print"
                        onClick={() => handleCreatePickList()}
                        color="secondary"
                      >
                        <PrintIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={intl.formatMessage({ id: `delete` })}>
                    <span sx={{ marginTop: "auto", marginBottom: "auto" }}>
                      <IconButton
                        disabled={selectedRows.length < 1}
                        aria-label="print"
                        onClick={() => handleDelete()}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </FormGroup>
              </Stack>
            </Toolbar>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab
                  label={intl.formatMessage({ id: "all" })}
                  {...a11yProps(0)}
                />
                <Tab label={intl.formatMessage({ id: "archived" })} />
                <Tab label={intl.formatMessage({ id: "completed" })} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {isMobile ? (
                <MobileView
                  orders={rows.filter((row) => row.status !== "archived")}
                />
              ) : (
                <DataGrid
                  name="orders"
                  autoHeight
                  components={{ Toolbar: QuickSearchToolbar }}
                  rows={rows.filter((row) => row.status !== "archived")}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={handleOnEditRowsModelChange}
                  slotProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {isMobile ? (
                <MobileView orders={archives} />
              ) : (
                <DataGrid
                  name="orders"
                  autoHeight
                  components={{ Toolbar: QuickSearchToolbar }}
                  rows={archives}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={handleOnEditRowsModelChange}
                  slotProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {isMobile ? (
                <MobileView
                  orders={rows.filter((row) => row.status === "completed")}
                />
              ) : (
                <DataGrid
                  name="orders"
                  autoHeight
                  components={{ Toolbar: QuickSearchToolbar }}
                  rows={rows.filter((row) => row.status === "completed")}
                  columns={columns}
                  checkboxSelection
                  onRowSelectionModelChange={handleOnEditRowsModelChange}
                  slotProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => requestSearch(event.target.value),
                      clearSearch: () => requestSearch(""),
                    },
                  }}
                />
              )}
            </TabPanel>
          </CardContent>
        </Card>
      </Container>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={dialogOpen}
      >
        <DialogTitle>
          {intl.formatMessage({ id: "update" })}{" "}
          {intl.formatMessage({ id: "orders" })}
        </DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "update_orders_content" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "are_you_sure" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOkDelete}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={pickListDialogOpen}>
        <DialogTitle>{intl.formatMessage({ id: "pick_list" })}</DialogTitle>
        <DialogContent>
          <div ref={componentRef} style={{ padding: "10px" }}>
            <h2>
              {intl.formatMessage({ id: "summary" })}{" "}
              {new Date().toLocaleDateString()}
            </h2>
            {Object.entries(pickListCategories).map(
              ([categoryName, products], i) => (
                <table
                  key={i}
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    borderCollapse: "collapse",
                  }}
                >
                  <caption>{intl.formatMessage({ id: categoryName })}</caption>
                  <tbody>
                    <tr>
                      <th style={{ width: "10px" }}></th>
                      <th style={{ padding: "5px", textAlign: "left" }}>
                        {intl.formatMessage({ id: "product" })}
                      </th>
                      <th style={{ padding: "5px", textAlign: "right" }}>
                        {intl.formatMessage({ id: "qty" })}
                      </th>
                    </tr>
                    {Object.entries(products).map(([name, { quantity }], j) => (
                      <tr key={j}>
                        <td style={{ width: "10px" }}>
                          <input type="checkbox" />
                        </td>
                        <td style={{ padding: "5px", textAlign: "left" }}>
                          {name}
                        </td>
                        <td style={{ padding: "5px", textAlign: "right" }}>
                          {quantity}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            )}
            <ul>
              {Object.entries(pickListOrders).map(
                ([customerName, orders], i) => (
                  <li key={i}>
                    <h2>{customerName}</h2>
                    {Object.entries(orders).map(([orderId, products], k) => (
                      <table
                        key={k}
                        style={{
                          width: "100%",
                          border: "1px solid black",
                          borderCollapse: "collapse",
                        }}
                      >
                        <caption>{orderId}</caption>
                        <tbody>
                          <tr>
                            <th style={{ width: "10px" }}></th>
                            <th style={{ padding: "5px", textAlign: "left" }}>
                              {intl.formatMessage({ id: "product" })}
                            </th>
                            <th style={{ padding: "5px", textAlign: "right" }}>
                              {intl.formatMessage({ id: "quantity" })}
                            </th>
                          </tr>
                          {Object.entries(products).map(
                            ([name, { quantity }], j) => (
                              <tr key={j}>
                                <td style={{ width: "10px" }}>
                                  <input type="checkbox" />
                                </td>
                                <td
                                  style={{ padding: "5px", textAlign: "left" }}
                                >
                                  {name}
                                </td>
                                <td
                                  style={{ padding: "5px", textAlign: "right" }}
                                >
                                  {quantity}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ))}
                  </li>
                )
              )}
            </ul>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() =>
              _dispatch({
                type: "setState",
                payload: { pickListDialogOpen: false },
              })
            }
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handlePrint}>
            {intl.formatMessage({ id: "print" })}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}
