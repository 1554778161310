import { PURGE } from "redux-persist";
import {
  createAction,
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
  // upsertOneAgreement,
} from "@reduxjs/toolkit";
import { updateAgreement, logout, upsertOneAgreement } from "./index";
import * as api from "../apis/firebase";

export const setCurrentPosition = createAction("dialogs/setCurrentPosition");

const dialogsAdapter = createEntityAdapter();

export const deleteDialog = createAsyncThunk("dialogs/delete", async (id) => {
  const response = await api.removeDoc("dialogs", id);
  return response;
});

export const updateDialog = createAsyncThunk(
  "dialogs/update",
  async ({ id, payload }) => {
    const response = await api.updateDoc("dialogs", id, payload);
    return response;
  }
);

export const dialogs = createSlice({
  name: "dialogs",
  initialState: dialogsAdapter.getInitialState(),
  reducers: {
    upsertOneDialog: dialogsAdapter.upsertOne,
    removeOneDialog: dialogsAdapter.removeOne,
    removeAllDialog: dialogsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
          dialogsAdapter.removeAll(state);
      })
      .addCase(updateAgreement, (state, action) => {
        console.log({ state, action });
      })
      .addCase(logout.fulfilled, (state) => {
        dialogsAdapter.removeAll(state);
      })
      .addCase(upsertOneAgreement, (state, { payload }) => {
        const {
          isAccepted = false,
          isRequired = false,
          isActive = false,
          exclusions = [],
          userId,
          viewCount = 0,
          viewMin = 0,
        } = payload;
        if (isActive && userId && !exclusions.includes(userId)) {
          dialogsAdapter.upsertOne(state, {
            body: "",
            isAccepted: false,
            title: "",
            viewCount,
            viewMin,
            viewPages: [],
            open: (viewCount < viewMin) || (isRequired && !isAccepted),
            acceptTextId: "accept",
            declineTextId: "decline",
            ...payload,
          });
        }
      });
  },
});

export const { upsertOneDialog, removeOneDialog, removeAllDialog } =
  dialogs.actions;

export const dialogsSelectors = dialogsAdapter.getSelectors(
  (state) => state.dialogs
);

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  dialogsSelectors;

export const selectDialogs = (state) => state.dialogs;

export const selectPosition = (state) => state.dialogs.position;

export default dialogs.reducer;
