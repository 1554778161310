/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useCallback, useEffect, useState, useReducer, useMemo } from "react";
import { useFormik, Form, FormikProvider, Field } from "formik";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  // Tooltip,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import { styled } from "@mui/material/styles";
import {
  GridEditInputCell,
  DataGrid,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AvatarImageField from "../../components/AvatarImageField";
import DataGridImageEdit from "../../components/DataGridImageEdit";
import MembersTable from "./MembersTable";
import {
  addOneMembershipLength,
  addOneMembershipTier,
  batchMemberships,
  deleteMemberships,
  resetMembership,
  removeManyMembershipPlan,
  removeOneMembershipLength,
  removeOneMembershipTier,
  salesChannelsSelectors,
  selectCategories,
  selectMembershipPlansByParentId,
  selectProductsOrVariantsByVendorIdByUserSessionId,
  selectStatuses,
  selectTimeout,
  upsertMembershipAndPlans,
  upsertOneMembershipPlan,
  upsertOneMembershipLength,
  upsertOneMembershipTier,
  updateOneProduct,
} from "../../store";

const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  display: "grid",
  gridTemplateColumns: "1fr",
  gridGap: theme.spacing(1),
  gridTemplateRows: "auto",
  gridTemplateAreas: `"main"
  "footer"
  "sidebar"`,
  [theme.breakpoints.up("sm")]: {
    gridGap: theme.spacing(2),
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateAreas: `"main main sidebar"
    "main main sidebar"
    "footer footer footer"`,
  },
  [theme.breakpoints.up("md")]: {
    gridGap: theme.spacing(3),
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateAreas: `"main main main sidebar"
    "main main main sidebar"
    "footer footer footer footer"`,
  },
}));

const CurrencyInput = ({ field, form, meta, ...props }) => {
  const intl = useIntl();
  const { currency, label } = props;
  const { name, value = 0 } = field;
  const { touched, errors } = form;
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <TextField
          {...field}
          {...props}
          InputProps={{ inputProps: { min: 0 } }}
          type="number"
          fullWidth
          label={intl.formatMessage({ id: label })}
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
          onBlur={() => setShow(false)}
          value={value}
        />
      )}
      {!show && (
        <TextField
          fullWidth
          label={intl.formatMessage({ id: label })}
          onFocus={() => setShow(true)}
          value={intl.formatNumber(value, {
            style: "currency",
            currency: currency || "CAD",
          })}
        />
      )}
    </>
  );
};

const EMPTY_ARR = [];

const initialState = {
  tierHeadings: EMPTY_ARR,
  profit: 0.0,
  margin: 0,
  src: undefined,
  imageId: undefined,
  image: undefined,
  isPlansTouched: false,
  deleteDialogOpen: false,
  removeMembershipTierDialogOpen: false,
  removeMembershipLengthDialogOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "srcChanged":
      return {
        ...state,
        src: action.payload,
      };
    case "childTiersChanged":
      const { childTiers = {} } = action.payload;
      const tierHeadings = Object.values(childTiers).map(({ key, tierId }) => ({
        field: tierId,
        headerName: key,
        type: "string",
        width: 180,
        renderCell: ({ row }) => {
          return (
            <div onClick={(e) => e.preventDefault()}>
              {row?.tiers?.entities[tierId]?.value || ""}
            </div>
          );
        },
      }));
      return {
        ...state,
        tierHeadings,
      };
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

export default function MembershipForm({ membership }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const availableCategories = useSelector(selectCategories);
  const availableSalesChannels = useSelector(salesChannelsSelectors.selectAll);
  const statuses = useSelector(selectStatuses);
  const formTimeout = useSelector(selectTimeout);

  const handleDatePickerAvailableFromOnChange = (value) => {
    setFieldValue("availableFrom", value);
  };

  const handleDatePickerAvailableToOnChange = (value) => {
    setFieldValue("availableTo", value);
  };

  const plans = useSelector((state) =>
    selectMembershipPlansByParentId(state, membership.id)
  );

  const numberOfPlans = plans?.length || 1;

  const [
    { deleteDialogOpen, removeMembershipLengthDialogOpen, removeMembershipTierDialogOpen },
    _dispatch,
  ] = useReducer(reducer, initialState);

  const {
    available = 0,
    availableFrom = Date.now(),
    availableTo = Date.now() + 31536000000,
    categories = EMPTY_ARR,
    childLengths = {},
    childTiers = {},
    continueSellingWhenOutOfStock = false,
    compareAtPrice = 0,
    description = "",
    displayAvailableInventoryToCustomers = false,
    hasPlans = false,
    id = "",
    isTaxed = false,
    length = {},
    // if membership.maxPerOrder is null, then set it to empty string
    maxPerOrder = "",
    name = "",
    parentId = "",
    parentName = "",
    price = 0,
    membershipPhoto,
    membershipPhotoId = "",
    salesChannels = EMPTY_ARR,
    status = "draft",
    tags = EMPTY_ARR,
    tier = {},
    trackQuantity = false,
    vendorId,
    ...rest
  } = membership;

  const products = useSelector(
    selectProductsOrVariantsByVendorIdByUserSessionId
  );

  const MembershipSchema = Yup.object().shape({
    parentName: Yup.string().required(intl.formatMessage({ id: "required" })),
    membershipPhoto: Yup.string().required(
      intl.formatMessage({ id: "required" })
    ),
    price: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  useEffect(() => {
    if (maxPerOrder === null) {
      setFieldValue("maxPerOrder", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxPerOrder]);

  const formik = useFormik({
    initialValues: {
      available,
      availableFrom,
      availableTo,
      categories,
      childLengths,
      childTiers,
      compareAtPrice,
      continueSellingWhenOutOfStock,
      description,
      displayAvailableInventoryToCustomers,
      hasPlans,
      id,
      isTaxed,
      length,
      maxPerOrder: maxPerOrder || "",
      membershipPhoto,
      membershipPhotoId,
      membershipName: name,
      parentId,
      parentName,
      price,
      salesChannels,
      status,
      tags,
      tier,
      trackQuantity,
      ...rest,
    },
    validationSchema: MembershipSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    dirty,
    setFieldValue,
    getFieldProps,
  } = formik;

  useEffect(() => {
    _dispatch({ type: "srcChanged", payload: membershipPhoto });
    setFieldValue("membershipPhoto", membershipPhoto);
  }, [membershipPhoto]);

  useEffect(() => {
    const { plans, childLengths, childTiers, membershipName, ...rest } = values;
    const timeoutId = setTimeout(() => {
      dispatch(
        upsertMembershipAndPlans({
          id: membership.id,
          name: membershipName,
          ...rest,
        })
      );
    }, formTimeout);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(batchMemberships({ parentId: id, products }));
  };

  const resetForm = () => {
    formik.resetForm();
    dispatch(resetMembership(id));
    _dispatch({ type: "stateChanged", payload: { isPlansTouched: false } });
  };

  const handleCancelDialog = () =>
    _dispatch({
      type: "stateChanged",
      payload: {
        deleteDialogOpen: false,
        removeMembershipTierDialogOpen: false,
        removeMembershipLengthDialogOpen: false,
      },
    });

  const handleOk = async () => {
    const deleteIds = [id, ...plans.map((plan) => plan.id)];
    await dispatch(deleteMemberships(deleteIds));
    formik.resetForm();
    dispatch(removeManyMembershipPlan(deleteIds));
    await _dispatch({
      type: "stateChanged",
      payload: {
        isPlansTouched: false,
        deleteDialogOpen: false,
        removeMembershipTierDialogOpen: false,
      },
    });
    navigate(`/vendors/${vendorId}/memberships`);
  };

  // const handleRemoveLengthDialog = () => {
  //   _dispatch({ type: "stateChanged", payload: { removeMembershipLengthDialogOpen: true } });
  // };

  // const handleRemoveTierDialog = () => {
  //   _dispatch({ type: "stateChanged", payload: { removeMembershipTierDialogOpen: true } });
  // };

  const handleDelete = () => {
    _dispatch({ type: "stateChanged", payload: { deleteDialogOpen: true } });
  };

  const handleCheckboxSalesChannels = (v) => {
    if (values.salesChannels.indexOf(v) === -1) {
      setFieldValue("salesChannels", [...values.salesChannels, v]);
    } else {
      setFieldValue(
        "salesChannels",
        values.salesChannels.filter((sc) => sc !== v)
      );
      setFieldValue("availableFrom", null);
      setFieldValue("availableTo", null);
    }
  };

  const handleRemoveTier = (tierId) => {
    dispatch(
      removeOneMembershipTier({ planId: id, parentId: membership.id, tierId })
    );
  };
  const handleRemoveLength = (lengthId) => {
    dispatch(
      removeOneMembershipLength({
        planId: id,
        parentId: membership.id,
        lengthId,
      })
    );
  };
  const handleOnInputChangeLength = (payload) => {
    dispatch(
      upsertOneMembershipLength({
        ...payload,
        parentId: membership.id,
        planId: id,
      })
    );
  };

  const handleOnInputChangeTier = (payload) => {
    dispatch(
      upsertOneMembershipTier({
        ...payload,
        parentId: membership.id,
        planId: id,
      })
    );
  };

  const handleAddTier = () => {
    dispatch(addOneMembershipTier({ planId: id }));
  };

  const handleAddLength = () => {
    dispatch(addOneMembershipLength({ planId: id }));
  };

  const processRowUpdate = useCallback(
    async (newRow) => {
      const { membershipPhoto, ...rest } = newRow;
      const response = await dispatch(upsertOneMembershipPlan(rest));
      return response.payload; // return the new row with the row id in it response.payload is { id, ...rest }
    },
    [dispatch]
  );

  const handleProcessRowUpdateError = useCallback((error) => {
    console.log({ error, children: error.message, severity: "error" });
  }, []);

  function CheckboxEditInputCell(props) {
    const { id, value, field } = props;

    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      await apiRef.current.updateRows([
        { id, [field]: Boolean(event.target.checked) },
      ]);
      await processRowUpdate({ id, [field]: Boolean(event.target.checked) });
    };
    return (
      <Checkbox checked={Boolean(value)} onClick={(e) => handleChange(e)} />
    );
  }

  const renderCheckboxEditInputCell = (params) => {
    return <CheckboxEditInputCell {...params} />;
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: intl.formatMessage({ id: "name" }),
        type: "string",
        editable: true,
        width: 150,
      },
      {
        field: "membershipPhoto",
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        editable: true,
        renderCell: ({ id: planId, value }) => {
          return (
            <DataGridImageEdit
              name="membershipPhoto"
              disabled={!true}
              uid={planId}
              change={({ downloadURL, fileName }) => {
                dispatch(
                  upsertOneMembershipPlan({
                    id: planId,
                    membershipPhoto: downloadURL,
                    membershipPhotoId: fileName,
                  })
                );
              }}
              initialized={true}
              intl={intl}
              path={"memberships"}
              src={value ? value : membershipPhoto}
              required
            />
          );
        },
        width: 70,
      },
      {
        field: "price",
        headerName: intl.formatMessage({ id: "price" }),
        type: "number",
        editable: true,
        valueFormatter: ({ value = 0 }) =>
          intl.formatNumber(value, {
            style: "currency",
            currency: "CAD",
          }),
        renderEditCell: (params) => (
          <GridEditInputCell
            {...params}
            inputProps={{
              min: 0,
            }}
          />
        ),
        width: 150,
      },
      {
        field: "available",
        headerName: intl.formatMessage({ id: "available" }),
        type: "number",
        editable: true,
        width: 80,
      },
      {
        field: "tierName",
        headerName: "Tier",
        type: "string",
        width: 80,
      },
      {
        field: "lengthName",
        headerName: "Length",
        type: "string",
        width: 80,
      },
      {
        field: "begins",
        headerName: intl.formatMessage({ id: "begins" }),
        type: "string",
        valueGetter: (params) =>
          params?.row?.begins === "customBegins"
            ? params.row.customBegins
            : intl.formatMessage({ id: "anytime" }),
      },
      {
        field: "expires",
        headerName: intl.formatMessage({ id: "expires" }),
        type: "string",
        valueGetter: (params) => {
          if (params?.row?.expires === "no_expiry") {
            return intl.formatMessage({ id: "no_expiry" });
          }
          return params?.row?.expires === "days"
            ? parseInt(params.row.days) > 0
              ? `${params.row.days} ${intl.formatMessage({
                  id: "days",
                })}`
              : intl.formatMessage({ id: "no_expiry" })
            : params?.row?.customExpires;
        },
      },
      {
        field: "isRenewable",
        headerName: intl.formatMessage({ id: "is_renewable" }),
        type: "boolean",
        editable: true,
        renderCell: renderCheckboxEditInputCell,
      },
      {
        field: "actions",
        type: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        cellClassName: "actions",
        flex: 1,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Delete"
              onClick={() =>
                navigate(
                  `/vendors/${vendorId}/memberships/${membership.id}/plans/${id}`
                )
              }
            />,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans]
  );

  const processProductPlanRowUpdate = useCallback(
    async (newRow) => {
      const response = await dispatch(updateOneProduct(newRow));
      return response.payload; // return the new row with the row id in it response.payload is { id, ...rest }
    },
    [dispatch]
  );

  const handleCheckboxChange = (event, params) => {
    const { plans = [], ...rest } = params.row;
    if (event.target.checked) {
      processProductPlanRowUpdate({
        ...rest,
        plans: [...plans, params.colDef.field],
      });
      dispatch(
        updateOneProduct({
          ...params.row,
          plans: [...plans, params.colDef.field],
        })
      );
    } else {
      const newPlans = plans.filter((plan) => plan !== params.colDef.field);
      processProductPlanRowUpdate({ ...rest, plans: newPlans });
      dispatch(
        updateOneProduct({
          ...rest,
          plans: newPlans,
        })
      );
    }
  };

  const renderProductPlansCheckboxEditInputCell = (params) => {
    const planId = params.colDef.field;
    const { plans = [] } = params.row;
    const isChecked = Boolean(plans.includes(planId));
    return (
      <Checkbox
        checked={isChecked}
        onClick={(e) => handleCheckboxChange(e, params)}
      />
    );
  };

  function getFullName(params) {
    return `${params.row.parentName || ""} ${params.row.name || ""}`;
  }

  const planColumns = useMemo(
    () => [
      {
        headerName: intl.formatMessage({ id: "name" }),
        field: "fullName",
        flex: 1,
        type: "string",
        renderCell: (params) => (
          <Link href={`/vendors/${vendorId}/products/${params.row.id}/edit`}>
            {getFullName(params)}
          </Link>
        ),
        valueGetter: getFullName,
      },
      ...plans.map(({ id: planId, name: planName }) => {
        return {
          editable: true,
          field: `${planId}`,
          flex: 1,
          headerName: planName,
          renderCell: renderProductPlansCheckboxEditInputCell,
        };
      }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [plans]
  );

  return (
    <Container maxWidth="md">
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit}>
          <RootStyle>
            <Box sx={{ gridArea: "main" }}>
              <Grid container>
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              autoComplete="off"
                              type="text"
                              fullWidth
                              label={intl.formatMessage({ id: "name" })}
                              {...getFieldProps("membershipName")}
                              onChange={formik.handleChange}
                              value={values.membershipName}
                              error={Boolean(touched.name && errors.name)}
                              helperText={touched.name && errors.name}
                              placeholder={intl.formatMessage({
                                id: "placeholder_membership_name",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type={"text"}
                              fullWidth
                              label={intl.formatMessage({ id: "description" })}
                              {...getFieldProps("description")}
                              onChange={formik.handleChange}
                              value={values.description}
                              error={Boolean(
                                touched.description && errors.description
                              )}
                              helperText={
                                touched.description && errors.description
                              }
                              multiline
                              rows={3}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title={intl.formatMessage({ id: "media" })} />
                      <CardContent>
                        {id && (
                          <AvatarImageField
                            name="membershipPhoto"
                            disabled={!true}
                            uid={id}
                            change={({ downloadURL, fileName }) => {
                              setFieldValue("membershipPhoto", downloadURL);
                              setFieldValue("membershipPhotoId", fileName);
                            }}
                            initialized={true}
                            intl={intl}
                            path={"memberships"}
                            src={values.membershipPhoto}
                            required
                          />
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "pricing" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          {numberOfPlans < 2 ? (
                            <Grid item xs={8}>
                              <Field
                                name="price"
                                placeholder="0.00"
                                label="price"
                                component={CurrencyInput}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              {intl.formatMessage({
                                id: "plans_pricing_text",
                              })}
                            </Grid>
                          )}
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("isTaxed")}
                                  checked={values.isTaxed || false}
                                  onChange={formik.handleChange}
                                  name="isTaxed"
                                  value={isTaxed}
                                />
                              }
                              label={intl.formatMessage({ id: "taxed_label" })}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "inventory" })}
                      />
                      <CardContent>
                        <Grid item container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps("trackQuantity")}
                                  checked={values.trackQuantity || false}
                                  onChange={formik.handleChange}
                                  name="trackQuantity"
                                  value={values.trackQuantity}
                                />
                              }
                              label={intl.formatMessage({
                                id: "track_quantity_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps(
                                    "continueSellingWhenOutOfStock"
                                  )}
                                  checked={
                                    values.continueSellingWhenOutOfStock ||
                                    false
                                  }
                                  onChange={formik.handleChange}
                                  name="continueSellingWhenOutOfStock"
                                  value={values.continueSellingWhenOutOfStock}
                                />
                              }
                              label={intl.formatMessage({
                                id: "continue_selling_when_out_of_stock_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...getFieldProps(
                                    "displayAvailableInventoryToCustomers"
                                  )}
                                  checked={
                                    values.displayAvailableInventoryToCustomers ||
                                    false
                                  }
                                  onChange={formik.handleChange}
                                  name="displayAvailableInventoryToCustomers"
                                  value={
                                    values.displayAvailableInventoryToCustomers
                                  }
                                />
                              }
                              label={intl.formatMessage({
                                id: "availability_visible_label",
                              })}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              name="maxPerOrder"
                              plan="outlined"
                              type="number"
                              inputProps={{
                                min: 0,
                              }}
                              label={intl.formatMessage({
                                id: "max_per_order_label",
                              })}
                              helperText={intl.formatMessage({
                                id: "max_per_order_helper_text",
                              })}
                              value={values.maxPerOrder}
                              onChange={formik.handleChange}
                              size="small"
                              {...getFieldProps("maxPerOrder")}
                            />
                          </Grid>
                          {numberOfPlans < 2 ? (
                            <Grid item xs={12}>
                              <TextField
                                name="available"
                                plan="outlined"
                                type="number"
                                inputProps={{
                                  min: 0,
                                }}
                                label={intl.formatMessage({
                                  id: "available_label",
                                })}
                                value={values.available}
                                onChange={formik.handleChange}
                                size="small"
                                {...getFieldProps("available")}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              {intl.formatMessage({
                                id: "plans_availability_text",
                              })}
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "sidebar" }}>
              <Grid container direction="column" justifyContent="flex-start">
                <Grid item container spacing={{ xs: 2, md: 3 }}>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "item_status" })}
                      />
                      <CardContent>
                        <FormControl sx={{ mb: 2 }}>
                          <Select
                            name="status"
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={values.status || "draft"}
                            onChange={formik.handleChange}
                          >
                            {statuses.map((status, i) => (
                              <MenuItem key={i} value={status}>
                                {intl.formatMessage({ id: status })}
                              </MenuItem>
                            ))}
                          </Select>
                          {status === "draft" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "item_status_hint",
                              })}
                            </FormHelperText>
                          )}
                          {status === "active" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "item_status_active_hint",
                              })}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <Typography variant="subtitle" gutterBottom>
                          {intl.formatMessage({ id: "sales_channels" })}
                        </Typography>
                        {availableSalesChannels.map((salesChannel, i) => {
                          switch (salesChannel.id) {
                            case "memberships":
                              break;
                            case "online_shop":
                              return (
                                <Grid item xs={12} key={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values?.salesChannels?.indexOf(
                                            salesChannel.id
                                          ) !== -1
                                        }
                                        onChange={(e) =>
                                          handleCheckboxSalesChannels(
                                            salesChannel.id
                                          )
                                        }
                                        name={intl.formatMessage({
                                          id: salesChannel.salesChannelsTitleId,
                                        })}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: salesChannel.salesChannelsTitleId,
                                    })}
                                  />
                                  {values?.salesChannels?.indexOf(
                                    salesChannel.id
                                  ) !== -1 && (
                                    <Stack gap={1}>
                                      <Typography>
                                        {intl.formatMessage({
                                          id: "schedule_availability",
                                        })}
                                      </Typography>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "from",
                                          })}`}
                                          value={values.availableFrom}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableFromOnChange(
                                              Math.floor(
                                                Number(newValue.getTime())
                                              )
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                          sx={{ mb: 2 }}
                                        />
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "to",
                                          })}`}
                                          value={values.availableTo}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableToOnChange(
                                              Math.floor(
                                                Number(newValue.getTime())
                                              )
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Stack>
                                  )}
                                </Grid>
                              );
                            default:
                              return (
                                <Grid item xs={12} key={i}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          values?.salesChannels?.indexOf(
                                            salesChannel.id
                                          ) !== -1
                                        }
                                        onChange={(e) =>
                                          handleCheckboxSalesChannels(
                                            salesChannel.id
                                          )
                                        }
                                        name={intl.formatMessage({
                                          id: salesChannel.salesChannelsTitleId,
                                        })}
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: salesChannel.salesChannelsTitleId,
                                    })}
                                  />
                                  {values?.salesChannels?.indexOf(
                                    salesChannel.id
                                  ) !== -1 && (
                                    <Stack gap={1}>
                                      <Typography>
                                        {intl.formatMessage({
                                          id: "schedule_availability",
                                        })}
                                      </Typography>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                      >
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "from",
                                          })}`}
                                          value={values.availableFrom}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableFromOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                        <DateTimePicker
                                          disablePast
                                          renderInput={(props) => (
                                            <TextField {...props} />
                                          )}
                                          inputFormat="yyyy/MM/dd hh:mm a"
                                          label={`${intl.formatMessage({
                                            id: "available",
                                          })} ${intl.formatMessage({
                                            id: "to",
                                          })}`}
                                          value={values.availableTo}
                                          onChange={(newValue) => {
                                            handleDatePickerAvailableToOnChange(
                                              newValue
                                            );
                                          }}
                                          components={{
                                            OpenPickerIcon: AccessTimeIcon,
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Stack>
                                  )}
                                </Grid>
                              );
                          }
                          return "";
                        })}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card>
                      <CardHeader
                        title={intl.formatMessage({ id: "organization" })}
                      />
                      <CardContent>
                        <Grid container spacing={{ xs: 2, md: 3 }}>
                          <Grid item xs={12}>
                            {values.categories && availableCategories && (
                              <Autocomplete
                                multiple
                                id={"categories"}
                                options={
                                  availableCategories
                                    ? availableCategories.filter(
                                        (category) => category !== "all"
                                      )
                                    : EMPTY_ARR
                                }
                                getOptionLabel={(tier) =>
                                  intl.formatMessage({ id: tier })
                                }
                                onChange={(e, value) =>
                                  setFieldValue("categories", value)
                                }
                                value={
                                  ["memberships", ...values.categories] ||
                                  EMPTY_ARR
                                }
                                renderTags={(value, getTagProps) =>
                                  value.map((tier, index) => (
                                    <Chip
                                      plan="outlined"
                                      label={intl.formatMessage({ id: tier })}
                                      {...getTagProps({ index })}
                                    />
                                  ))
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    plan="outlined"
                                    label={intl.formatMessage({
                                      id: `categories`,
                                    })}
                                    placeholder={intl.formatMessage({
                                      id: "add_category_placeholder",
                                    })}
                                  />
                                )}
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              id={"tags"}
                              onChange={(e, value) =>
                                setFieldValue("tags", value)
                              }
                              options={EMPTY_ARR}
                              freeSolo
                              value={values.tags || EMPTY_ARR}
                              renderTags={(value, getTagProps) =>
                                value.map((tier, index) => (
                                  <Chip
                                    plan="outlined"
                                    label={tier}
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  plan="outlined"
                                  label={intl.formatMessage({ id: `tags` })}
                                  placeholder={intl.formatMessage({
                                    id: "add_tag_placeholder",
                                  })}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  {(dirty || plans.length > 0) && (
                    <Grid item xs={12}>
                      <Card>
                        <CardContent>
                          <Grid
                            item
                            container
                            spacing={2}
                            justifyContent="center"
                          >
                            <Grid item>
                              <LoadingButton
                                type="submit"
                                plan="contained"
                                loading={isSubmitting}
                                disabled={formik.isSubmitting}
                                startIcon={<SaveIcon />}
                              >
                                {intl.formatMessage({ id: "save" })}
                              </LoadingButton>
                            </Grid>
                            <Grid item>
                              <LoadingButton
                                plan="contained"
                                onClick={resetForm}
                                startIcon={<RefreshIcon />}
                              >
                                {intl.formatMessage({ id: "reset" })}
                              </LoadingButton>
                            </Grid>
                            <Grid item>
                              <Button
                                plan="contained"
                                color="error"
                                onClick={handleDelete}
                                startIcon={<DeleteIcon />}
                              >
                                {intl.formatMessage({ id: "delete" })}
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ gridArea: "footer" }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "plans" })}
                      subheader={intl.formatMessage({ id: "plans_subheader" })}
                    />
                    <CardContent>
                      <>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Card>
                              <CardHeader
                                title={intl.formatMessage({ id: "tiers" })}
                                subheader={intl.formatMessage({
                                  id: "tiers_subheader",
                                })}
                                action={
                                  childTiers &&
                                  Object.keys(childTiers).length < 3 ? (
                                    <Button
                                      plan="outlined"
                                      onClick={() => handleAddTier()}
                                      sx={{ m: 2 }}
                                    >
                                      {intl.formatMessage({
                                        id: "add_another_tier",
                                      })}
                                    </Button>
                                  ) : (
                                    <></>
                                  )
                                }
                              />
                              <CardContent>
                                <List>
                                  {Object.values(
                                    childTiers?.entities || {}
                                  ).map(
                                    (
                                      {
                                        tierId,
                                        name: tierName,
                                        description: tierDescription,
                                        discount,
                                      },
                                      index
                                    ) => (
                                      <ListItem
                                        key={index}
                                        divider
                                        sx={{ mb: 2 }}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            container
                                            spacing={1}
                                            xs={12}
                                            md={10}
                                          >
                                            <Grid item xs={12}>
                                              <TextField
                                                type="text"
                                                name="tierName"
                                                label={intl.formatMessage({
                                                  id: `name`,
                                                })}
                                                onChange={(e) =>
                                                  handleOnInputChangeTier({
                                                    tierId,
                                                    name: e.target.value,
                                                  })
                                                }
                                                onBlur={(e) =>
                                                  handleOnInputChangeTier({
                                                    tierId,
                                                    name: e.target.value,
                                                  })
                                                }
                                                value={tierName || ""}
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: `add_tier_placeholder_${index}`,
                                                  }
                                                )}
                                                sx={{ mb: 2 }}
                                              />
                                            </Grid>
                                            <Grid item xs={12}>
                                              <TextField
                                                type="text"
                                                name="description"
                                                label={intl.formatMessage({
                                                  id: `description`,
                                                })}
                                                multiline
                                                rows={2}
                                                onChange={(e) =>
                                                  handleOnInputChangeTier({
                                                    tierId,
                                                    description: e.target.value,
                                                  })
                                                }
                                                onBlur={(e) =>
                                                  handleOnInputChangeTier({
                                                    tierId,
                                                    description: e.target.value,
                                                  })
                                                }
                                                value={tierDescription || ""}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            md={2}
                                            alignItems="center"
                                          >
                                            {childTiers.ids.length > 1 && (
                                              <Button
                                                color="secondary"
                                                onClick={() =>
                                                  handleRemoveTier(tierId)
                                                }
                                              >
                                                {intl.formatMessage({
                                                  id: "remove",
                                                })}
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            <Card>
                              <CardHeader
                                title={intl.formatMessage({ id: "lengths" })}
                                subheader={intl.formatMessage({
                                  id: "lengths_subheader",
                                })}
                                action={
                                  Object.keys(childLengths).length < 3 ? (
                                    <Button
                                      plan="outlined"
                                      onClick={() => handleAddLength()}
                                      sx={{ m: 2 }}
                                    >
                                      {intl.formatMessage({
                                        id: "add_another_length",
                                      })}
                                    </Button>
                                  ) : (
                                    <></>
                                  )
                                }
                              />
                              <CardContent>
                                <List>
                                  {Object.values(
                                    childLengths?.entities || {}
                                  ).map(
                                    (
                                      {
                                        begins,
                                        days,
                                        expires,
                                        isRenewable,
                                        lengthId,
                                        name: lengthName,
                                        customBegins,
                                        customExpires,
                                        // selectedBegins,
                                        // selectedExpires,
                                      },
                                      index
                                    ) => (
                                      <ListItem
                                        key={index}
                                        divider
                                        sx={{ mb: 2 }}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid
                                            item
                                            container
                                            spacing={1}
                                            xs={12}
                                            md={10}
                                          >
                                            <Grid item xs={12}>
                                              <TextField
                                                type="text"
                                                name="lengthName"
                                                label={intl.formatMessage({
                                                  id: `name`,
                                                })}
                                                onChange={(e) =>
                                                  handleOnInputChangeLength({
                                                    lengthId,
                                                    name: e.target.value,
                                                  })
                                                }
                                                onBlur={(e) =>
                                                  handleOnInputChangeLength({
                                                    lengthId,
                                                    name: e.target.value,
                                                  })
                                                }
                                                value={lengthName || ""}
                                                placeholder={intl.formatMessage(
                                                  {
                                                    id: `add_length_placeholder_${index}`,
                                                  }
                                                )}
                                                sx={{ mb: 2 }}
                                              />
                                            </Grid>
                                            <Grid
                                              item
                                              xs={12}
                                              container
                                              spacing={3}
                                            >
                                              <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                              >
                                                <Grid item xs={6}>
                                                  <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                      {intl.formatMessage({
                                                        id: "begins_label",
                                                      })}
                                                    </FormLabel>
                                                    <RadioGroup
                                                      aria-label={intl.formatMessage(
                                                        { id: "begins_label" }
                                                      )}
                                                      name="begins"
                                                      value={begins}
                                                      onChange={(e, v) =>
                                                        handleOnInputChangeLength(
                                                          {
                                                            lengthId,
                                                            begins:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                    >
                                                      <FormControlLabel
                                                        name="anytime"
                                                        value="anytime"
                                                        control={<Radio />}
                                                        label={intl.formatMessage(
                                                          {
                                                            id: "anytime",
                                                          }
                                                        )}
                                                      />
                                                      <FormControlLabel
                                                        name="customBegins"
                                                        value="customBegins"
                                                        control={<Radio />}
                                                        label={intl.formatMessage(
                                                          {
                                                            id: "custom",
                                                          }
                                                        )}
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                  {begins !== "anytime" && (
                                                    <LocalizationProvider
                                                      dateAdapter={
                                                        AdapterDateFns
                                                      }
                                                    >
                                                      <DateTimePicker
                                                        name="customBegins"
                                                        format="yyyy/MM/dd"
                                                        label={intl.formatMessage(
                                                          {
                                                            id: "custom_begins",
                                                          }
                                                        )}
                                                        value={customBegins}
                                                        onChange={(value) =>
                                                          handleOnInputChangeLength(
                                                            {
                                                              lengthId,
                                                              customBegins:
                                                                value.toString(),
                                                            }
                                                          )
                                                        }
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                          />
                                                        )}
                                                      />
                                                    </LocalizationProvider>
                                                  )}
                                                </Grid>
                                                <Grid item xs={6} container>
                                                  <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                      <FormLabel component="legend">
                                                        {intl.formatMessage({
                                                          id: "expires_label",
                                                        })}
                                                      </FormLabel>
                                                      <RadioGroup
                                                        aria-label={intl.formatMessage(
                                                          {
                                                            id: "expires_label",
                                                          }
                                                        )}
                                                        name="expires"
                                                        value={expires}
                                                        onChange={(e, value) =>
                                                          handleOnInputChangeLength(
                                                            {
                                                              lengthId,
                                                              expires: value,
                                                            }
                                                          )
                                                        }
                                                      >
                                                        <FormControlLabel
                                                          value="no_expiry"
                                                          control={<Radio />}
                                                          label={intl.formatMessage(
                                                            {
                                                              id: "never",
                                                            }
                                                          )}
                                                        />
                                                        <FormControlLabel
                                                          value="days"
                                                          control={<Radio />}
                                                          label={
                                                            parseInt(days) < 2
                                                              ? intl.formatMessage(
                                                                  {
                                                                    id: "day",
                                                                  }
                                                                )
                                                              : intl.formatMessage(
                                                                  {
                                                                    id: "days",
                                                                  }
                                                                )
                                                          }
                                                        />
                                                        <FormControlLabel
                                                          value="customExpires"
                                                          control={<Radio />}
                                                          label={intl.formatMessage(
                                                            { id: "custom" }
                                                          )}
                                                        />
                                                      </RadioGroup>
                                                    </FormControl>
                                                  </Grid>
                                                  {expires !== "no_expiry" && (
                                                    <>
                                                      <Grid item xs={12}>
                                                        {expires === "days" && (
                                                          <TextField
                                                            id={`${lengthId}`}
                                                            name="days"
                                                            type="number"
                                                            label={intl.formatMessage(
                                                              {
                                                                id: "number_of_days",
                                                              }
                                                            )}
                                                            value={days || ""}
                                                            variant="outlined"
                                                            onChange={(e) =>
                                                              handleOnInputChangeLength(
                                                                {
                                                                  lengthId,
                                                                  days: e.target
                                                                    .value,
                                                                }
                                                              )
                                                            }
                                                            helperText={intl.formatMessage(
                                                              {
                                                                id: "membership_length_help_text",
                                                              }
                                                            )}
                                                          />
                                                        )}
                                                      </Grid>
                                                      <Grid item xs={12}>
                                                        {expires !== "days" && (
                                                          <LocalizationProvider
                                                            dateAdapter={
                                                              AdapterDateFns
                                                            }
                                                          >
                                                            <DateTimePicker
                                                              name="customExpires"
                                                              format="yyyy/MM/dd"
                                                              value={
                                                                customExpires
                                                              }
                                                              label={intl.formatMessage(
                                                                {
                                                                  id: "custom_expires",
                                                                }
                                                              )}
                                                              onChange={(
                                                                value
                                                              ) =>
                                                                handleOnInputChangeLength(
                                                                  {
                                                                    lengthId,
                                                                    customExpires:
                                                                      value.toString(),
                                                                  }
                                                                )
                                                              }
                                                              renderInput={(
                                                                params
                                                              ) => (
                                                                <TextField
                                                                  {...params}
                                                                />
                                                              )}
                                                            />
                                                          </LocalizationProvider>
                                                        )}
                                                      </Grid>
                                                    </>
                                                  )}
                                                </Grid>
                                              </LocalizationProvider>
                                            </Grid>
                                            <Grid item xs={12}>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      Boolean(isRenewable) ||
                                                      false
                                                    }
                                                    onChange={(e) =>
                                                      handleOnInputChangeLength(
                                                        {
                                                          lengthId,
                                                          isRenewable:
                                                            e.target.checked,
                                                        }
                                                      )
                                                    }
                                                    name="isRenewable"
                                                  />
                                                }
                                                label={intl.formatMessage({
                                                  id: "is_renewable",
                                                })}
                                                value={isRenewable}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} md={2}>
                                            {childLengths.ids.length > 1 && (
                                              <Button
                                                color="secondary"
                                                onClick={() =>
                                                  handleRemoveLength(lengthId)
                                                }
                                              >
                                                {intl.formatMessage({
                                                  id: "remove",
                                                })}
                                              </Button>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </CardContent>
                            </Card>
                          </Grid>
                          <Grid item xs={12}>
                            <Card>
                              <CardHeader
                                title={intl.formatMessage({ id: "plans" })}
                              />
                              <CardContent>
                                <DataGrid
                                  autoHeight
                                  name="plans"
                                  rows={plans}
                                  columns={columns}
                                  processRowUpdate={processRowUpdate}
                                  onProcessRowUpdateError={
                                    handleProcessRowUpdateError
                                  }
                                />
                              </CardContent>
                            </Card>
                          </Grid>
                        </Grid>
                      </>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "products" })}
                      subheader={intl.formatMessage({
                        id: "membership_products_subheader",
                      })}
                    />
                    <CardContent>
                      <DataGrid
                        autoHeight
                        name="products"
                        rows={products}
                        columns={planColumns}
                      />
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={intl.formatMessage({ id: "members" })}
                      subheader={intl.formatMessage({
                        id: "members_subheader",
                      })}
                    />
                    <CardContent>
                      <MembersTable
                        docName={"member"}
                        collectionName={"members"}
                        items={EMPTY_ARR}
                        membership={membership}
                        membershipstatus={status}
                        plans={
                          plans?.length > 0
                            ? plans
                            : [
                                {
                                  name: membership.name,
                                  planId: membership.id,
                                },
                              ]
                        }
                        statuses={statuses}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </RootStyle>
        </Form>
      </FormikProvider>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={deleteDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "are_you_sure" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={removeMembershipLengthDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "alter_membership_plans_warning" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={removeMembershipTierDialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "alter_membership_plans_warning" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
