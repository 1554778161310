import { lazy, useEffect, Suspense } from "react";
import { useFormik } from "formik";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import Page from "../components/Page";
import {
  getVendorsByLatLngRadiusInM,
  locationsSelectors,
  selectActiveMembershipsByParent,
  selectFilteredActiveProductsByParent,
  selectHasAddress,
  selectSortIndex,
  selectUserId,
  selectVendorByOwnerId,
  updateFilters,
  updateLocation,
  updateProduct,
  updateUser,
  usersSelectors,
} from "../store";
import { useIntl } from "react-intl";
import { collection, doc } from "firebase/firestore";
import { db } from "../apis/firebase";
import MapContainer from "../components/forms/ProfileForm/MapContainer";

// Lazy-load components
const ProductSort = lazy(() =>
  import("../components/_dashboard/products/ProductSort").then(
    (module) => ({ default: module.default }),
    () => ({ default: () => <div>Error loading ProductSort</div> })
  )
);

const ProductList = lazy(() =>
  import("../components/_dashboard/products/ProductList").then(
    (module) => ({ default: module.default }),
    () => ({ default: () => <div>Error loading ProductList</div> })
  )
);

const ProductCartWidget = lazy(() =>
  import("../components/_dashboard/products/ProductCartWidget").then(
    (module) => ({ default: module.default }),
    () => ({ default: () => <div>Error loading ProductCartWidget</div> })
  )
);

const NORTHPOLE = {
  longitude: 0.0,
  latitude: 90.0,
};

const ProductFilterSidebar = lazy(() =>
  import("../components/_dashboard/products/ProductFilterSidebar").then(
    (module) => ({ default: module.default }),
    () => ({ default: () => <div>Error loading ProductFilterSidebar</div> })
  )
);

const libraries = process.env.REACT_APP_GOOGLE_MAPS_LIBRARIES.split(",");

export default function EcommerceShop() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const memberships = useSelector(selectActiveMembershipsByParent);
  // const products = useSelector(selectActiveProductsByParent);
  const products = useSelector(selectFilteredActiveProductsByParent);
  const selectedIndex = useSelector(selectSortIndex);
  const intl = useIntl();
  const navigate = useNavigate();
  const userId = useSelector(selectUserId);
  const vendor = useSelector((state) => selectVendorByOwnerId(state, userId));
  const vendorId = vendor ? vendor.vendorId : userId;
  const user = useSelector((state) => usersSelectors.selectById(state, userId));
  const hasAddress = useSelector((state) => selectHasAddress(state, userId));
  const {
    range = 25,
  } = user || {};
  const address = useSelector((state) =>
    locationsSelectors.selectById(state, userId)
  );

  const [newAddress, setNewAddress] = useState(address);

  useEffect(() => {
    if (address) {
      setNewAddress(address);
    }
  }, [address]);
  console.log({
    products,
    memberships,
    selectedIndex,
    userId,
    vendor,
    vendorId,
    user,
    hasAddress,
  });

  const handleAddressUpdate =  async (updatedAddress) => {
    setNewAddress(updatedAddress);
    const {
      geometry,
      ...restAddress
    } = newAddress;
    await dispatch(
      updateLocation({
        id: userId,
        payload: {
          ownerId: userId,
          latitude: newAddress.lat ?? NORTHPOLE.latitude,
          longitude: newAddress.lng ?? NORTHPOLE.longitude,
          location: newAddress.formatted_address ?? "",
          ...restAddress,
          range: values?.range ? values.range : 25,
        },
      })
    );
    console.log({ lat: newAddress.lat, lng: newAddress.lng, range: values.range })
    await dispatch(
      getVendorsByLatLngRadiusInM({
        lat: newAddress.lat ?? NORTHPOLE.latitude,
        lng: newAddress.lng ?? NORTHPOLE.longitude,
        radiusInM: values?.range ? parseInt(values.range) * 1000 : 25000,
      })
    );
  };

  const handleRangeUpdate = (range) => {
    dispatch(updateUser({ id: userId, payload: { range: range ?? 25 } }));
    dispatch(
      updateLocation({
        id: userId,
        payload: {
          range: range ?? 25,
        },
      })
    );
  }

  const formik = useFormik({
    initialValues: {
      categories: [],
    },
    onSubmit: () => {
      setOpenFilter(false);
    },
  });

  const { resetForm, handleSubmit } = formik;

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleResetFilter = () => {
    handleSubmit();
    resetForm();
  };

  const handleAddNewProduct = async () => {
    try {
      const docRef = doc(collection(db, "products"));
      await dispatch(
        updateProduct({
          id: docRef.id,
          payload: {
            availableFrom: Date.now(),
            availableTo: Date.now() + 1000 * 60 * 60 * 24 * 365, // Unix timestamp for a year from now
            id: docRef.id,
            nameText: "",
            vendorId: vendorId,
            parentId: docRef.id,
            status: "draft",
          },
        })
      );
      navigate(`/vendors/${vendorId}/products/${docRef.id}/edit`);
    } catch (error) {
      console.error("Failed to create product:", error);
      // Display an error message to the user, e.g. using a toast or an alert
    }
  };

  const { values, setFieldValue } = formik;

  const handleDelete = (item) =>
    setFieldValue(
      "categories",
      values.categories.filter((c) => c !== item)
    );

  const handleMenuItemClick = (index) => {
    dispatch(updateFilters({ sortIndex: index }));
  };

  return (
    <Suspense fallback={<LinearProgress />}>
      <Page title={intl.formatMessage({ id: "products" })}>
        {hasAddress ? (
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                {intl.formatMessage({ id: "products" })}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              flexWrap="wrap-reverse"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ mb: 5 }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                flexShrink={0}
                sx={{ my: 1 }}
              >
                {values.categories.map((c) => (
                  <Chip
                    onDelete={() => handleDelete(c)}
                    label={intl.formatMessage({ id: c })}
                  />
                ))}
                <Stack direction="row">
                  <Suspense
                    fallback={<Skeleton variant="rounded" sx={{ margin: 1 }} width={50} height={50} />}
                  >
                    <ProductFilterSidebar
                      formik={formik}
                      isOpenFilter={openFilter}
                      onResetFilter={handleResetFilter}
                      onOpenFilter={handleOpenFilter}
                      onCloseFilter={handleCloseFilter}
                    />
                  </Suspense>
                  <Suspense fallback={<Skeleton variant="rounded" sx={{ margin: 1 }} width={50} height={50} />}>
                    <ProductSort
                      selectedIndex={selectedIndex}
                      handleMenuItemClick={handleMenuItemClick}
                    />
                  </Suspense>
                </Stack>
              </Stack>
            </Stack>

            {products.length > 0 || memberships.length > 0 ? (
              <Suspense fallback={<Skeleton variant="rounded"  sx={{ margin: 1 }} width={50} height={50} />}>
                <ProductList products={products} memberships={memberships} />
              </Suspense>
            ) : (
              <Container maxWidth="xs">
                <Stack> 
                  <Typography variant="p" gutterBottom>
                    {intl.formatMessage({ id: "no_products_message" })}
                  </Typography>
                  <Button
                    variant="contained"
                    component={RouterLink}
                    to="#"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={handleAddNewProduct}
                  >
                    {intl.formatMessage({ id: "new_product" })}
                  </Button>
                </Stack>
              </Container>
            )}
            <Suspense fallback={<Skeleton variant="rounded"  sx={{ margin: 1 }} width={50} height={50} />}>
              <ProductCartWidget />
            </Suspense>
          </Container>
        ) : (
          <Container>
            <Card sx={{ zIndex: 2 }}>
                  <CardHeader
                    title={intl.formatMessage({ id: "location" })}
                    subheader={intl.formatMessage({
                      id: "location_subheader",
                    })}
                    action={
                      newAddress && (
                        <Button variant="contained" onClick={handleSubmit}>
                          {intl.formatMessage({ id: "save" })}
                        </Button>
                      )
                    }
                  />
                  <CardContent>
                    <Box sx={{ height: 450 }}>
                      <MapContainer
                        address={{ ...newAddress }}
                        formatted_address={newAddress?.formatted_address ?? ""}
                        libraries={libraries}
                        range={range}
                        onAddressUpdate={handleAddressUpdate}
                        onRangeUpdate={handleRangeUpdate}
                        vendors={[]}
                      />
                    </Box>
                  </CardContent>
                </Card>
          </Container>
        )}
      </Page>
    </Suspense>
  );
}
