/* eslint-disable react/style-prop-object */
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Card, Container, Tab, Tabs, Typography } from "@mui/material";
import Page from "../../Page";
import Scrollbar from "../../Scrollbar";
import {
  selectMembershipMastersByVendor,
  selectStatuses,
  selectUserId,
  selectProductsByVendor,
  selectVendorByOwnerId,
} from "../../../store";
import { useIntl } from "react-intl";
import ItemsTable from "./ItemsTable";
import MembershipsTable from "./MembershipsTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Products() {
  const intl = useIntl();
  const userId = useSelector(selectUserId);
  const vendor = useSelector((state) => selectVendorByOwnerId(state, userId));
  const vendorId = vendor ? vendor.vendorId : userId;

  const userProducts = useSelector((state) =>
    selectProductsByVendor(state, userId)
  );
  const vendorProducts = useSelector((state) =>
    selectProductsByVendor(state, vendorId)
  );
  const products = [...userProducts, ...vendorProducts];
  
  const memberships = useSelector((state) =>
    selectMembershipMastersByVendor(state, vendorId)
  );

  const statuses = useSelector(selectStatuses);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title={intl.formatMessage({ id: "products" })}>
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          {intl.formatMessage({ id: "catalog" })}
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab
            label={intl.formatMessage({ id: "products" })}
            {...a11yProps(0)}
          />
          <Tab label={intl.formatMessage({ id: "memberships" })} />
          {/* <Tab label={intl.formatMessage({ id: "subscriptions" })} /> */}
        </Tabs>
        <Card>
          <Scrollbar>
            <TabPanel value={value} index={0}>
              <ItemsTable
                collectionName={"products"}
                docName={"product"}
                items={products}
                statuses={statuses}
                vendorId={vendorId}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <MembershipsTable
                collectionName={"memberships"}
                docName={"membership"}
                items={memberships}
                statuses={statuses}
                vendorId={vendorId}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ItemsTable
                collectionName={"products"}
                docName={"product"}
                items={products}
                statuses={statuses}
                vendorId={vendorId}
              />
            </TabPanel>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
}
