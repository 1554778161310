/* eslint-disable react/style-prop-object */
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
} from "@mui/material";
import SvgIconStyle from "../../components/SvgIconStyle";

const CardMediaStyle = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const TitleStyle = styled(Link)({
  height: 44,
  overflow: "hidden",
  WebkitLineClamp: 2,
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const CoverImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  position: "absolute",
});

export default function ReserveCard({ reserve, index }) {
  const { name, vendorName, vendorPhoto, price = "", productPhoto } = reserve;

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Card sx={{ position: "relative" }}>
        <CardMediaStyle>
          <SvgIconStyle
            color="paper"
            src="/static/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: "absolute",
            }}
          />
          <AvatarStyle alt={vendorName} src={vendorPhoto} />
          <CoverImgStyle alt={name} src={productPhoto} />
        </CardMediaStyle>
        <CardContent
          sx={{
            pt: 4,
          }}
        >
          <Typography
            gutterBottom
            variant="caption"
            sx={{ color: "text.disabled", display: "block" }}
          >
            {vendorName}
          </Typography>
          <TitleStyle
            to="#"
            color="inherit"
            variant="subtitle2"
            underline="hover"
            component={RouterLink}
          >
            {name}{" "}
            {price !== undefined &&
              price !== 0 &&
              price !== "" &&
              `$${parseFloat(price).toFixed(2)}`}
          </TitleStyle>
        </CardContent>
      </Card>
    </Grid>
  );
}
