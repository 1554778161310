import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useIntl } from "react-intl";
import { getStorage, ref, uploadBytesResumable } from "firebase/storage";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  Slider,
  Stack,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/material/styles";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

function getResizedCanvas(canvas, newWidth, newHeight) {
  const tmpCanvas = document.createElement("canvas");
  tmpCanvas.width = newWidth;
  tmpCanvas.height = newHeight;

  const ctx = tmpCanvas.getContext("2d");
  ctx.drawImage(
    canvas,
    0,
    0,
    canvas.width,
    canvas.height,
    0,
    0,
    newWidth,
    newHeight
  );

  return tmpCanvas;
}

const aspect = 1 / 1;

export default function ImageCrop({
  open,
  title,
  file,
  path,
  fileName,
  onUploadSuccess,
  ...rest
}) {
  const intl = useIntl();
  const theme = useTheme();
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const storage = getStorage();
  const storageRef = ref(storage, `${path}/${fileName}`);

  const generateDownload = () => {
    const canvas = getResizedCanvas(
      previewCanvasRef.current,
      completedCrop.width,
      completedCrop.height
    );

    canvas.toBlob(
      (blob) => {
        const uploadTask = uploadBytesResumable(storageRef, blob);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            setIsLoading(true);
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log({ progress });
            switch (snapshot.state) {
              case "paused":
                break;
              case "running":
                break;
              default:
                console.log("default");
            }
          },
          (error) => {
            console.error({ error });
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
            onUploadSuccess(uploadTask.snapshot);
          }
        );
      },
      "image/jpg",
      0.6
    );
  };

  const handleSave = async () => {
    generateDownload(previewCanvasRef.current, completedCrop);
  };

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  const onDrop = useCallback((acceptedFiles) => {
    // console.log({ acceptedFiles });
  }, []);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  useEffect(() => {
    if (acceptedFiles?.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const handleClose = () => {
    const { handleClose } = rest;
    handleClose();
  };

  const handleCancel = () => {
    setImgSrc(null);
    handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Container
        maxWidth="md"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          height: ["100%", "auto"], // height 100% for mobile devices and auto for larger screens
          padding: [2, 4], // padding 2 for mobile devices and 4 for larger screens
        }}
      >
        <Stack alignItems="center">
          <Box>
            {Boolean(imgSrc) ? (
              <Box>
                <ReactCrop
                  aspect={aspect}
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  ruleOfThirds={true}
                >
                  <img
                    // height="100%"
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                      maxWidth: "100%", // added this
                      maxHeight: "100%", // and this
                    }}
                    onLoad={onImageLoad}
                  />
                  <div style={{ display: "none" }}>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: completedCrop?.width ?? 0,
                        height: completedCrop?.height ?? 0,
                      }}
                    />
                  </div>
                </ReactCrop>
              </Box>
            ) : (
              <Box
                {...getRootProps()}
                sx={{
                  height: ["200px", "50vh"], // height 200px for mobile devices and 50vh for larger screens
                  width: ["100%", "50vw"], // width 100% for mobile devices and 50vw for larger screens
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderStyle: "dashed",
                  borderColor: theme.palette.secondary.main,
                }}
              >
                <input {...getInputProps()} />
                <Typography>
                  {intl.formatMessage({ id: "drop_or_select_file_label" })}
                </Typography>
              </Box>
            )}
          </Box>
        </Stack>
        <Stack>
          <Box>
            <label htmlFor="scale-input">
              {intl.formatMessage({ id: "scale" })}
            </label>
            <Slider
              id="scale-input"
              step={0.1}
              min={1}
              max={10}
              disabled={!imgSrc}
              aria-label="Volume"
              value={scale}
              onChange={(e) => setScale(Number(e.target.value))}
            />
          </Box>
          <Box>
            <label htmlFor="rotate-input">
              {intl.formatMessage({ id: "rotate" })}
            </label>
            <Slider
              id="rotate-input"
              min={0}
              max={360}
              disabled={!imgSrc}
              aria-label="rotate-input"
              value={rotate}
              onChange={(e) =>
                setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
              }
            />
          </Box>
        </Stack>
      </Container>
      <DialogActions>
        <Button onClick={handleCancel}>
          {intl.formatMessage({ id: "cancel" })}
        </Button>
        <Button onClick={handleSave}>
          {intl.formatMessage({ id: "save" })}
        </Button>
      </DialogActions>
      <Backdrop sx={{ color: "#fff", zIndex: 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}

ImageCrop.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  file: PropTypes.string,
  path: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func,
};
