/* eslint-disable react/style-prop-object */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Card,
  // CardActions,
  CardHeader,
  CardContent,
  Container,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import {
  fulfillmentStatusChip,
  statusChip,
  paymentStatusChip,
} from "../../utils/chips";
import Page from "../../components/Page";
import {
  fetchOrderLines,
  ordersSelectors,
  selectLinesByOrderId,
} from "../../store";
import { useIntl } from "react-intl";

export default function Purchase() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(fetchOrderLines(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const purchase = useSelector((state) =>
    ordersSelectors.selectById(state, id)
  );
  const lines = useSelector((state) => selectLinesByOrderId(state, id)) || [];

  const date = new Date(purchase.created); // Convert to milliseconds
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
    timeZoneName: "short",
  };
  const orderCreated = date.toLocaleDateString("en-US", options);

  return (
    <Page title={intl.formatMessage({ id: "purchase" })}>
      <Container maxWidth="sm">
        <Link href="/purchases">
          {intl.formatMessage({ id: "all" })}{" "}
          {intl.formatMessage({ id: "purchases" })}
        </Link>
        <Card>
          <CardHeader
            title={`${intl.formatMessage({ id: "purchase" })} # ${purchase.id}`}
            subheader={`${intl.formatMessage({
              id: "total",
            })} ${intl.formatNumber(purchase.total, {
              style: "currency",
              currency: "CAD",
            })}`}
            action={
              <>
                {purchase.status &&
                  statusChip(
                    purchase.status,
                    intl.formatMessage({ id: purchase.status })
                  )}
                {purchase.paymentStatus &&
                  paymentStatusChip(
                    purchase.paymentStatus,
                    intl.formatMessage({ id: purchase.paymentStatus })
                  )}
                {purchase.fulfillmentStatus &&
                  fulfillmentStatusChip(
                    purchase.fulfillmentStatus,
                    intl.formatMessage({ id: purchase.fulfillmentStatus })
                  )}
              </>
            }
          />
          <CardContent>
            <Typography>{orderCreated}</Typography>
            <Typography>{purchase.vendorName}</Typography>
            <List dense>
              {lines.map((line, i) => (
                <ListItem key={i}>
                  <ListItemAvatar>
                    <Avatar src={line.itemPhoto} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={line.name}
                    secondary={`${line.quantity} x ${intl.formatNumber(
                      line.price,
                      {
                        style: "currency",
                        currency: "CAD",
                      }
                    )}`}
                  />
                  <ListItemSecondaryAction>
                    {`${intl.formatNumber(line.subTotal, {
                      style: "currency",
                      currency: "CAD",
                    })}`}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </CardContent>
          {/* <CardActions>
            <Chip
              variant="outlined"
              label={`${intl.formatNumber(purchase.total, {
                style: "currency",
                currency: "CAD",
              })}`}
            />
          </CardActions> */}
        </Card>
      </Container>
    </Page>
  );
}
