/* eslint-disable react/style-prop-object */
import { useSelector } from "react-redux";
import { Container } from "@mui/material";
import Page from "../../components/Page";
import {
  ProductCartWidget,
} from "../../components/_dashboard/products";
import { enrolmentsSelectors, invitationsSelectors } from "../../store";
import { useIntl } from "react-intl";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import EnrolmentCard from "./EnrolmentCard";
import InviteCard from "./InviteCard";

export default function Enrolments() {
  const intl = useIntl();
  const enrolments = useSelector(enrolmentsSelectors.selectAll);
  const invitations = useSelector(invitationsSelectors.selectAll);

  return (
    <Page title={intl.formatMessage({ id: "enrolments" })}>
      <Container maxWidth="lg">
        {invitations?.length > 0 && (
          <Card>
            <CardHeader title={intl.formatMessage({ id: "invitations" })} />
            <CardContent>
              <Grid container spacing={3}>
                {invitations.map((invite, i) => (
                  <InviteCard key={invite.id} invite={invite} index={i} />
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}
        {enrolments?.length > 0 ? (
          <Card>
            <CardHeader title={intl.formatMessage({ id: "enrolments" })} />
            <CardContent>
              <Grid container spacing={3}>
                {enrolments.map((enrolment, i) => (
                  <EnrolmentCard key={enrolment.id} enrolment={enrolment} index={i} />
                ))}
              </Grid>
            </CardContent>
          </Card>
        ) : <Typography>{intl.formatMessage({ id: "no_enrolments" })}</Typography>}
        <ProductCartWidget />
      </Container>
    </Page>
  );
}
