import { PURGE } from "redux-persist";
import {
  collection,
  endAt,
  getDocs,
  orderBy,
  query,
  startAt,
  where,
} from "firebase/firestore";
import {
  current,
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  createEntityAdapter,
  nanoid,
} from "@reduxjs/toolkit";
import {
  logout,
  refreshSite,
  removeOneEnrolment,
  removeOneVendor,
  selectProductVendor,
  selectSearchText,
  selectSortIndex,
  // selectUserLocation,
  selectVendorIdBySessionUserId,
  setSnackbar,
  upsertOneVendor,
} from "./index";
import * as api from "../apis/firebase";

export const batchProducts = createAsyncThunk(
  "products/batchProducts",
  async (payload, { dispatch, getState }) => {
    const { parentId, useSnackbar = true} = payload;
    if (useSnackbar) {
      dispatch(setSnackbar({ pageLoading: "loading" }));
    }
    const state = getState();

    const { uid, displayName, photoURL } = state?.session?.user;
    if (!uid) return;
    const author = selectProductVendor(state, uid);
    const { vendorPhoto, vendorName, vendorId } = author;

    const childProducts = Object.values(state?.products?.entities)
      .filter((product) => product.parentId === parentId)
      .map((child) => ({
        ...child,
        vendorId: vendorId || uid,
        vendorPhoto: vendorPhoto || photoURL,
        vendorName: vendorName || displayName,
      }));

    const parentProduct = childProducts.find(
      (product) => product.id === parentId
    );

    if (!parentProduct) {
      return null; // Parent product not found
    }

    const allChildPlans = childProducts.reduce((plans, product) => {
      if (Array.isArray(product.plans)) {
        return [...plans, ...product.plans];
      }
      return plans;
    }, []);

    const parentProductWithPlanUnion = {
      ...parentProduct,
      plans: parentProduct.plans
        ? [...new Set([...parentProduct.plans, ...allChildPlans])]
        : [...new Set([...allChildPlans])],
    };

    const products = childProducts.map((product) =>
      product.id === parentId ? parentProductWithPlanUnion : product
    );
    console.log({ products });
    const response = await api.batchProducts(products);
    return response;
  }
);

export const getProductsByLatLngRadiusInM = createAsyncThunk(
  "products/getProductsByGeoHash",
  async (payload, { dispatch }) => {
    const { lat = 90, lng = 0, radiusInM = 2500 } = payload;
    console.log({ payload, lat, lng });
    const center = [lat, lng];
    const bounds = api.geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = query(
        collection(api.db, "vendors"),
        where("status", "==", "active"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
      promises.push(getDocs(q));
    }

    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const { geoPoint } = doc.data();
            if (geoPoint?._lat && geoPoint?._long) {
              const { _lat, _long } = geoPoint;
              console.log({ _lat, _long });
              const distanceInKm = api.geofire.distanceBetween(
                [_lat, _long],
                center
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc);
              }
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        // Process the matching documents
        // ...
        console.log({ matchingDocs });
        for (const doc of matchingDocs) {
          dispatch(upsertOneVendor({ id: doc.id, ...doc.data() }));
        }
      });
  }
);

export const refreshProducts = createAsyncThunk(
  "products/getProductsByGeoHash",
  async (_, { dispatch, getState }) => {
    const state = getState();
    const uid = state.session?.user?.uid;
    const entities = state.locations?.entities;
    if (!uid || !entities) {
      return;
    }
    const location = entities[uid];
    if (!location) {
      return;
    }
    const { latitude = 90, longitude = 0, radiusInM = 2500 } = location;
    const center = [latitude, longitude];
    const bounds = api.geofire.geohashQueryBounds(center, radiusInM);
    const promises = [];
    for (const b of bounds) {
      const q = query(
        collection(api.db, "vendors"),
        where("status", "==", "active"),
        orderBy("geohash"),
        startAt(b[0]),
        endAt(b[1])
      );
      promises.push(getDocs(q));
    }

    Promise.all(promises)
      .then((snapshots) => {
        const matchingDocs = [];
        for (const snap of snapshots) {
          for (const doc of snap.docs) {
            const { geoPoint } = doc.data();
            if (geoPoint?._lat && geoPoint?._long) {
              const { _lat, _long } = geoPoint;
              console.log({ _lat, _long });
              const distanceInKm = api.geofire.distanceBetween(
                [_lat, _long],
                center
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= radiusInM) {
                matchingDocs.push(doc);
              }
            }
          }
        }

        return matchingDocs;
      })
      .then((matchingDocs) => {
        // Process the matching documents
        // ...
        for (const doc of matchingDocs) {
          dispatch(upsertOneVendor({ id: doc.id, ...doc.data() }));
        }
      });
  }
);

export const resetProduct = createAsyncThunk(
  "products/reset",
  async (parentId, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    dispatch(resetVariants({ parentId }));
    const q = query(
      collection(api.db, "products"),
      where("parentId", "==", parentId)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      dispatch(upsertOneProduct({ id: doc.id, ...doc.data() }));
    });
    return {
      ...querySnapshot,
    };
  }
);

const childOptionsAdapter = createEntityAdapter({
  selectId: (option) => option.optionId,
});

const optionsAdapter = createEntityAdapter();

const productsAdapter = createEntityAdapter({});

export const deleteProduct = createAsyncThunk("products/delete", async (id) => {
  const response = await api.removeDoc("products", id);
  return response;
});

export const deleteProducts = createAsyncThunk(
  "products/deleteMany",
  async (ids, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.deleteProducts(ids);
    return response;
  }
);

export const updateProduct = createAsyncThunk(
  "products/update",
  async ({ id, payload }, { dispatch }) => {
    dispatch(setSnackbar({ pageLoading: "loading" }));
    const response = await api.updateDoc("products", id, payload);
    return response;
  }
);

export const optionSelected = createAction("products/optionSelected");

export const products = createSlice({
  name: "products",
  initialState: productsAdapter.getInitialState(),
  reducers: {
    addOneOption(state, action) {
      const optionId = nanoid();
      const productId = action.payload.productId;
      const productEntry = state.entities[productId];
      if (productEntry) {
        childOptionsAdapter.addOne(productEntry.childOptions, {
          optionId,
          productId,
          key: "",
          optionValues: [],
        });
      }
    },
    upsertManyProducts: productsAdapter.upsertMany,
    removeAllProduct: productsAdapter.removeAll,
    removeManyProducts(state, action) {
      productsAdapter.removeMany(state, [...action.payload]);
    },
    removeOneOption(state, { payload: { parentId, productId, optionId } }) {
      const productEntry = state.entities[productId];
      if (productEntry) {
        childOptionsAdapter.removeOne(productEntry.childOptions, optionId);
        const currentVariants = Object.values(state?.entities || {}).filter(
          (variant) => variant.parentId === parentId && variant.id !== variant.parentId
        );
        const currentVariantMap = currentVariants.reduce((acc, variant) => {
          acc[variant.handle] = variant;
          return acc;
        }, {});
        const variants = productEntry.childOptions.ids.length
          ? Object.values(productEntry.childOptions.entities)
              .map(({ key, optionValues, optionId }) =>
                optionValues.map((value) => ({ key, value, optionId }))
              )
              .reduce((a, b) =>
                b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
              )
              .map((variant) => {
                const arr = Array.isArray(variant) ? variant : [variant];
                const options = arr.reduce(
                  (acc, curr) => ({
                    ids: [...acc.ids, curr.optionId],
                    entities: {
                      ...acc.entities,
                      [curr.optionId]: curr,
                    },
                  }),
                  { ids: [], entities: {} }
                );
                const handle = arr.reduce(
                  (acc, curr, j) =>
                    acc.concat(
                      `${j > 0 ? `-` : ""}${curr.value.replace(/\s/g, "")}`
                    ),
                  ""
                );
                const id = arr.reduce(
                  (acc, curr) =>
                    acc.concat(`-${curr.value.replace(/\s/g, "")}`),
                  `${productId}`
                );
                const name = arr.reduce(
                  (acc, curr, j) =>
                    acc.concat(`${j > 0 ? ` / ` : ""}${curr.value}`),
                  ""
                );
                if (currentVariantMap[handle]) {
                  return currentVariantMap[handle];
                }
                return {
                  ...productEntry,
                  childOptions: { ids: [], entities: {} },
                  handle,
                  id,
                  name,
                  options,
                  parentId: productId,
                  parentName: productEntry?.name || "",
                };
              })
          : [];
          const variantsToDelete = Object.values(state?.entities).filter(
            (variant) => variant.parentId === parentId 
                        && variant.id !== variant.parentId
                        && !variants.find(v => v.id === variant.id)
          );
        
          productsAdapter.removeMany(state, variantsToDelete.map((v) => v.id));
        
          productsAdapter.setMany(state, [
            {
              ...productEntry,
              hasVariants: true,
              selectedVariantId: variants.length > 0 ? variants[0].id : productId,
            },
            ...variants,
          ]);
      }
    },
    removeOneProduct: productsAdapter.removeOne,
    resetVariants(state, action) {
      const productsToDelete = Object.values(state.entities).filter(
        (product) =>
          product.parentId === action.payload.parentId &&
          product.id !== product.parentId
      );
      productsAdapter.removeMany(
        state,
        productsToDelete.map((product) => product.id)
      );
    },
    upsertOneOption(state, action) {
      const { productId, parentId } = action.payload;
      const productEntry = state.entities[productId];
      if (productEntry?.childOptions === undefined) {
        childOptionsAdapter.upsertOne(productEntry, {
          childOptions: { ids: [], entities: {} },
        });
      }
      if (productEntry) {
        childOptionsAdapter.upsertOne(
          productEntry.childOptions,
          action.payload
        );
      }
    
      const parentName = productEntry?.name || "";
      const currentVariants = Object.values(state?.entities || {}).filter(
        (variant) => variant.parentId === parentId && variant.id !== variant.parentId
      );
      const currentVariantMap = currentVariants.reduce((acc, variant) => {
        acc[variant.handle] = variant;
        return acc;
      }, {});
      const variants = Object.values(productEntry?.childOptions?.entities || {})
        .map(({ key, optionValues, optionId }) =>
          optionValues.map((value) => ({ key, value, optionId }))
        )
        .reduce((a = [], b = []) =>
          b.length ? a.flatMap((d) => b.map((e) => [d, e].flat())) : a
        )
        .map((variant) => {
          const arr = Array.isArray(variant) ? variant : [variant];
          const options = arr.reduce(
            (acc, curr) => ({
              ids: [...acc.ids, curr.optionId],
              entities: {
                ...acc.entities,
                [curr.optionId]: curr,
              },
            }),
            { ids: [], entities: {} }
          );
          const handle = arr.reduce(
            (acc, curr, j) =>
              acc.concat(`${j > 0 ? `-` : ""}${curr.value.replace(/\s/g, "")}`),
            ""
          );
          const id = arr.reduce(
            (acc, curr) => acc.concat(`-${curr.value.replace(/\s/g, "")}`),
            `${productId}`
          );
          const name = arr.reduce(
            (acc, curr, j) => acc.concat(`${j > 0 ? ` / ` : ""}${curr.value}`),
            ""
          );
          if (currentVariantMap[handle]) {
            return currentVariantMap[handle];
          }
          return {
            ...productEntry,
            childOptions: { ids: [], entities: {} },
            handle,
            id,
            name,
            options,
            parentName,
            parentId: productId,
          };
        });
    
      const variantsToDelete = Object.values(state?.entities).filter(
        (variant) => variant.parentId === parentId 
                    && variant.id !== variant.parentId
                    && !variants.find(v => v.id === variant.id)
      );
    
      productsAdapter.removeMany(state, variantsToDelete.map((v) => v.id));
    
      productsAdapter.setMany(state, [
        {
          ...productEntry,
          hasVariants: true,
          selectedVariantId: variants.length > 0 ? variants[0].id : productId,
        },
        ...variants,
      ]);
    },    
    upsertOneProduct: {
      reducer: (state, action) => {
        const productEntry = state.entities[action.payload.id];

        if (productEntry) {
          productsAdapter.upsertOne(state, action.payload);
        } else {
          productsAdapter.upsertOne(state, {
            options: optionsAdapter.getInitialState(),
            childOptions: childOptionsAdapter.getInitialState(),
            ...action.payload,
          });
        }
      },
      prepare: (data) => {
        const { at, updated, created, geoPoint, ...rest } = data;
        return {
          payload: {
            ...rest,
          },
        };
      },
    },
    updateOneProduct: productsAdapter.setOne,
    upsertParentAndChildren(state, action) {
      const {
        id,
        options,
        childOptions,
        name,
        productPhoto,
        productPhotoId,
        ...rest
      } = action.payload;
      const variants = Object.values(state.entities)
        .filter(
          (entity) => entity.parentId === id && entity.id !== entity.parentId
        )
        .map((child) => ({
          ...rest,
          id: child.id,
          parentName: name,
          productPhoto: child.productPhoto || productPhoto,
          productPhotoId: child.productPhotoId || productPhotoId,
        }));
      productsAdapter.upsertMany(state, [action.payload, ...variants]);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(optionSelected, (state, { payload }) => {
        const { productId, optionId, value } = payload;
        const productEntry = state.entities[productId];
        if (productEntry) {
          childOptionsAdapter.upsertOne(productEntry.childOptions, {
            optionId,
            selected: value,
          });
          const newOptions = productEntry.childOptions.entities;
          const selectedVariantId = Object.values(state.entities)
            .filter(
              ({ parentId, id }) => parentId === productId && id !== parentId
            )
            .reduce((acc, { options = {}, id }) => {
              if (
                Object.entries(options?.entities || {}).every(
                  ([key, { value }]) => {
                    return newOptions[key]?.selected === value;
                  }
                )
              ) {
                acc = id;
              }
              return acc;
            }, undefined);
          console.log({ "HEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE": optionId, optionId, value, productId, selectedVariantId, })
          productsAdapter.upsertOne(state, {
            id: productId,
            selectedVariantId,
          });
        }
      })
      .addCase(PURGE, (state) => {
        productsAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        productsAdapter.removeAll(state);
      })
      .addCase(removeOneEnrolment, (state, { payload }) => {
        console.log({ state: current(state), payload });
        if (state?.entities) {
          const productsToDelete = Object.values(state.entities).reduce(
            (acc, { plans = [], id }) =>
              plans.includes(payload) ? [...acc, id] : acc,
            []
          );
          productsAdapter.removeMany(state, productsToDelete);
        }
      })
      .addCase(removeOneVendor, (state, { payload }) => {
        console.log({ state: current(state), payload });
        if (state?.entities) {
          const productsToDelete = Object.values(state.entities).reduce(
            (acc, { vendorId, id }) =>
              vendorId && vendorId === payload ? [...acc, id] : acc,
            []
          );
          console.log({ productsToDelete });
          productsAdapter.removeMany(state, productsToDelete);
        }
      })
      .addCase(refreshSite.fulfilled, (state) => {
        productsAdapter.removeAll(state);
      });
  },
});

export const {
  addOneOption,
  removeAllProduct,
  removeManyProducts,
  removeOneOption,
  removeOneProduct,
  resetVariants,
  updateOneProduct,
  upsertManyProducts,
  upsertOneOption,
  upsertOneProduct,
  upsertParentAndChildren,
} = products.actions;

export const productsSelectors = productsAdapter.getSelectors(
  (state) => state.products
);

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  productsSelectors;

export const selectProducts = (state) => state.products;

export const selectProductsByParent = createSelector(selectAll, (products) =>
  products.filter(({ id, parentId }) => id === parentId)
);

export const selectActiveProductsByParent = createSelector(
  selectAll,
  (products) =>
    products.filter(
      ({ id, parentId, status = "draft" }) =>
        id === parentId && status === "active"
    )
);

export const selectProductsByVendor = createSelector(
  [selectProductsByParent, (state, vendorId) => vendorId],
  (products, vendorId) =>
    products.filter((product) => product.vendorId === vendorId)
);

export const selectProductsByVendorIdByUserSessionId = createSelector(
  [(state) => state, (state) => selectVendorIdBySessionUserId(state)],
  (state, vendorId) => selectProductsByVendor(state, vendorId)
);

export const selectProductsOrVariantsByVendorIdByUserSessionId = createSelector(
  [selectAll, (state) => selectVendorIdBySessionUserId(state)],
  (products, vendorId) =>
    products.filter(
      (product) =>
        vendorId === product.vendorId &&
        (product.id !== product.parentId ||
          (product.id === product.parentId &&
            product.childOptions?.ids?.length < 1))
    )
);

export const selectProductVariantsByVendorId = createSelector(
  [selectAll, (state, vendorId) => vendorId],
  (products, vendorId) =>
    products.filter(
      (product) =>
        vendorId === product.vendorId && product.id !== product.parentId
    )
);

export const selectProductsByVariant = createSelector(
  [selectAll, (state, parentId) => parentId],
  (products, parentId) =>
    products.filter(
      (product) =>
        parentId === product.parentId && product.id !== product.parentId
    )
);

const selectEnrolmentId = (state, itemId) => itemId;

export const selectProductsByEnrolmentIdByParent = createSelector(
  [selectAll, selectEnrolmentId],
  (products, enrolmentId) =>
    products.filter(
      ({ plans = [], parentId, id }) =>
        plans.includes(enrolmentId) && parentId === id
    )
);

export const selectVariants = (state, uid) =>
  Object.values(state).filter(
    ({ id, parentId }) => uid === parentId && id !== parentId
  );

const selectCategories = (state) => state?.filters?.categories ?? [];

export const selectFilteredActiveProductsByParent = createSelector(
  [
    selectActiveProductsByParent,
    selectCategories,
    selectSearchText,
    selectSortIndex,
  ],
  (products, selected, searchText, sortIndex) =>
    products
      .filter((product) =>
        selected.length < 1
          ? product
          : selected.some((s) => product?.categories.includes(s))
      )
      .filter((product) =>
        searchText ? product.name.toLowerCase().includes(searchText.toLowerCase()) : product
      )
      .sort((a, b) => {
        if (sortIndex === 2) return a.price - b.price;
        if (sortIndex === 1) return b.price - a.price;
        return b.availableFrom - a.availableFrom;
      })
);

export default products.reducer;
