import React, { useReducer } from "react";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { Avatar, Stack } from "@mui/material";
import ImageCrop from "./ImageCrop";
import { getDownloadURL } from "firebase/storage";

const initialState = {
  image: undefined,
  fileName: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}
const DataGridImageEdit = (props) => {
  const { src, intl, path, uid, name, change, variant = "rounded" } = props;

  const [{ selectedImage, fileName }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const d = new Date();

  const handleClick = async () => {
    await dispatch({
      type: "stateChanged",
      payload: {
        fileName: d.getTime(),
      },
    });
    dispatch({
      type: "stateChanged",
      payload: {
        selectedImage: true,
      },
    });
  };

  const handlePhotoUploadSuccess = (snapshot) => {
    getDownloadURL(snapshot.ref).then((downloadURL) => {
      change({ name, downloadURL, fileName });
      dispatch({
        type: "stateChanged",
        payload: {
          selectedImage: undefined,
        },
      });
    });
  };

  return (
    <Stack>
      <Avatar variant={variant} src={src} onClick={() => handleClick()}>
        <AddAPhotoIcon />
      </Avatar>
      <input
        name={name}
        accept="image/*"
        id="contained-button-file"
        type="file"
        hidden
      />
      <ImageCrop
        path={`${path}/${uid}`}
        fileName={fileName}
        onUploadSuccess={(s) => handlePhotoUploadSuccess(s)}
        open={selectedImage !== undefined}
        src={selectedImage}
        handleClose={() =>
          dispatch({
            type: "stateChanged",
            payload: {
              selectedImage: undefined,
            },
          })
        }
        title={intl.formatMessage({ id: "change" })}
      />
    </Stack>
  );
};

export default DataGridImageEdit;
