import { createSlice } from '@reduxjs/toolkit';
import {  refreshSite } from "./index";

const initialState = {
  locazoa: 'Locazoa'
};

export const locale = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    updateLocale: {
      reducer: (state, action) => action.payload,
      prepare: (data) => {
        if (data) {
          const { at, ...rest} = data;
          return {
            payload: {
              ...rest,
            }
          }
        }
        return {};
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshSite.fulfilled, (state) => {
        state = initialState;
      })
  },
});

export const { updateLocale } = locale.actions;

export const selectLocale = (state) => state.locale;

export default locale.reducer;