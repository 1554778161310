import { useEffect, } from "react";
import { useParams, } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Page from '../../components/Page';
import PlanForm from './PlanForm';
import {
  membershipPlansSelectors,
  removeOneMemberPlan,
  removeOneReserve,
  upsertOneMemberPlan,
  upsertOneReserve
} from '../../store';
import { useIntl } from 'react-intl';
import { db, } from "../../apis/firebase";
import {
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export default function Plan() {
  const { planId } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  useEffect(() => {
    if (!planId) return;
    const q0 = onSnapshot(query(
      collection(db, `memberships/${planId}/reserves`),
      where("id", "==", planId),
    ), (snapshot) => {
      snapshot
        .docChanges()
        .forEach(({ type, doc }) =>
          type === "removed"
            ? dispatch(removeOneReserve(doc.id))
            : dispatch(
                upsertOneReserve({
                  ...doc.data(),
                  id: doc.id,
                })
              )
        );
    });
    
    const q1 = onSnapshot(query(
      collection(db, `memberships/${planId}/members`),
      where("id", "==", planId),
    ), (snapshot) => {
      snapshot
        .docChanges()
        .forEach(({ type, doc }) =>
          type === "removed"
            ? dispatch(removeOneMemberPlan(doc.id))
            : dispatch(
                upsertOneMemberPlan({
                  ...doc.data(),
                  id: doc.id,
                })
              )
        );
    });
    return () => {
      q0();
      q1();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planId]);

  const plan = useSelector(state => membershipPlansSelectors.selectById(state, planId));
  return (
    <Page title={(plan?.id) || intl.formatMessage({ id: 'plan' })}>
      {plan !== undefined ? <PlanForm plan={plan} />
        :<div>loading</div>
      }      
    </Page>
  );
}
