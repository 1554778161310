import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, nanoid, } from '@reduxjs/toolkit';
import { addMember, logout } from './index';
import { collection, query, where, getDocs } from "firebase/firestore";
import * as api from '../apis/firebase';

const contactsAdapter = createEntityAdapter({
  selectId: (contact) => contact.contactId ?? contact.email
})

export const addContact = createAsyncThunk(
  "contacts/addContact",
  async ({ email, displayName }) => {
    const q = query(collection(api.db, "users"), where("email", "==", email));
    const querySnapshot = await getDocs(q);
    const users = [];
    querySnapshot.forEach((doc) => users.push({...doc.data(), id: doc.id}));
    const user = users[0];
    if (user) {
      const {
        displayName,
        email,
        geohash,
        id = user.id,
        userId = user.id,
        photoURL,
      } = user;
      return {
        id,
        contactId: id,
        displayName,
        email,
        geohash,
        userId,
        photoURL,
        status: 'contact',
        isRegistered: true
      };
    } else {
      const contactId = nanoid(20);
      return {
        id: contactId,
        displayName,
        email,
        contactId,
        status: 'unregistered',
        isRegistered: false
      };
    }  
  }
);

export const deleteContact = createAsyncThunk(
  'contacts/delete',
  async (id) => {
    const response = await api.removeDoc('contacts', id);
    return response;
  }
);

export const updateContact = createAsyncThunk(
  'contacts/update',
  async ({ id, payload }) => await api.updateDoc('contacts', id, payload)
);

export const contacts = createSlice({
  name: 'contacts',
  initialState: contactsAdapter.getInitialState(),
  reducers: {
    upsertOneContact: contactsAdapter.upsertOne,
    removeOneContact: contactsAdapter.removeOne,
    removeAllContact: contactsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMember.fulfilled, (state, action) => {
        const { email = "" } = action?.payload?.data ?? { email: ""};
        const contact = Object.values(state.entities).find(contact => email === contact.email);
        if (contact === undefined && action?.payload?.data && email !== "") {
          contactsAdapter.upsertOne(state, {
            ...action.payload.data,
            contactId: email
          });
        }        
      })
      .addCase(logout.fulfilled, (state) => {
        contactsAdapter.removeAll(state);
      })
  },
});

export const { 
  upsertOneContact, 
  removeOneContact,
  removeAllContact
} = contacts.actions;

export const contactsSelectors = contactsAdapter.getSelectors((state) => state.contacts);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = contactsSelectors;

export const selectContacts = state => state.contacts;

export const selectPosition = state => state.contacts.position;

export const selectContactByEmail = createSelector(
  [contactsSelectors.selectAll, (state, email) => email],
  (contacts, email) =>
  contacts.filter((contact) => email === contact?.email)
);

export default contacts.reducer;
