import React, { useState } from 'react';
import { 
    Box, 
    LinearProgress, 
    // useTheme 
} from '@mui/material';
import EggIcon from '@mui/icons-material/Egg';
import useImage from 'use-image';
import { styled } from "@mui/material/styles";
const CoverImgStyle = styled("img")({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  });

  const CoverBrokenImgStyle = styled(EggIcon)({
    top: 0,
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute",
  });


export const ImageViewer = ({ src, alt = ""}) => {
  const [image, status] = useImage(src);
  const [imageStatus, setImageStatus]= useState(status);

  React.useEffect(() => {
    if (status === "failed" || !image) {      
        setImageStatus("failed")
    } else {
        setImageStatus(status);
    };
  }, [src, status, image]);

  return (
    <Box>
      {imageStatus === 'loading' && <LinearProgress />}
      {imageStatus === 'failed' && <CoverBrokenImgStyle />}
      {imageStatus === 'loaded' && <CoverImgStyle alt={alt} src={src} />}
    </Box>
  );
};

export default ImageViewer;
