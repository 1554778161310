import { createAction, createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { logout } from './index';
import * as api from '../apis/firebase';

export const setCurrentPosition = createAction('places/setCurrentPosition');

const placesAdapter = createEntityAdapter({
  selectId: (place) => place.uid,
})

export const deletePlace = createAsyncThunk(
  'places/delete',
  async (id) => {
    const response = await api.removeDoc('places', id);
    console.log({ response });
    return response;
  }
);

export const updatePlace = createAsyncThunk(
  'places/update',
  async ({ id, payload }) => {
    const response = await api.updateDoc('places', id, payload);
    return response;
  }
);

export const places = createSlice({
  name: 'places',
  initialState: placesAdapter.getInitialState({
    position: { coords: { longitude: 0, latitude: 0 }}
  }),
  reducers: {
    upsertOnePlace: placesAdapter.upsertOne,
    removeOnePlace: placesAdapter.removeOne,
    removeAllPlace: placesAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCurrentPosition, (state, action) => {
        const { 
          coords: {
            latitude,
            longitude
          }
        } = action.payload;
        state.position = {
          coords: {
            latitude,
            longitude
          }
        }
      })
      .addCase(logout.fulfilled, (state) => {
        placesAdapter.removeAll(state);
      })
  },
});

export const { 
  upsertOnePlace, 
  removeOnePlace,
  removeAllPlace
} = places.actions;

export const placesSelectors = placesAdapter.getSelectors((state) => state.places);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = placesSelectors;

export const selectHasAddress = state => state?.locations?.ids && state.locations.ids.length > 0;

export const selectPlaces = state => state.places;

export const selectPosition = state => state.places.position;

export default places.reducer;
