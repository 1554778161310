import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { wipeout } from './index';

const initialState = {
  status: 'idle',
  categories: [],
  isLoadingTimeout: 5000,
  languages: [
    {
      value: 'en-US',
      label: 'English',
      icon: '/static/icons/ic_flag_ca.svg',
      inputValue: 'enUS'
    },
    {
      value: 'fr-FR',
      label: 'French',
      icon: '/static/icons/ic_flag_fr.svg',
      inputValue: 'frFR'
    },
    {
      value: 'de-DE',
      label: 'German',
      icon: '/static/icons/ic_flag_de.svg',
      inputValue: 'deDE'
    }
  ],
  weightUnits: [
    {
      value: 'kg',
      label: 'kg',
    },
    {
      value: 'lb',
      label: 'lb',
    },
    {
      value: 'oz',
      label: 'oz',
    },
    {
      value: 'g',
      label: 'g',
    },
  ],
  statuses: [
    'draft',
    'active'
  ]
};

export const refreshSite = createAsyncThunk(
  "site/refresh",
  async (_, { dispatch }) => {
    dispatch(wipeout());
    window.location.reload();
  }
);

export const site = createSlice({
  name: 'site',
  initialState,
  reducers: {
    updateSite: {
      reducer: (state, action) => ({
        ...state, ...action.payload
      }),
      prepare: (data) => {
        if (data) {
          const { at, ...rest } = data;
          return {
            payload: {
              ...rest,
            }
          }
        }
        return {};
      }
    }
  },
});

export const { updateSite } = site.actions;

export const selectCategories = (state) => state.site.categories;

export const selectLanguages = (state) => state.site.languages;

export const selectWeightUnits = (state) => state.site?.weightUnits || [];

export const selectStatuses = (state) => state.site.statuses;

export const selectFulfillmentStatuses = (state) => state.site.fulfillmentStatuses;

export const selectstatuses = (state) => state.site.statuses;

export const selectPaymentStatuses = (state) => state.site.paymentStatuses;

export const selectTimeout = (state) => state?.site?.timeout ?? 1000;

export const selectIsLoadingTimeout = (state) => state?.site?.isLoadingTimeout ?? 5000;

export default site.reducer;
