import React, { useEffect, useCallback, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Vendor from "./Vendor.js";
import PickUpOptions from "./PickUpOptions.js";
import Page from "../../components/Page";
import { cartVendorsSelectors, confirmCheckout } from "../../store";

const initialState = {
  loading: false,
  isOrderSubmitted: false,
  dialog: undefined,
  activeStep: 0,
};

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING": {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case "SET_IS_ORDER_SUBMITTED": {
      return {
        ...state,
        isOrderSubmitted: false,
        loading: false,
      };
    }
    case "CONFIRM":
      return {
        ...state,
        isOrderSubmitted: true,
        // loading: true,
        dialog: undefined,
      };
    case "SET_DIALOG": {
      return {
        ...state,
        dialog: action.payload,
      };
    }
    case "SET_ACTIVE_STEP": {
      return {
        ...state,
        activeStep: action.payload,
      };
    }
    case "HANDLE_NEXT": {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }
    case "HANDLE_BACK": {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }
    default:
      return state;
  }
};

const Checkout = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vendors = useSelector(cartVendorsSelectors.selectAll);
  const [{ isOrderSubmitted, dialog, activeStep }, _dispatch] = useReducer(
    checkoutReducer,
    initialState
  );
  const getSteps = () => ["Delivery", "Payment", "Confirm"];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return vendors !== undefined && vendors.length > 0 ? (
          <Box p={3}>
            <Typography variant="h5">
              {intl.formatMessage({ id: "claim_instructions" })}
            </Typography>
            <List arial-label="cart list">
              {vendors.map((vendor, i) => (
                <PickUpOptions key={i} vendor={vendor} />
              ))}
            </List>
          </Box>
        ) : (
          <Box p={3}>
            <Typography>
              {intl.formatMessage({ id: "no_items_in_your_cart_yet" })}
            </Typography>
            <Button onClick={handleContinueShopping}>
              {intl.formatMessage({ id: "continue_shopping" })}
            </Button>
          </Box>
        );
      case 1:
        return (
          <Box p={3}>
            <Typography variant="h5">
              {intl.formatMessage({ id: "payment" })}
            </Typography>
            <Typography variant="body1">
              {intl.formatMessage({ id: "payment_options_disabled" })}
              <br />
              {intl.formatMessage({
                id: "please_make_arrangements_with_your_vendor",
              })}
              <br />
            </Typography>
          </Box>
        );
      case 2:
        return vendors !== undefined && vendors.length > 0 ? (
          <div>
            <Typography variant="h4">
              {intl.formatMessage({ id: "review" })}
            </Typography>
            <List arial-label="cart list">
              {vendors.map((vendor, i) => (
                <Vendor vendor={vendor} key={i} />
              ))}
            </List>
          </div>
        ) : (
          <div>
            <Typography>
              {intl.formatMessage({ id: "no_items_in_your_cart_yet" })}
            </Typography>
            <Button onClick={handleContinueShopping}>
              {intl.formatMessage({ id: "continue_shopping" })}
            </Button>
          </div>
        );
      default:
        return "Done";
    }
  };

  const steps = getSteps();

  const handleNext = useCallback(() => {
    _dispatch({ type: "HANDLE_NEXT" });
  }, []);

  const handleBack = useCallback(() => {
    _dispatch({ type: "HANDLE_BACK" });
  }, []);

  const handleContinueShopping = () => navigate("/");

  const handleSeeYourOrder = () => navigate("/purchases");

  useEffect(() => {
    if (isOrderSubmitted) {
      if (vendors === undefined || vendors.length === 0) {
        _dispatch({ type: "SET_IS_ORDER_SUBMITTED" });
        handleNext();
      }
    }
  }, [isOrderSubmitted, vendors, handleNext]);

  const handleConfirm = () => {
    _dispatch({ type: "CONFIRM" });
    dispatch(confirmCheckout());
  };

  return (
    <Page title={intl.formatMessage({ id: `checkout` })}>
      <Container maxWidth="md">
        <Stepper activeStep={activeStep} sx={{ margin: 0, padding: "0.5rem" }}>
          {steps.map((label) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box sx={{ textAlign: "center" }}>
          {activeStep === steps.length ? (
            <Box p={2}>
              <Typography>
                {intl.formatMessage({ id: `order_submitted` })}
              </Typography>
              <Button onClick={handleContinueShopping}>
                {intl.formatMessage({ id: `continue_shopping` })}
              </Button>
              <Button onClick={handleSeeYourOrder}>
                {intl.formatMessage({ id: `see_your_order` })}
              </Button>
            </Box>
          ) : (
            <Box>{getStepContent(activeStep)}</Box>
          )}
          <ButtonGroup>
            {activeStep === 0
              ? <Button onClick={() => navigate("/cart")}>
              {intl.formatMessage({ id: "back" })}
            </Button>
              :  <Button disabled={activeStep === 0} onClick={handleBack}>
              {intl.formatMessage({ id: "back" })}
            </Button>

            }
            
            {activeStep === steps.length - 1 ? (
              isOrderSubmitted || vendors.length < 1 ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    isOrderSubmitted ||
                    vendors === undefined ||
                    vendors.length < 1
                  }
                >
                  {intl.formatMessage({ id: "sent" })}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    _dispatch({ type: "SET_DIALOG", payload: "confirm_order" })
                  }
                  disabled={isOrderSubmitted || vendors === undefined}
                >
                  {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                </Button>
              )
            ) : (
              <>
                {activeStep < steps.length && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? "Confirm" : "Next"}
                  </Button>
                )}
              </>
            )}
          </ButtonGroup>
        </Box>
      </Container>
      <Dialog
        open={dialog === "confirm_order"}
        onClose={() => _dispatch({ type: "SET_DIALOG", undefined })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({ id: "checkout_dialog_title" })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({ id: "checkout_dialog_message" })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => _dispatch({ type: "SET_DIALOG", undefined })}
            color="primary"
          >
            {intl.formatMessage({ id: "decline" })}
          </Button>
          <Button onClick={() => handleConfirm()} color="primary">
            {intl.formatMessage({ id: "checkout" })}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};

export default Checkout;
