/* eslint-disable react/style-prop-object */
import { useEffect, useMemo, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import ClearIcon from "@mui/icons-material/Delete";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import plusFill from "@iconify/icons-eva/plus-fill";
import SaveIcon from "@mui/icons-material/Save";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Toolbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { collection, doc } from "firebase/firestore";
import { db } from "../../../apis/firebase";
import { deleteDocs, updateDoc } from "../../../store";
import { useIntl } from "react-intl";
import DataGridImageEdit from "../../DataGridImageEdit";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

function QuickSearchToolbar(props) {
  return (
    <Toolbar>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        mt={2}
        sx={{ width: '100%' }}
      >
        <Box>
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              startAdornment: <SearchIcon fontSize="small" />,
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{ visibility: props.value ? "visible" : "hidden" }}
                  onClick={props.clearSearch}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
          <IconButton aria-label="Save">
            <SaveIcon />
          </IconButton>
          <IconButton
            disabled={!props.isRowsSelected}
            aria-label="Delete"
            onClick={() => props.handleDeleteItems()}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
        <Box>
          <Button
            variant="contained"
            to="#"
            startIcon={<Icon icon={plusFill} />}
            onClick={props.handleAddNewItem}
          >
            {props.newItemLabel}
          </Button>
        </Box>
      </Stack>
    </Toolbar>
  );
}

QuickSearchToolbar.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  handleAddNewItem: PropTypes.func.isRequired,
  handleDeleteItems: PropTypes.func.isRequired,
  newItemLabel: PropTypes.string.isRequired,
  isRowsSelected: PropTypes.bool.isRequired,
};

export default function ItemsTable({
  items,
  docName,
  collectionName,
  vendorId,
  statuses,
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [rows, setRows] = useState(items);
  const [searchText, setSearchText] = useState("");
  const [isRowsSelected, setIsRowsSelected] = useState(false);

  useEffect(() => {
    setRows(items);
  }, [items]);

  useEffect(() => {
    setIsRowsSelected(Boolean(selectedRows.length > 0))
  }, [selectedRows]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };

  const handleOnEditRowsModelChange = useCallback((model) => {
    setSelectedRows(model);
  }, []);

  const handleCancelDialog = () => setDialogOpen(false);

  const handleOk = async () => {
    await dispatch(deleteDocs({collection: collectionName, docs: selectedItems}));
    setSelectedItems([]);
    setDialogOpen(false);    
  };

  const handleDeleteItems = useCallback(() => {
    setSelectedItems(selectedRows);
    setDialogOpen(true);    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const handleDeleteItem = useCallback((id) => {
    setDialogOpen(true);
    setSelectedItems([id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleDelete = useCallback(() => {
  //   setDialogOpen(true);
  //   setSelectedItems(selectedRows);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedRows]);

  const handleAddNewItem = useCallback(async () => {
    const docRef = doc(collection(db, collectionName));
    await dispatch(
      updateDoc({
        collection: collectionName,
        id: docRef.id,
        payload: {
          availableFrom: Date.now(),
          availableTo: Date.now()  + 31536000000,
          id: docRef.id,
          name: "",
          nameText: "",
          vendorId: vendorId,
          parentId: docRef.id,
          status: "draft",
        },
      })
    );
    navigate(`/vendors/${vendorId}/${collectionName}/${docRef.id}/edit`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateItem = useCallback((id, payload) => {
    dispatch(updateDoc({ collection: collectionName, id, payload }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: intl.formatMessage({ id: "name" }),
        flex: 1,
        // editable: true,
        valueFormater: ({ value }) =>
          value ? value : intl.formatMessage({ id: "no_name" }),
      },
      {
        field: `${docName}Photo`,
        headerName: intl.formatMessage({ id: "image" }),
        type: "string",
        renderCell: ({ id: variantId, value }) => {
          return (
            <DataGridImageEdit
              change={({ downloadURL, fileName }) =>
                updatePhoto({
                  id: variantId,
                  downloadURL,
                  fileName,
                })
              }
              disabled={!true}
              name={`${docName}Photo`}
              uid={variantId}
              initialized={true}
              intl={intl}
              path={collection}
              src={value}
              required
            />
          );
        },
      },
      {
        field: "price",
        headerName: intl.formatMessage({ id: "price" }),
        type: "number",
        // editable: true,
        valueFormatter: ({ value = 0 }) =>
          intl.formatNumber(value, {
            style: "currency",
            currency: "CAD",
          }),
      },
      {
        field: "available",
        headerName: intl.formatMessage({ id: "available" }),
        type: "number",
      },
      {
        field: "status",
        headerName: intl.formatMessage({ id: "status" }),
        width: 120,
        renderCell: ({ id, value }) => {
          return (
            <FormControl>
              <Select
                name="status"
                labelId="status-label"
                id="demo-simple-select-placeholder-label"
                value={value || "draft"}
                sx={{
                  "& .MuiInputBase-input": { padding: "10px 26px 10px 12px" },
                }}
                onChange={(e) =>
                  handleUpdateItem(id, { [e.target.name]: e.target.value })
                }
              >
                {statuses.map((status, i) => (
                  <MenuItem key={i} value={status}>
                    {intl.formatMessage({ id: status })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: intl.formatMessage({ id: "actions" }),
        cellClassName: "actions",
        flex: 1,
        getActions: ({ id, row }) => {
          if (selectedRows.includes(id)) {
            return [
              // <IconButton aria-label="Save">
              //   <SaveIcon />
              // </IconButton>,
              <IconButton
                aria-label="Edit"
                onClick={() => navigate(`/vendors/${vendorId}/${collectionName}/${id}/edit`)}
              >
                <EditIcon />
              </IconButton>,
              <IconButton
                aria-label="Delete"
                onClick={() => handleDeleteItem(id)}
              >
                <DeleteIcon />
              </IconButton>,
            ];
          }

          return [
            <IconButton
              aria-label="Edit"
              onClick={() => navigate(`/vendors/${vendorId}/${collectionName}/${id}/edit`)}
            >
              <EditIcon />
            </IconButton>,
            <IconButton
              aria-label="Delete"
              onClick={() => handleDeleteItem(id)}
            >
              <DeleteIcon />
            </IconButton>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRows]
  );

  const updatePhoto = (photo) => {
    const newRows = rows.map((row) =>
      photo.id === row.id
        ? {
            ...row,
            [`${docName}Photo`]: photo.downloadURL,
            [`${docName}PhotoId`]: photo.fileName,
          }
        : row
    );
    setRows(newRows);
  };

  return (
    <>
      <DataGrid
        autoHeight
        checkboxSelection
        columns={columns}
        slotProps={{
          toolbar: {
            isRowsSelected: isRowsSelected,
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
            handleAddNewItem: () => handleAddNewItem(),
            handleDeleteItems: () => handleDeleteItems(),
            newItemLabel: intl.formatMessage({ id: "new" }),            
          },
        }}
        slots={{ toolbar: QuickSearchToolbar }}
        // loading
        name={collectionName}
        onRowSelectionModelChange={handleOnEditRowsModelChange}
        rows={rows}
      />
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        open={dialogOpen}
      >
        <DialogTitle>{intl.formatMessage({ id: "delete" })}</DialogTitle>
        <DialogContent>
          {intl.formatMessage({ id: "are_you_sure" })}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancelDialog}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
