/* eslint-disable react/style-prop-object */
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BlockIcon from '@mui/icons-material/Block';
import SvgIconStyle from "../../SvgIconStyle";
import AddToCart from "../../AddToCart";
import {
  optionSelected,
  productsSelectors,
  selectUserLocaleValue,
} from "../../../store";
import ImageView from "../../../utils/ImageViewer";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardMediaStyle = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const UnavailableImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
  opacity: 0.5,
  position: "absolute",
});

const IconStyle = styled(WorkspacePremiumIcon)(({ theme }) => ({
  color: "gold",
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  right: theme.spacing(3),
  top: theme.spacing(3),
}));

const UnavailableIconStyle = styled(BlockIcon)(({ theme }) => ({
  color: "red",
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
}));

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
};

export default function ProductCard({ product }) {
  const intl = useIntl();
  const locale = useSelector(selectUserLocaleValue);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    categories = [],
    childOptions,
    description = "",
    id: productId,
    reserve,
    vendorName,
    vendorPhoto,
    selectedVariantId,
    tags = [],
  } = product;

  let vid = selectedVariantId;

  if (!vid) {
    vid = productId;
  }

  const variant = useSelector((state) =>
    productsSelectors.selectById(state, vid)
  );

  console.log({ productId, product, selectedVariantId, vid, variant });

  const {
    name,
    parentName,
    productPhoto,
  } = variant || {
    name: intl.formatMessage({ id: "unavailable" }),
    ...product,
  };

  console.log({ name, selectedVariantId, variant })

  const handleChange = (e, id, productId, optionId) =>
    dispatch(
      optionSelected({
        name: e.target.name,
        value: e.target.value,
        id,
        productId,
        optionId,
      })
    );

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: "absolute",
          }}
        />
        <AvatarStyle alt={vendorName} src={vendorPhoto} />
        {selectedVariantId !== undefined && variant !== undefined && (
          <ImageView alt={name} src={productPhoto || vendorPhoto} />
        )}
        {(selectedVariantId === undefined || variant === undefined) && (
          <UnavailableImgStyle alt={name} src={productPhoto || vendorPhoto} />
        )}
        {reserve && (
          <Tooltip title={intl.formatMessage({ id: "from_membership" })}>
            <IconStyle
            />
          </Tooltip>
        )}
        {!variant && (
          <Tooltip title={intl.formatMessage({ id: "unavailable" })}>
            <UnavailableIconStyle
            />
          </Tooltip>
        )}
      </CardMediaStyle>
      <CardContent
        sx={{
          pt: 4,
          pb: 0,
        }}
      >
        <Typography
          gutterBottom
          variant="body1"
          sx={{ color: "text.disabled", display: "block" }}
        >
          {/* {fDate(updated)} */}
          {vendorName}
        </Typography>
        <Typography variant="h5">{parentName} </Typography>
        <Typography variant="h4">{name}</Typography>
      </CardContent>
      {selectedVariantId !== undefined && variant !== undefined && (
        <CardActions spacing={2} sx={{ p: 2 }}>
          {(Object.keys(childOptions?.entities).length > 0 ||
            description !== "" ||
            categories.length > 0 || tags.length > 0) && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
          <AddToCart
            item={{
              ...variant,
              itemPhoto: productPhoto || vendorPhoto,
            }}
            locale={locale}
          />
        </CardActions>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography gutterBottom variant="body2">
            {description}
          </Typography>
          {categories.map((category, i) => (
            <Chip key={i} label={intl.formatMessage({ id: category })} />
          ))}
          {tags.map((tag, i) => (
            <Chip key={i} label={tag} />
          ))}
          {childOptions?.entities && (
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {Object.entries(childOptions.entities).map(
                (
                  [
                    id,
                    {
                      key,
                      optionValues = [],
                      optionId,
                      selected = optionValues[0],
                    },
                  ],
                  i
                ) => (
                  <Grid item key={i}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {key}
                      </InputLabel>
                      <Select
                        autoWidth
                        label={key}
                        name={key}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // renderValue={() => product.data.options[optionHeading.title].values[optionIndices[i].value]}
                        value={selected}
                        onChange={(e) =>
                          handleChange(e, id, productId, optionId)
                        }
                        disabled={optionValues.length === 1}
                      >
                        {optionValues.map((value, j) => (
                          <MenuItem key={j} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
              )}
            </Grid>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
