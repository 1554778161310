import { createSlice } from '@reduxjs/toolkit';
import { logout } from './index';

const initialState = {
  categories: [],
  searchText: "",
  vendors: [],
}

export const filters = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateCategories: (state, action) => ({
      ...state,
      categories: action.payload,
    }),
    updateFilters: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    updateSearchText: (state, action) => ({
      ...state,
      searchText: action.payload,
    }),
    updateSort: (state, action) => ({
      ...state,
      sortIndex: action.payload,
    }),
    updateVendors: (state, action) => ({
      ...state,
      vendors: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.fulfilled, (state) => {
        state = initialState;
      })
  },
});

export const { 
  updateCategories,
  updateVendors,
  updateFilters,
  updateSearchText,
} = filters.actions;

export const selectSearchText = state => state?.filters?.searchText ?? "";

export const selectSelectedCategories = state => state?.filters?.categories ?? [];

export const selectSortIndex = state => state?.filters?.sortIndex ?? 0;

export const selectSelectedVendors = state => state?.filters?.vendors ?? [];

export default filters.reducer;
