import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Skeleton,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import usePlacesAutocomplete, {
  getDetails,
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  CircleF,
  GoogleMap,
  InfoWindowF,
  useLoadScript,
  MarkerF,
  useGoogleMap,
} from "@react-google-maps/api";
import { selectAutocomplete, setAutocomplete } from "../../../store";

const containerStyle = {
  height: "100%",
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function MapContainer({
  address,
  formatted_address = "",
  libraries,
  range,
  onAddressUpdate,
  onRangeUpdate,
  vendors,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_KEY
        : process.env.REACT_APP_PROD_API_KEY,
    libraries,
  });

  if (!isLoaded && address !== undefined) return <div>Loading...</div>;
  const selectedAddress = address
    ? { lat: address.lat, lng: address.lng }
    : null;
  return (
    <Map
      address={selectedAddress}
      libraries={libraries}
      range={range}
      onAddressUpdate={onAddressUpdate}
      onRangeUpdate={onRangeUpdate}
      formatted_address={formatted_address}
      vendors={vendors}
    />
  );
}

function CustomCircle({ center, radius }) {
  const map = useGoogleMap();

  useEffect(() => {
    if (map && center) {
      const circleBounds = new window.google.maps.Circle({
        center,
        radius,
      }).getBounds();

      map.fitBounds(circleBounds);
    }
  }, [map, center, radius]);

  const circleOptions = {
    strokeColor: "#FF0000",
    strokeOpacity: 0.5,
    strokeWeight: 1,
    fillColor: "#FF0000",
    fillOpacity: 0.2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius,
    zIndex: 1,
  };

  return <CircleF center={center} radius={radius} options={circleOptions} />;
}

function Map({
  address,
  libraries,
  range,
  onAddressUpdate,
  onRangeUpdate,
  formatted_address = "",
  vendors = {},
}) {
  // console.log({ address, vendors })
  const intl = useIntl();
  const [selected, setSelected] = useState(address);
  const prevAddress = usePrevious(address);

  useEffect(() => {
    // Ensure that address, lat and lng are defined and lat/lng are numbers
    if (
      address &&
      typeof address.lat === "number" &&
      typeof address.lng === "number"
    ) {
      // Only update if the address has changed
      if (
        !prevAddress ||
        JSON.stringify(address) !== JSON.stringify(prevAddress)
      ) {
        setSelected(address);
      }
    }
  }, [address, prevAddress]);

  const mapRef = useRef();

  // useCallback will return a memoized version of the callback that only changes if one of the dependencies has changed.
  const setLocation = useCallback(
    ({ lat, lng }) => {
      setSelected({ lat, lng });
      onAddressUpdate({ lat, lng });
    },
    [onAddressUpdate]
  );

  useEffect(() => {
    setSelected(address);
  }, [address]);

  const { lat, lng } = selected || {};

  const radiusInMeters = range * 1000;

  const [selectedVendor, setSelectedVendor] = useState();

  return (
    <Stack spacing={1}>
      <div className="places-container">
        <PlacesAutocomplete
          libraries={libraries}
          setSelected={setLocation}
          onAddressUpdate={onAddressUpdate}
          formatted_address={formatted_address}
        />
      </div>
      <Box sx={{ height: 300 }}>
        {lat !== undefined && lng !== undefined ? (
          <GoogleMap
            zoom={10}
            center={{ lat, lng }}
            mapContainerStyle={containerStyle}
            onLoad={(map) => {
              mapRef.current = map; // continue to set mapRef as before
            }}
          >
            <CustomCircle center={{ lat, lng }} radius={radiusInMeters} />
            <MarkerF position={{ lat, lng }} />
            {Object.values(vendors).map((vendor, i) =>
              // Again, ensure vendor latitude and longitude are valid numbers
              typeof vendor.latitude === "number" &&
              typeof vendor.longitude === "number" ? (
                <MarkerF
                  key={i}
                  position={{ lat: vendor.latitude, lng: vendor.longitude }}
                  onClick={() => {
                    setSelectedVendor(vendor);
                  }}
                >
                  {selectedVendor === vendor ? (
                    <InfoWindowF
                      onCloseClick={() => {
                        setSelectedVendor(null);
                      }}
                    >
                      <div>
                        <h2>{vendor.vendorName}</h2>
                        {vendor.vendorPhoto && (
                          <img
                            src={vendor.vendorPhoto}
                            alt={vendor.vendorName}
                          />
                        )}
                        <p>{vendor.description}</p>
                      </div>
                    </InfoWindowF>
                  ) : null}
                </MarkerF>
              ) : null
            )}
          </GoogleMap>
        ) : (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: "100%" }}
          />
        )}
      </Box>
      <Typography gutterBottom>
        {intl.formatMessage({ id: "range" })}
      </Typography>
      <Slider
        name="range"
        defaultValue={25}
        value={range ?? 25}
        valueLabelDisplay="on"
        onChange={(e) => onRangeUpdate(e.target.value)}
      />
    </Stack>
  );
}

const PlacesAutocompleteHook = ({
  setSelected,
  onAddressUpdate,
  formatted_address,
}) => {
  const intl = useIntl();
  const autocomplete = useSelector(selectAutocomplete);
  const dispatch = useDispatch();
  const prevFormattedAddress = usePrevious(formatted_address);

  const {
    ready,
    suggestions: { status, data = [] },
    setValue: setAutocompleteValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "CA" },
    },
    debounce: 300,
  });

  // This function will both update the local autocomplete value, and the redux state
  const setValue = useCallback(
    (value, shouldFetch = true) => {
      dispatch(setAutocomplete(value));
      setAutocompleteValue(value, shouldFetch);
    },
    [dispatch, setAutocompleteValue]
  );

  useEffect(() => {
    if (formatted_address && formatted_address !== prevFormattedAddress) {
      setValue(formatted_address);
    }
  }, [formatted_address, setValue, prevFormattedAddress]);

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    if (address) {
      const results = await getGeocode({ address });
      if (results.length > 0) {
        const { lat, lng } = await getLatLng(results[0]);
        const placeId = results[0].place_id;
        const details = await getDetails({ placeId });
        if (lat && lng) {
          setSelected({ lat, lng });
          console.log({ details });
          onAddressUpdate({
            lat,
            lng,
            ...details,
          });
        }
      }
    }
  };

  const isOptionEqualToValue = (option, value) =>
    option.trim().toLowerCase().includes(value.trim().toLowerCase());

  return (
    <Autocomplete
      options={status === "OK" ? data.map((place) => place.description) : []}
      getOptionLabel={(option) => option}
      value={autocomplete}
      onInputChange={(event, newValue) => setValue(newValue)}
      onChange={(event, newValue) => handleSelect(newValue)}
      disabled={!ready}
      isOptionEqualToValue={isOptionEqualToValue} // Custom equality test
      renderInput={(params) => (
        <TextField {...params} autoComplete="off" label={intl.formatMessage({ id: "search_an_address" })} variant="outlined" />
      )}
    />
  );
};

const PlacesAutocomplete = ({
  setSelected,
  onAddressUpdate,
  formatted_address,
  libraries,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_DEV_API_KEY
        : process.env.REACT_APP_PROD_API_KEY,
    libraries,
  });

  if (!isLoaded) return null;
  if (loadError) return "Error loading maps";

  return (
    <PlacesAutocompleteHook
      setSelected={setSelected}
      onAddressUpdate={onAddressUpdate}
      formatted_address={formatted_address}
    />
  );
};
