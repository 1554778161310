import { PURGE } from "redux-persist";
import { createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { logout, refreshSite } from './index';
import * as api from '../apis/firebase';

const salesChannelsAdapter = createEntityAdapter()

export const deletesalesChannel = createAsyncThunk(
  'salesChannels/delete',
  async (id) => await api.removeDoc('salesChannels', id));

export const updatesalesChannel = createAsyncThunk(
  'salesChannels/update',
  async ({ id, payload }) => await api.updateDoc('salesChannels', id, payload));

export const salesChannels = createSlice({
  name: 'salesChannels',
  initialState: salesChannelsAdapter.getInitialState(),
  reducers: {
    upsertOneSalesChannel: {
      reducer: (state, action) => salesChannelsAdapter.upsertOne(state, action.payload),
      prepare: (data) => {
        const {at, updated, ...rest} = data;
        if (updated !== undefined) {
          return {
            payload: {              
              ...rest,
              updated: api.toDate(updated),
            }
          }
        }
        const d = new Date();
        return {
          payload: {
            ...rest,
            updated: d.toLocaleString()
          }
        }
      }
    },
    removeOneSalesChannel: salesChannelsAdapter.removeOne,
    removeAllSalesChannel: salesChannelsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        salesChannelsAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        salesChannelsAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        salesChannelsAdapter.removeAll(state);
      })
  },
});

export const { 
  upsertOneSalesChannel,
  removeOneSalesChannel,
  removeAllSalesChannel
} = salesChannels.actions;

export const salesChannelsSelectors = salesChannelsAdapter.getSelectors((state) => state.salesChannels);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = salesChannelsSelectors;

export const selectsalesChannels = state => state.salesChannels;

export default salesChannels.reducer;
