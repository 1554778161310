import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, Stack } from "@mui/material";
import Page from "../components/Page";

export default function Home() {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <Page title="Locazoa">
      <Container maxWidth="lg">
        <Stack sx={{ alignItems: 'center' }}>
          <Box
            component="img"
            src="/static/krista-lee-logo-early-access.svg"
            sx={{ paddingTop: 3, marginBottom: 10, width: "100%" }}
          />
          <Button onClick={() => navigate("/login")} sx={{ width: 100 }} variant="outlined">
            {intl.formatMessage({ id: "sign_in" })}
          </Button>
        </Stack>
      </Container>
    </Page>
  );
}
