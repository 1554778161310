import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

function formatTimestamp(timestamp) {
  const date = timestamp instanceof Date ? timestamp : new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  }).format(date);
}

export default function MobileView({ purchases = [] }) {
  const navigate = useNavigate();
  
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {purchases.map((purchase, i) => (
        <ListItem key={i} onClick={() => navigate(`/purchases/${purchase.orderId}`)}>
          <ListItemAvatar>
            <Avatar>
              <Avatar alt={purchase.vendorName} src={purchase.vendorPhoto} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={purchase.vendorName} secondary={formatTimestamp(purchase.created)} />
        </ListItem>
      ))}
    </List>
  );
}
