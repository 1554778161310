import { Icon } from "@iconify/react";
import { useState } from "react";
import chevronUpFill from "@iconify/icons-eva/chevron-up-fill";
import chevronDownFill from "@iconify/icons-eva/chevron-down-fill";
import { useIntl } from "react-intl";
import { Menu, Button, MenuItem, Typography } from "@mui/material";

const SORT_BY_OPTIONS = ["newest", "price_desc", "price_asc"];

export default function ShopProductSort({
  handleMenuItemClick,
  selectedIndex = 0,
}) {
  const intl = useIntl();
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Icon icon={open ? chevronUpFill : chevronDownFill} />}
      >
        {intl.formatMessage({ id: "sort_by" })}:&nbsp;
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ color: "text.secondary" }}
        >
          {intl.formatMessage({ id: SORT_BY_OPTIONS[selectedIndex] })}

        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {SORT_BY_OPTIONS.map((option, index) => (
          <MenuItem
            key={index}
            selected={selectedIndex === index}
            onClick={() => {
              handleMenuItemClick(index);
              setOpen(null);
            }}
            sx={{ typography: "body2" }}
          >
            {intl.formatMessage({ id: option })}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
