import { useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from '../../Page';
import { ProductForm } from '../product';
import { productsSelectors } from '../../../store';
import { useIntl } from 'react-intl';

export default function Product() {
  const { id } = useParams();
  const intl = useIntl();
  const product = useSelector(state => productsSelectors.selectById(state, id));
  return (
    <Page title={(id && product && product.id && product.name) || intl.formatMessage({ id: 'product' })}>
      {product !== undefined ? <ProductForm product={product} />
        :<Navigate to="/404" />
      }      
    </Page>
  );
}