import { PURGE } from "redux-persist";
import {
  createSelector,
  createSlice,
  createEntityAdapter,
  // nanoid,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { logout, refreshSite, setSnackbar } from "./index";
import * as api from "../apis/firebase";

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async ({ item, quantity = 1 }, { getState, requestId }) => {
    if (!item) return;
    const { currentRequestId, loading } = getState().cart;
    if (loading !== 'pending' || requestId !== currentRequestId) {
      return;
    }
    
    // Create a new Promise that rejects after a delay
    const timeoutPromise = new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), 15000) // 15 seconds
    );

    // Create a new Promise for the API call
    const apiPromise = api.addToCart({ item, quantity });

    // Use Promise.race to complete when the first Promise resolves or rejects
    const response = await Promise.race([apiPromise, timeoutPromise]);

    // If the timeoutPromise completed first, the code will never reach this point because an error will be thrown

    return {
      ...response.data,
      item,
      quantity,
      isLoading: 'cart'
    };
  }
);


export const confirmCheckout = createAsyncThunk(
  'cart/confirmCheckout',
  async (_, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const response = await api.checkout();
    return response;
  }
);

const linesAdapter = createEntityAdapter();

const vendorsAdapter = createEntityAdapter();

const itemLoadingAdapter = createEntityAdapter();

export const linesSelectors = linesAdapter.getSelectors(
  (state) => state.cart.lines
);
export const cartVendorsSelectors = vendorsAdapter.getSelectors(
  (state) => state.cart.vendors
);
export const itemLoadingSelectors = itemLoadingAdapter.getSelectors(
  (state) => state.cart.itemLoadings
);

export const cart = createSlice({
  name: "cart",
  initialState: {    
    currentRequestId: undefined,
    error: null,
    itemLoadings: itemLoadingAdapter.getInitialState(),
    lines: linesAdapter.getInitialState(),
    loading: 'idle',
    vendors: vendorsAdapter.getInitialState(),    
  },
  reducers: {
    upsertOneLine: {
      reducer: (state, action) => {
        linesAdapter.upsertOne(state.lines, action.payload);
        if (state.itemLoadings) {
          itemLoadingAdapter.removeOne(state.itemLoadings, action.payload.id);
        }
      },
      prepare: (data) => {        
        const { at, geoPoint, updated, created, ...rest } = data;
        return {
          payload: {
            ...rest
          }
        }
      }
    },
    upsertOneCartVendor: {
      reducer: (state, action) => {
        vendorsAdapter.upsertOne(state.vendors, action.payload);
      },
      prepare: (data) => {        
        const { at, updated, created, ...rest } = data;
        return {
          payload: {
            ...rest,
          }
        }
      }
    },
    removeOneLine(state, { payload }) {
      linesAdapter.removeOne(state.lines, payload);
    },
    removeOneCartVendor(state, { payload }) {
      vendorsAdapter.removeOne(state.vendors, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(confirmCheckout.fulfilled, (state, action) => {        
        console.log({ action });
      })
      .addCase(PURGE, (state) => {        
        linesAdapter.removeAll(state.lines);
        vendorsAdapter.removeAll(state.vendors);
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
        state.itemLoadings = itemLoadingAdapter.getInitialState();
      })
      .addCase(refreshSite.fulfilled, (state) => {
        linesAdapter.removeAll(state.lines);
        vendorsAdapter.removeAll(state.vendors);
        state.itemLoadings = itemLoadingAdapter.getInitialState();
      })
      .addCase(logout.fulfilled, (state) => {
        linesAdapter.removeAll(state.lines);
        vendorsAdapter.removeAll(state.vendors);
        state.loading = 'idle';
        state.currentRequestId = undefined;
        state.error = null;
        state.itemLoadings = itemLoadingAdapter.getInitialState();
      })
      .addCase(addToCart.pending, (state, action) => {
        if (state.loading === 'idle') {
          state.loading = 'pending';
          state.currentRequestId = action.meta.requestId;
          const newId = action?.meta?.arg?.item?.id;
          if (newId) {
            if (!state.itemLoadings) {
              state.itemLoadings = itemLoadingAdapter.getInitialState();
            }
            itemLoadingAdapter.addOne(state.itemLoadings, { id: newId, isLoading: true });
          }
        }
      })

      .addCase(addToCart.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.currentRequestId = undefined;

          const { item } = action.payload;
          if (item) {
            itemLoadingAdapter.removeOne(state.itemLoadings, item.id);
          }
        }
      })

      .addCase(addToCart.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.loading === 'pending' && state.currentRequestId === requestId) {
          state.loading = 'idle';
          state.error = action.error;
          state.currentRequestId = undefined;
          
          const { item = {} } = action?.payload || {};
          console.log({ item, action, payload: action.payload, })
          const id = item?.id || action?.meta?.arg?.item?.id;
          if (id) {
            itemLoadingAdapter.removeOne(state.itemLoadings, id);
          } else {
            console.log({ payload: action.payload})
            state.itemLoadings = itemLoadingAdapter.getInitialState();
          }
        }
      });
  },
});

export const { selectIds, selectById, selectTotal, selectEntities, selectAll } =
  linesSelectors;

export const selectCount = createSelector(linesSelectors.selectAll, items =>
    items.reduce((count, item) => count + item.quantity, 0)
  )

export const selectLinesByVendorId = createSelector(
    [
      // Usual first input - extract value from `state`
      state => linesSelectors.selectAll(state),
      // Take the second arg, `category`, and forward to the output selector
      (state, vendorId) => vendorId
    ],
    // Output selector gets (`items, category)` as args
    (items, vendorId) => items.filter(item => item.vendorId === vendorId)
  )

// export const selectForms = (state) => state.forms.products;
export const selectLine = (state, product) =>
  linesSelectors.selectById(state, product.id) ?
  linesSelectors.selectById(state, product.id) :
  product;

export const {
  removeOneLine,
  removeOneCartVendor,
  upsertOneLine,
  upsertOneCartVendor,
} = cart.actions;

export const selectIsCartLoading = (state) => state.cart.loading === 'pending';

export const selectItemLoading = (itemId) => createSelector(
  (state) => state.cart?.itemLoadings?.entities ?? {},
  (itemLoadings) => {
    if (itemLoadings) {
      const loadingItem = itemLoadings[itemId];
      return loadingItem ? loadingItem.isLoading : false;
    }
    return false;
  }
);

export const selectAnyItemLoading = (state) => (state.cart?.itemLoadings?.ids || []).length > 0;

export default cart.reducer;
