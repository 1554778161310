import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import shoppingCartFill from "@iconify/icons-eva/shopping-cart-fill";
import { useSelector, shallowEqual } from "react-redux";
import { styled } from "@mui/material/styles";
import {Badge, Box} from "@mui/material";
import { selectCount } from "../../../store";

const RootStyle = styled("div")(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: "flex",
  cursor: "pointer",
  position: "fixed",
  alignItems: "center",
  top: theme.spacing(16),
  height: theme.spacing(5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  borderTopLeftRadius: theme.shape.borderRadiusMd,
  borderBottomLeftRadius: theme.shape.borderRadiusMd,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

export default function CartWidget() {
  const count = useSelector(selectCount, shallowEqual);
  const navigate = useNavigate();
  return (
    <Box onClick={() => navigate("/cart")}>
      <RootStyle>
        <Badge showZero badgeContent={count} color="error" max={99}>
          <Icon icon={shoppingCartFill} width={24} height={24} />
        </Badge>
      </RootStyle>
    </Box>
  );
}
