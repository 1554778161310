import PropTypes from 'prop-types';
import {
  useEffect,
  useState,
} from "react";
import { useIntl } from 'react-intl';
import { Icon } from '@iconify/react';
import { Form, FormikProvider } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import closeFill from '@iconify/icons-eva/close-fill';
import roundClearAll from '@iconify/icons-ic/round-clear-all';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import {
  Box,
  Grid,
  // Radio,
  Stack,
  Button,
  Drawer,
 // Rating,
  Divider,
  Checkbox,
  FormGroup,
  IconButton,
  Typography,
  FormControlLabel
} from '@mui/material';
import Scrollbar from '../../Scrollbar';
import {
  locationsSelectors,
  getVendorsByLatLngRadiusInM,
  selectCategories,
  selectUserId,
  updateFilters,
  updateLocation,
  updateUser,  
  selectVendors,
} from '../../../store';
import MapContainer from "../../../components/forms/ProfileForm/MapContainer";
export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' }
];
export const FILTER_GENDER_OPTIONS = ['Men', 'Women', 'Kids'];
export const FILTER_CATEGORY_OPTIONS = ['All', 'Shose', 'Apparel', 'Accessories'];
export const FILTER_RATING_OPTIONS = ['up4Star', 'up3Star', 'up2Star', 'up1Star'];
export const FILTER_PRICE_OPTIONS = [
  { value: 'below', label: 'Below $25' },
  { value: 'between', label: 'Between $25 - $75' },
  { value: 'above', label: 'Above $75' }
];
export const FILTER_COLOR_OPTIONS = [
  '#00AB55',
  '#000000',
  '#FFFFFF',
  '#FFC0CB',
  '#FF4842',
  '#1890FF',
  '#94D82D',
  '#FFC107'
];

const NORTHPOLE = {
  longitude: 0.0,
  latitude: 90.0,
};

ShopFilterSidebar.propTypes = {
  isOpenFilter: PropTypes.bool,
  onResetFilter: PropTypes.func,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  formik: PropTypes.object
};

const libraries = process.env.REACT_APP_GOOGLE_MAPS_LIBRARIES.split(",");

export default function ShopFilterSidebar({
  isOpenFilter,
  onResetFilter,
  onOpenFilter,
  onCloseFilter,
  formik
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const { 
    values,
    getFieldProps,
    // handleChange 
  } = formik;

  useEffect(() => {
    dispatch(updateFilters(values))
  }, [dispatch, values]);
  
  const vendors = useSelector(selectVendors);

  const userId = useSelector(selectUserId);
  const address = useSelector((state) =>
    locationsSelectors.selectById(state, userId)
  );


  const [newAddress, setNewAddress] = useState(address);

  useEffect(() => {
    if (address) {
      setNewAddress(address);
    }
  }, [address]);

  const handleAddressUpdate =  async (updatedAddress) => {
    console.log({ here: updatedAddress })
    setNewAddress(updatedAddress);
    const {
      geometry,
      ...restAddress
    } = newAddress;
    await dispatch(
      updateLocation({
        id: userId,
        payload: {
          ownerId: userId,
          latitude: newAddress.lat ?? NORTHPOLE.latitude,
          longitude: newAddress.lng ?? NORTHPOLE.longitude,
          location: newAddress.formatted_address ?? "",
          ...restAddress,
          range: values?.range ? values.range : 25,
        },
      })
    );
    console.log({ lat: newAddress.lat, lng: newAddress.lng, range: values.range })
    await dispatch(
      getVendorsByLatLngRadiusInM({
        lat: newAddress.lat ?? NORTHPOLE.latitude,
        lng: newAddress.lng ?? NORTHPOLE.longitude,
        radiusInM: values?.range ? parseInt(values.range) * 1000 : 25000,
      })
    );
  };

  const handleRangeUpdate = (range) => {
    dispatch(updateUser({ id: userId, payload: { range: range ?? 25 } }));
    dispatch(
      updateLocation({
        id: userId,
        payload: {
          range: range ?? 25,
        },
      })
    );
  }
  

  return (
    <>
      <Grid>
        <Button
          disableRipple
          color="inherit"
          endIcon={<Icon icon={roundFilterList} />}
          onClick={onOpenFilter}
        >
          {intl.formatMessage({ id: 'filters' })}&nbsp;
        </Button>
      </Grid>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <Drawer
            anchor="right"
            open={isOpenFilter}
            onClose={onCloseFilter}
            PaperProps={{
              sx: { width: 280, border: 'none', overflow: 'hidden' }
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ px: 1, py: 2 }}
            >              
              <Typography variant="subtitle1" sx={{ ml: 1 }}>
                {intl.formatMessage({ id: 'filters' })}
              </Typography>
              <IconButton onClick={onCloseFilter}>
                <Icon icon={closeFill} width={20} height={20} />
              </IconButton>
            </Stack>

            <Divider />

            <Scrollbar>
              <Stack spacing={3} sx={{ p: 3 }}>
                <div>
                  <Box sx={{ height: 450, mb: 2 }} >
                    <MapContainer
                      address={newAddress}
                      formatted_address={newAddress?.formatted_address ?? ""}
                      libraries={libraries}
                      range={newAddress?.range ?? 25}
                      onAddressUpdate={handleAddressUpdate}
                      onRangeUpdate={handleRangeUpdate}
                      vendors={vendors}
                    />
                  </Box>                  
                  <Typography variant="subtitle1" gutterBottom>
                    {intl.formatMessage({ id: 'categories' })}
                  </Typography>
                  <FormGroup>
                    {categories.map((item) => (
                      <FormControlLabel
                        key={item}
                        control={
                          <Checkbox
                            {...getFieldProps('categories')}
                            value={item}
                            checked={values.categories.includes(item)}
                          />
                        }
                        label={intl.formatMessage({ id: item })}
                      />
                    ))}
                  </FormGroup>
                </div>
              </Stack>
            </Scrollbar>

            <Box sx={{ p: 3 }}>
              <Button
                fullWidth
                size="large"
                type="submit"
                color="inherit"
                variant="outlined"
                onClick={onResetFilter}
                startIcon={<Icon icon={roundClearAll} />}
              >
                Clear All
              </Button>
            </Box>
          </Drawer>
        </Form>
      </FormikProvider>
    </>
  );
}
