import { useState } from "react";
import { Icon } from "@iconify/react";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import googleFill from "@iconify/icons-eva/google-fill";
// import twitterFill from '@iconify/icons-eva/twitter-fill';
import EmailIcon from "@mui/icons-material/Email";
// import facebookFill from "@iconify/icons-eva/facebook-fill";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  forgotPassword,
  signInWithEmailAndPassword,
  // signInWithFacebook,
  signInWithGoogle,
} from "../../store";
import { useDispatch } from "react-redux";

export default function AuthSocial() {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleRedirectToOrBack() {
    const previousPath = location.pathname;
    console.log({ previousPath });
    const isFromInvite = previousPath.startsWith("/invitations/");
    
    if (isFromInvite) {
      // If the previous page was an invite page, redirect back there
      console.log({ isFromInvite: previousPath });
      navigate(previousPath, { replace: true });
    } else {
      // Otherwise, redirect to the profile page
      navigate("/profile", { replace: true });
    }
  }

// const fromInvite = location.state && location.state.from;

// // Dispatch the action to store the invite URL
// if (fromInvite) {
//   dispatch(storeInvitationUrl(fromInvite));
// }

  const EmailPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email")
      .required(intl.formatMessage({ id: "required" })),
    password: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const formik = useFormik({
    initialValues: {
      email: null,
      password: null,
    },
    onSubmit: (values) =>
      dispatch(signInWithEmailAndPassword(values))
        .then((user) => {
          handleRedirectToOrBack();
        })
        .catch((e) => {
          console.log({ e })
        }),
    validationSchema: EmailPasswordSchema,
  });

  const { errors, handleChange, handleSubmit, touched, values } = formik;

  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleForgotPassword = async () => {
    const response = dispatch(forgotPassword(values.email));
    console.log({ response })
  };

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Button
          fullWidth
          color="inherit"
          onClick={() =>
            dispatch(signInWithGoogle())
              .then((user) => {
                handleRedirectToOrBack();
              })
              .catch((e) => console.log(e.message))
          }
          size="large"
          startIcon={<Icon icon={googleFill} color="#DF3E30" height={24} />}
          variant="outlined"
        >
          {intl.formatMessage({ id: "sign_in_with_google" })}
        </Button>
        {/* <Button
          fullWidth
          color="inherit"
          onClick={() =>
            dispatch(signInWithFacebook())
              .then((user) => {
                handleRedirectToOrBack();
              })
              .catch((e) => console.log(e.message))
          }
          size="large"
          startIcon={<Icon icon={facebookFill} color="#1877F2" height={24} />}
          variant="outlined"
        >
          {intl.formatMessage({ id: "sign_in_with_facebook" })}
        </Button> */}

        <Button
          fullWidth
          color="inherit"
          onClick={handleClickOpen}
          size="large"
          startIcon={<EmailIcon />}
          variant="outlined"
          sx={{ textTransform: "capitalize" }}
        >
          {intl.formatMessage({ id: "sign_in_with_email" })}
        </Button>

        {/* <Button fullWidth size="large" color="inherit" variant="outlined">
          <Icon icon={twitterFill} color="#1C9CEA" height={24} />
        </Button> */}
      </Stack>

      {/* <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          OR
        </Typography>
      </Divider> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {intl.formatMessage({ id: "sign_in_with_email" })}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form autoComplete="on" onSubmit={handleSubmit}>
              <TextField
                autoFocus
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                id="email"
                label={intl.formatMessage({ id: "email" })}
                margin="dense"
                name="email"
                onChange={handleChange}
                required
                type="email"
              />
              <TextField
                autoFocus
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={
                  touched.password && errors.password ? (
                    errors.password
                  ) : (
                    <Button onClick={handleForgotPassword}>
                      Forgot password?
                    </Button>
                  )
                }
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                label={intl.formatMessage({ id: "password" })}
                margin="dense"
                name="password"
                onChange={handleChange}
                required
                type={showPassword ? "text" : "password"}
              />
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {intl.formatMessage({ id: "cancel" })}
          </Button>
          <Button onClick={handleSubmit}>
            {intl.formatMessage({ id: "submit" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
