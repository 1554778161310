import React from 'react';
import { useIntl } from 'react-intl';
import {
  useDispatch,
  } from 'react-redux';
import {
  Avatar,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip
} from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import {
  addToCart
} from "../../store";

const Line = ({ line: variant }) => {
	const intl = useIntl();
  	const dispatch = useDispatch();

	const {
		isError,
		isAvailable,
		maxQuantity = 999999999,
		name,
		itemPhoto,
		subTotal,
		priceText,
		quantity,
		statuses = [],
		tax,
		taxName,
		...rest
	} = variant;

	const helperText = Array.isArray(rest?.helperText) ? rest.helperText.reduce(
		(acc, curr) =>
		  acc.concat(
			curr["isIntl"]
			  ? intl.formatMessage({ id: curr["isIntl"] })
			  : curr
		  ),
		""
	  ) : (rest?.helperText ? intl.formatMessage({ id: rest.helperText}) : '');
	
	const item = {
		isAvailable,
		itemPhoto,
		name,
		tax,
		taxName,
		...rest,
	};
    
	const remove = () => dispatch(addToCart({ item, quantity: -quantity}));
	const increment = () => dispatch(addToCart({ item, quantity: 1}));
	const decrement = () => dispatch(addToCart({ item, quantity: -1}));
	const disabled = maxQuantity <= quantity;

	return (
		<ListItem divider button>
			<Grid container>
				<Grid item xs={12} sm={5} style={{ display: 'flex', alignItems: 'center' }}>
					<ListItemIcon>
						<Tooltip title={intl.formatMessage({ id: 'delete' })}>
							<IconButton
								aria-label="delete"
								onClick={() => remove()}
							>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</ListItemIcon>
					<ListItemIcon>
						<IconButton
							aria-label="remove"
							onClick={() => decrement()}
							disabled={quantity === 0}
						>
							<RemoveIcon />
						</IconButton>
					</ListItemIcon>
					<ListItemIcon>
						<IconButton
							aria-label="add"
							onClick={() => increment()}
							disabled={!isAvailable || disabled }
						>
							<AddIcon />
						</IconButton>
					</ListItemIcon>
					{isError && Array.isArray(statuses) && statuses.length > 0 ? statuses.map((status, i) => 					
					<ListItemText
						key={i}
						primary={intl.formatMessage({ id: status })}
					/>): null}

					<ListItemText
						primary={name}
						secondary={quantity}
					/>
				</Grid>
				<Grid item xs={12}  sm={3} style={{ display: 'flex', alignItems: 'center' }}>
					<ListItemAvatar>
						<Avatar
							alt={name}
							src={itemPhoto}
						/>
					</ListItemAvatar>
				</Grid>
				<Grid item xs={9} sm={3} style={{ display: 'flex', alignItems: 'center' }}>
					<ListItemText
						primary={priceText}
						secondary={helperText}
					/>
				</Grid>
				<Grid item xs={3} sm={1} style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
					<ListItemText
						primary={`= $${parseFloat(subTotal).toFixed(2)}`}
						secondary={tax > 0 ? `+$${parseFloat(tax).toFixed(2)} ${taxName}` : null}
					/>
				</Grid>
			</Grid>
		</ListItem>
	)
}

export default Line