/* eslint-disable react/style-prop-object */
import { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";
// import { Icon } from "@iconify/react";
// import eyeFill from "@iconify/icons-eva/eye-fill";
// import shareFill from "@iconify/icons-eva/share-fill";
// import messageCircleFill from "@iconify/icons-eva/message-circle-fill";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Collapse,
  // Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SvgIconStyle from "../../SvgIconStyle";
import AddToCart from "../../AddToCart";
import { planSelected, membershipPlansSelectors } from "../../../store";
import ImageViewer from "../../../utils/ImageViewer";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CardMediaStyle = styled("div")({
  position: "relative",
  paddingTop: "calc(100% * 3 / 4)",
});

const AvatarStyle = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: "absolute",
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

MembershipDialog.propTypes = {
  membership: PropTypes.object.isRequired,
};

export default function MembershipDialog({ membership }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const {
    // categories = [],
    childLengths,
    childTiers,
    description = "",
    membershipId,
    name: membershipName,
    // view,
    // comment,
    // share,
    vendorName,
    vendorPhoto,
    selectedPlanId,
  } = membership;

  const plan = useSelector((state) =>
    membershipPlansSelectors.selectById(state, selectedPlanId)
  );

  const [selectedTier, setSelectedTier] = useState(childTiers.ids[0]);
  const [selectedLength, setSelectedLength] = useState(childLengths.ids[0]);

  const {
    // compareAtPrice,
    name,
    // price,
    membershipPhoto,
  } = plan || {
    ...membership,
    ...plan,
    name: "",
    price: 0,
    membershipPhoto: "",
  };

  const handleChangeLength = (lengthId) => {
    setSelectedLength(lengthId);
    dispatch(
      planSelected({
        planId: membershipId,
        lengthId,
        tierId: selectedTier,
      })
    );
  };

  const handleChangeTier = (tierId) => {
    setSelectedTier(tierId);
    dispatch(
      planSelected({
        planId: membershipId,
        lengthId: selectedLength,
        tierId,
      })
    );
  };

  return (
    <Card
      sx={{
        position: "relative",
        borderRadius: "8px",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <CardMediaStyle>
        <SvgIconStyle
          color="paper"
          src="/static/icons/shape-avatar.svg"
          sx={{
            width: 80,
            height: 36,
            zIndex: 9,
            bottom: -15,
            position: "absolute",
          }}
        />
        <AvatarStyle alt={vendorName} src={vendorPhoto} />
        <ImageViewer alt={name} src={membershipPhoto || vendorPhoto} />
      </CardMediaStyle>
      <CardContent
        sx={{
          pt: 4,
          pb: 0,
        }}
      >
        <Typography
          gutterBottom
          plan="body1"
          sx={{ color: "text.disabled", display: "block" }}
        >
          {vendorName}
        </Typography>
        <Typography variant="h5">{membershipName} </Typography>
        <Typography variant="h4">{name}</Typography>
        <Typography gutterBottom variant="body2">
          {description}
        </Typography>
        {/* {categories.map((category, i) => (
            <Chip key={i} label={intl.formatMessage({ id: category })} />
          ))} */}
      </CardContent>
      {plan && (
        <CardActions spacing={2} sx={{ p: 2 }}>
          {(Object.keys(childLengths?.entities).length > 1 ||
            Object.keys(childTiers?.entities).length > 1) && (
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          )}
          <AddToCart
            item={{
              ...plan,
              itemPhoto: plan.membershipPhoto || vendorPhoto,
              membershipId,
              planId: selectedPlanId,
              type: "memberships"
            }}
          />
        </CardActions>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Grid container spacing={2}>
            {childLengths?.ids?.length > 0 && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="length-label">
                    {intl.formatMessage({ id: "length" })}
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="length-label"
                    value={selectedLength}
                    onChange={(e) => handleChangeLength(e.target.value)}
                    disabled={childLengths.ids.length === 1}
                  >
                    {childLengths.ids.map((id) => {
                      const { name } = childLengths.entities[id];
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {childTiers?.ids?.length > 0 && (
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="tier-label">
                    {intl.formatMessage({ id: "tier" })}
                  </InputLabel>
                  <Select
                    autoWidth
                    labelId="tier-label"
                    value={selectedTier}
                    onChange={(e) => handleChangeTier(e.target.value)}
                    disabled={childTiers.ids.length === 1}
                  >
                    {childTiers.ids.map((id) => {
                      const { name } = childTiers.entities[id];
                      return (
                        <MenuItem key={id} value={id}>
                          {name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}
