/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useEffect, useReducer, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AvatarImageField from "../../AvatarImageField";
import {
  selectByAuthorId,
  selectStatuses,
  selectUserId,
  updateLocation,
  updateVendor,
} from "../../../store";
import MapContainer from "../ProfileForm/MapContainer";

const initialState = {
  src: undefined,
  imageId: undefined,
  image: undefined,
};

function reducer(state, action) {
  switch (action.type) {
    case "srcChanged":
      return {
        ...state,
        src: action.payload,
      };
    case "stateChanged":
      return {
        ...state,
        ...action.payload,
      };
    default:
      throw new Error();
  }
}

const NORTHPOLE = {
  longitude: 0.0,
  latitude: 90.0,
};

const libraries = process.env.REACT_APP_GOOGLE_MAPS_LIBRARIES.split(",");

export default function VendorForm({ vendor }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const statuses = useSelector(selectStatuses);
  const userId = useSelector(selectUserId);

  const [{ src }, _dispatch] = useReducer(reducer, initialState);

  const {
    vendorName = "",
    email,
    range = 25,
    status = "draft",
    id,
    isSameAddressAsPersonal = false,
    vendorPhoto,
    vendorPhotoId = 0,
  } = vendor;

  const vendorLocation = useSelector((state) => selectByAuthorId(state, id));
  const userLocation = useSelector((state) => selectByAuthorId(state, userId));
  const [newAddress, setNewAddress] = useState(vendorLocation);
  useEffect(() => {
    if (vendorLocation) {
      setNewAddress(vendorLocation);
    } else {
      setNewAddress(userLocation);
    }
  }, [vendorLocation, userLocation]);

  const {
    latitude,
    longitude,
    location,
    address2,
    locality,
    administrative_area_level_1,
    country,
    postal_code,
  } = vendorLocation || {
    latitude: NORTHPOLE.latitude,
    longitude: NORTHPOLE.longitude,
    location: "",
    address2: "",
    locality: "",
    administrative_area_level_1: "",
    country: "Canada",
    postal_code: "",
  };

  const VendorSchema = Yup.object().shape({
    vendorName: Yup.string().required(intl.formatMessage({ id: "required" })),
    email: Yup.string().required(intl.formatMessage({ id: "required" })),
    vendorPhoto: Yup.string().required(intl.formatMessage({ id: "required" })),
    location: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const handleAddressUpdate = (updatedAddress) => {
    setNewAddress(updatedAddress);
  };

  const handleRangeUpdate = (range) => {
    dispatch(updateVendor({ id, payload: { range: range ?? 25 } }));
    dispatch(
      updateLocation({
        id,
        payload: {
          range: range ?? 25,
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      address2,
      administrative_area_level_1,
      country,
      vendorName,
      email,
      isSameAddressAsPersonal,
      latitude,
      longitude,
      location,
      locality,
      postal_code,
      range,
      id,
      status,
      vendorPhoto,
      vendorPhotoId,
    },
    validationSchema: VendorSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    // handleSubmit,
    getFieldProps,
  } = formik;

  useEffect(() => {
    _dispatch({ type: "srcChanged", payload: vendorPhoto });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorPhoto]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { location, ...rest } = values;
    const { geometry, ...restAddress } = newAddress;
    dispatch(
      updateVendor({
        id: id,
        payload: {
          ...rest,
          latitude: newAddress.latitude,
          longitude: newAddress.longitude,
          isSameAddressAsPersonal,
          ownerId: userId,
          updated: Date.now(),
          vendorId: id,
        },
      })
    );
    dispatch(
      updateLocation({
        id: id,
        payload: {
          isSameAddressAsPersonal,
          ownerId: id,
          latitude: newAddress.lat ?? NORTHPOLE.latitude,
          longitude: newAddress.lng ?? NORTHPOLE.longitude,
          location: newAddress.formatted_address ?? "",
          ...restAddress,
          range: values?.range ? values.range : 25,
          status,
        },
      })
    );
  };

  // const resetForm = () => {
  //   formik.resetForm();
  // };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={{ xs: 2, md: 3 }}>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <Card>
              <CardHeader
                title={intl.formatMessage({ id: "vendor" })}
                action={
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    disabled={formik.isSubmitting}
                  >
                    {intl.formatMessage({ id: "save" })}
                  </LoadingButton>
                }
              />
              <CardContent>
                <Grid item container spacing={2}>
                  <Grid item xs={12} md={4}>
                    {id && (
                      <AvatarImageField
                        name="vendorPhoto"
                        variant="rounded"
                        disabled={!true}
                        uid={id}
                        change={({ downloadURL, fileName }) => {
                          setFieldValue("vendorPhoto", downloadURL);
                          setFieldValue("vendorPhotoId", fileName);
                          _dispatch({
                            type: "srcChanged",
                            payload: downloadURL,
                          });
                        }}
                        initialized={true}
                        intl={intl}
                        path={"vendors"}
                        src={src}
                        required
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          fullWidth
                          label={intl.formatMessage({
                            id: "vendorName",
                          })}
                          {...getFieldProps("vendorName")}
                          onChange={formik.handleChange}
                          value={values.vendorName}
                          error={Boolean(
                            touched.vendorName && errors.vendorName
                          )}
                          helperText={touched.vendorName && errors.vendorName}
                          placeholder={intl.formatMessage({
                            id: "placeholder_display_name",
                          })}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          fullWidth
                          label={intl.formatMessage({ id: "email" })}
                          {...getFieldProps("email")}
                          onChange={formik.handleChange}
                          value={values.email}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          placeholder={intl.formatMessage({
                            id: "email_placeholder",
                          })}
                          sx={{ mb: 2 }}
                        />
                      </Grid>
                      <Grid item>
                        <FormControl sx={{ mb: 2 }}>
                          <InputLabel id="demo-simple-select-label">
                            {intl.formatMessage({ id: "status" })}
                          </InputLabel>
                          <Select
                            name="status"
                            label={intl.formatMessage({ id: "status" })}
                            labelId="demo-simple-select-placeholder-label-label"
                            id="demo-simple-select-placeholder-label"
                            value={values.status}
                            onChange={formik.handleChange}
                          >
                            {statuses.map((status, i) => (
                              <MenuItem key={i} value={status}>
                                {intl.formatMessage({ id: status })}
                              </MenuItem>
                            ))}
                          </Select>
                          {status === "draft" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "vendor_status_hint",
                              })}
                            </FormHelperText>
                          )}
                          {status === "active" && (
                            <FormHelperText>
                              {intl.formatMessage({
                                id: "vendor_status_active_hint",
                              })}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Card>
              <CardHeader
                title={`${intl.formatMessage({
                  id: "vendor",
                })} ${intl.formatMessage({ id: "location" })}`}
                subheader={intl.formatMessage({
                  id: "location_subheader",
                })}
                action={
                  newAddress && (
                    <Button variant="contained" onClick={handleSubmit}>
                      {intl.formatMessage({ id: "save" })}
                    </Button>
                  )
                }
              />
              <CardContent>
                <Box sx={{ height: 450 }}>
                  <MapContainer
                    address={newAddress}
                    formatted_address={newAddress?.formatted_address ?? ""}
                    libraries={libraries}
                    range={range}
                    onAddressUpdate={handleAddressUpdate}
                    onRangeUpdate={handleRangeUpdate}
                    vendors={[]}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
