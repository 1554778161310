import { Icon } from "@iconify/react";
import {
  Badge,
} from "@mui/material";
// import InfoIcon from '@mui/icons-material/Info';
// import BugReportIcon from '@mui/icons-material/BugReport';
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import ReceiptIcon from "@mui/icons-material/Receipt";

const sidebarConfig = (vendorId, orders, handleMarkAsRead) => {
  return [
    {
      title: "buy",
      path: "",
      icon: <Icon icon="mdi:basket-fill" width={22} height={22} />,
    },
    {
      title: "sell",
      path: "",
      icon: (
        <Icon
          icon="ant-design:unordered-list-outlined"
          width={22}
          height={22}
        />
      ),
      children: [
        {
          title: "vendor",
          path: vendorId ? `/vendors/${vendorId}/edit` : "",
          icon: <Icon icon="healthicons:market-stall" width={22} height={22} />,
        },
        {
          title: "products",
          path: vendorId ? `/vendors/${vendorId}/products` : "",
          icon: (
            <Icon
              icon="ant-design:unordered-list-outlined"
              width={22}
              height={22}
            />
          ),
        },
        {
          title: "memberships",
          path: vendorId ? `/vendors/${vendorId}/memberships` : "",
          icon: <CardMembershipIcon width={22} height={22} />,
        },
        {
          title: "orders",
          path: vendorId ? `/vendors/${vendorId}/orders` : "",
          icon: (
            <Badge badgeContent={orders} color="error">
              <ReceiptIcon onClick={handleMarkAsRead} width={22} height={22} />
            </Badge>
          ),
        },
      ],
    },
  ];
};

export default sidebarConfig;
