import React, { useState } from 'react';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import {
  Avatar,
  IconButton,
  Stack
} from '@mui/material';
import ImageCropDialog from './ImageCrop';
import { getDownloadURL } from "firebase/storage";

const AvatarImageField = (props) => {
  const {
    src, 
    disabled, 
    initialized, 
    intl, 
    path, 
    uid, 
    name, 
    change, 
    icon, 
    variant = 'rounded',
  } = props;

  const [selectedImage, setImage] = useState(undefined);

  const [fileName, setFileName] = useState();

  const d = new Date();

  const handleClick = () => {
    setFileName(d.getTime()); 
    setImage(true);     
  }

  const handlePhotoUploadSuccess = snapshot => {
    getDownloadURL(snapshot.ref).then(downloadURL => {
      change({name, downloadURL, fileName});
      setImage(undefined);
    })
  }
  
  return (
    <Stack>
      <Avatar
        style={{ width: 120, height: 120, fontSize: 60 }}
        src={src}
        sx={{ mx: "auto" }}
        variant={variant}
      >
        {icon}
      </Avatar>
      <IconButton
        onClick={() => handleClick()}
        disabled={disabled === true ? true : uid === undefined || !initialized}
        color="inherit"
        sx={{ mx: "auto", width: 'fit-content' }}
      >
        <AddAPhotoIcon />
      </IconButton>
      <input
        name={name}
        accept='image/*'
        id='contained-button-file'
        type='file'
        hidden
      />
      <ImageCropDialog
        path={`${path}/${uid}`}
        fileName={fileName}
        onUploadSuccess={s => handlePhotoUploadSuccess(s)}
        open={selectedImage !== undefined}
        src={selectedImage}
        handleClose={() => setImage(undefined)}
        title={intl.formatMessage({ id: 'change' })}
      />
    </Stack>
  )
}

export default AvatarImageField