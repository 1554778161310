import { useRef, useState } from "react";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
// import SettingsIcon from "@mui/icons-material/Settings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import MenuPopover from "../../components/MenuPopover";
import {
  logout,
  selectUser,
  selectVendorByOwnerId,
  usersSelectors,
  selectIsLoggedIn,
} from "../../store";
import { useIntl } from "react-intl";

export default function AccountPopover() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    displayName = "",
    email = "",
    photoURL = "",
    uid = "",
  } = useSelector(selectUser) || {
    email: undefined,
    uid: undefined,
    displayName: undefined,
  };

  const vendor = useSelector((state) => selectVendorByOwnerId(state, uid));

  const { photoURL: userPhoto, displayName: userName } = useSelector((state) =>
    usersSelectors.selectById(state, uid)
  ) || {
    photoURL: undefined,
    displayName: undefined,
  };
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        {userPhoto ? (
          <Avatar src={userPhoto} alt="photoURL" />
        ) : (
          <Avatar src={photoURL} alt="photoURL" />
        )}
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userName ? userName : displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <MenuItem
          to={"/"}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          {intl.formatMessage({ id: "home" })}
        </MenuItem>
        {uid && (
          <>
            <MenuItem
              to={`/profile`}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              {intl.formatMessage({ id: "profile" })}
            </MenuItem>
            {vendor?.vendorId && (
              <MenuItem
                to={`/vendors/${vendor.vendorId}/edit`}
                component={RouterLink}
                onClick={handleClose}
                sx={{
                  typography: "body2",
                  py: 1,
                  px: 2.5,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%", // adjust as needed
                  display: "flex", // ensures ellipsis works
                }}
              >
                <ListItemIcon>
                  <StorefrontIcon />
                </ListItemIcon>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "100%", // adjust as needed
                    display: "block", // ensures ellipsis works
                  }}
                >
                  {vendor?.vendorName
                    ? vendor.vendorName
                    : intl.formatMessage({ id: "vendor" })}
                </div>
              </MenuItem>
            )}

            <MenuItem
              to={`/purchases`}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText>
                {intl.formatMessage({ id: "purchases" })}
              </ListItemText>
            </MenuItem>
          </>
        )}
        <MenuItem
          to={`/enrolments`}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <ListItemIcon>
            <CardMembershipIcon />
          </ListItemIcon>
          <ListItemText>
            {intl.formatMessage({ id: "enrolments" })}
          </ListItemText>
        </MenuItem>
        {/* <MenuItem
          to={"/settings"}
          component={RouterLink}
          onClick={handleClose}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          {intl.formatMessage({ id: "settings" })}
        </MenuItem> */}
        {isLoggedIn ? (
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => handleLogout()}
            >
              {intl.formatMessage({ id: "logout" })}
            </Button>
          </Box>
        ) : (
          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => navigate("login")}
            >
              {intl.formatMessage({ id: "sign_in" })}
            </Button>
          </Box>
        )}
      </MenuPopover>
    </>
  );
}
