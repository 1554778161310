import { PURGE } from "redux-persist";
import {createAsyncThunk, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { logout, refreshSite, setSnackbar } from './index';
import * as api from '../apis/firebase';

export const agreementsAdapter = createEntityAdapter({})

export const updateAgreement = createAsyncThunk(
  'agreements/update',
  async ({payload, isRejected = false}, { dispatch }) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const response = await api.updateAgreements(payload);
    if (isRejected && payload?.declineAction === 'sign-out') {
      dispatch(logout());
    }
    return response;
  }
);

export const deleteAgreement = createAsyncThunk(
  'agreements/delete',
  async (id, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    const response = await api.removeDoc('agreements', id);
    return response;
  }
);

export const agreements = createSlice({
  name: 'agreements',
  initialState: agreementsAdapter.getInitialState(),
  reducers: {
    upsertOneAgreement: {
      reducer: (state, action) => {
        agreementsAdapter.upsertOne(state, action.payload);
      },
      prepare: (data) => {        
        const { at, created, expiration, updated, ...rest } = data;
        return {
          payload: {
            ...rest,
          }
        }
      }
    },
    removeOneAgreement: agreementsAdapter.removeOne,
    removeAllAgreement: agreementsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        agreementsAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        agreementsAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        agreementsAdapter.removeAll(state);
      })
  },
});

export const { 
  upsertOneAgreement, 
  removeOneAgreement,
  removeAllAgreement
} = agreements.actions;

export const agreementsSelectors = agreementsAdapter.getSelectors((state) => state.agreements);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = agreementsSelectors;

export const selectAgreements = state => state.agreements;

export const selectPosition = state => state.agreements.position;

export default agreements.reducer;
