import React from 'react';
import {useIntl} from "react-intl";
import {
	Avatar,
	Grid,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from "@mui/material";

const PickUpOptions = ({ vendor }) => {
	const intl = useIntl();
	const {
		vendorName,
		vendorPhoto,
		pickUpInstructions,
	} = vendor;
	return (vendor 
		? 	<ListItem button divider>
				<Grid container spacing={2}>
					<Grid item>
						<ListItemAvatar>
							<Avatar
								alt={vendorName}
								src={vendorPhoto}
							/>
						</ListItemAvatar>
					</Grid>
					<Grid item>
						<ListItemText primary={vendorName} />
					</Grid>
					<Grid item>
						<ListItemText primary={pickUpInstructions ? `${pickUpInstructions}`: intl.formatMessage({ id: 'please_confirm_claim_instructions_with_vendor'})} />
					</Grid>
				</Grid>
			</ListItem>
		: null)
}

export default PickUpOptions