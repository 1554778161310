import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Fab,
  LinearProgress,
  List,
  Stack,
  Typography,
} from "@mui/material";
import NavigationIcon from "@mui/icons-material/Navigation";
import CartVendor from "./CartVendor";
import Page from "../../components/Page";
import { cartVendorsSelectors, selectAnyItemLoading } from "../../store";

export default function Cart() {
  const intl = useIntl();
  const navigate = useNavigate();
  const vendors = useSelector(cartVendorsSelectors.selectAll);
  const isLoading = useSelector(selectAnyItemLoading);
  console.log({ isLoading })
  return (
    <Page title={intl.formatMessage({ id: "cart" })}>
      <Container maxWidth="md">
      {isLoading && <LinearProgress />}
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            {intl.formatMessage({ id: "cart" })}
          </Typography>
          <Stack>
            {vendors?.length > 0 ? (
              <List arial-label="cart list">
                {vendors.map((vendor, i) => (
                  <CartVendor vendor={vendor} key={i} />
                ))}
              </List>
            ) : (
              <Typography paragraph>
                {intl.formatMessage({ id: "no_items_in_your_cart_yet" })}               
              </Typography>
            )}
            <Button onClick={() => navigate("/")}>{intl.formatMessage({ id: "continue_shopping" })}  </Button>
          </Stack>
        </Box>
        <Fab
          variant="extended"
          color="secondary"
          onClick={() => navigate("/checkout")}
          sx={{ position: "fixed", bottom: 15, right: 20, zIndex: 99 }}
        >
          {intl.formatMessage({ id: 'checkout' })}
          <NavigationIcon />
        </Fab>
      </Container>
    </Page>
  );
}
