import React from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  Box,
  Collapse,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { addToCart, selectItemLoading , selectLine } from "../../store";

const AddToCart = ({ item, locale = "en-CA" }) => {
  const dispatch = useDispatch();
  const { price } = item;
  const intl = useIntl();
  const {
    isError,
    isAvailable,
    maxQuantity = 999999999,
    name,
    itemPhoto,
    subTotal,
    priceText,
    quantity,
    tax,
    taxName,
    ...rest
  } = useSelector((state) => selectLine(state, item), shallowEqual);

  const isLoading = useSelector(selectItemLoading(item.id));

  const helperText = Array.isArray(rest?.helperText)
    ? rest.helperText.reduce(
        (acc, curr) =>
          acc.concat(
            curr["isIntl"] ? intl.formatMessage({ id: curr["isIntl"] }) : curr
          ),
        ""
      )
    : rest?.helperText
    ? intl.formatMessage({ id: rest.helperText })
    : "";

  const newItem = {
    isAvailable,
    itemPhoto,
    name,
    price,
    tax,
    taxName,
    ...rest,
  };

  console.log({ newItem });

  const handleAddToCart = () =>
    dispatch(addToCart({ item: newItem, quantity: 1 }));

  const handleRemoveFromCart = () =>
    dispatch(addToCart({ item: newItem, quantity: -1 }));

  const itemsInCart = quantity ? quantity : 0;
  const enabled = isAvailable && Boolean(itemsInCart < maxQuantity);

  console.log({ name, itemsInCart, enabled, maxQuantity})

  return (
    <>
      <ListItemText
        id="switch-list-label-wifi"
        primary={
          price === 0
            ? intl.formatMessage({ id: "free" })
            : `${Intl.NumberFormat("en-CA", {
              style: "currency",
              currency: "CAD",
            }).format(price)} ${!!priceText ? priceText : ''}`
        }
        secondary={helperText}
      />
      <Box>
        <Stack>
          <Collapse in={enabled} timeout="auto" unmountOnExit>
            <LoadingButton
              onClick={() => handleAddToCart()}
              loading={isLoading}
              loadingPosition="center"
              endIcon={
                itemsInCart === 0 ? <ShoppingBasketIcon /> : <AddCircleOutlineIcon />
              }
              sx={{
                "& .MuiButton-endIcon": {
                  margin: 0,
                },
              }}
            ></LoadingButton>
          </Collapse>
          <Collapse in={quantity > 0} timeout="auto" unmountOnExit>
            <Typography sx={{ textAlign: "center" }}>{quantity}</Typography>
            <LoadingButton
              onClick={() => handleRemoveFromCart()}
              loading={isLoading}
              loadingPosition="center"
              endIcon={<RemoveCircleOutlineIcon />}
              sx={{
                "& .MuiButton-endIcon": {
                  margin: 0,
                },
              }}
            ></LoadingButton>
          </Collapse>
        </Stack>
      </Box>
    </>
  );
};

export default AddToCart;
