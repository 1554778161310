/* eslint-disable react/style-prop-object */
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { NavLink as RouterLink, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  TextField,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import AvatarImageField from "../../AvatarImageField";
import {
  deleteUser,
  getVendorsByLatLngRadiusInM,
  locationsSelectors,
  // resetUser,
  // selectByAuthorId,
  updateLocation,
  updateUser,
  vendorsSelectors,
} from "../../../store";
import MapContainer from "./MapContainer";

const NORTHPOLE = {
  longitude: 0.0,
  latitude: 90.0,
};

const libraries = process.env.REACT_APP_GOOGLE_MAPS_LIBRARIES.split(",");

export default function ProfileForm({ user }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const vendors = useSelector(vendorsSelectors.selectEntities) ?? [];
  // const vendorLocation = useSelector((state) => selectByAuthorId(state, vendor.ownerId));
  const handleOnDeleteUser = () =>
    dispatch(deleteUser()).then(() => {
      setDeleteDialogOpen(false);
      navigate(`/`);
    });

  const {
    displayName = "",
    email = "",
    range = 25,
    uid,
    photoURL = "",
    photoURLId = uid,
  } = user;

  const address = useSelector((state) =>
    locationsSelectors.selectById(state, uid)
  );

  const [newAddress, setNewAddress] = useState(address);

  useEffect(() => {
    if (address) {
      setNewAddress(address);
    }
  }, [address]);

  const {
    latitude = NORTHPOLE.latitude,
    longitude = NORTHPOLE.longitude,
    lat = NORTHPOLE.latitude,
    lng = NORTHPOLE.longitude,
    location = "",
    address2 = "",
    locality = "",
    administrative_area_level_1 = "",
    country = "Canada",
    postal_code = "",
  } = address || {};

  const UserSchema = Yup.object().shape({
    displayName: Yup.string().required(intl.formatMessage({ id: "required" })),
    userEmail: Yup.string().required(intl.formatMessage({ id: "required" })),
    photoURL: Yup.string().required(intl.formatMessage({ id: "required" })),
    location: Yup.string().required(intl.formatMessage({ id: "required" })),
    // price: Yup.string().required(intl.formatMessage({ id: "required" })),
  });

  const handleAddressUpdate = (updatedAddress) => {
    setNewAddress(updatedAddress);
  };

  const handleRangeUpdate = (range) => {
    dispatch(updateUser({ id: uid, payload: { range: range ?? 25 } }));
    dispatch(
      updateLocation({
        id: uid,
        payload: {
          range: range ?? 25,
        },
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      address2,
      administrative_area_level_1,
      country,
      displayName,
      userEmail: email,
      latitude,
      longitude,
      location,
      locality,
      postal_code,
      range,
      uid,
      photoURL,
      photoURLId,
    },
    validationSchema: UserSchema,
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { location, photoURLId = uid, userEmail, ...rest } = values;
    const { geometry, ...restAddress } = newAddress;
    await Promise.all([
      dispatch(
        updateLocation({
          id: uid,
          payload: {
            ownerId: uid,
            lat: newAddress.lat ?? NORTHPOLE.latitude,
            lng: newAddress.lng ?? NORTHPOLE.longitude,
            latitude: newAddress.lat ?? NORTHPOLE.latitude,
            longitude: newAddress.lng ?? NORTHPOLE.longitude,
            location: newAddress.formatted_address ?? "",
            ...restAddress,
            range: values?.range ? values.range : 25,
          },
        })
      ),
      dispatch(updateUser({ id: uid, payload: { photoURLId, email: userEmail, ...rest } })),
      dispatch(
        getVendorsByLatLngRadiusInM({
          lat: newAddress.lat ?? NORTHPOLE.latitude,
          lng: newAddress.lng ?? NORTHPOLE.longitude,
          radiusInM: values?.range ? parseInt(values.range) * 1000 : 25000,
        })
      ),
    ]);
  };

  // const resetForm = () => {
  //   formik.resetForm();
  //   dispatch(resetUser(uid));
  // };

  return (
    <>
      <Container maxWidth="md">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid item container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <Link
                  component={RouterLink}
                  underline="none"
                  variant="subtitle2"
                  to={`/`}
                >
                  {`< ${intl.formatMessage({ id: "all" })} ${intl.formatMessage(
                    {
                      id: "products",
                    }
                  )}`}
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ zIndex: 2 }}>
                  <CardHeader
                    title={intl.formatMessage({ id: "location" })}
                    subheader={intl.formatMessage({
                      id: "location_subheader",
                    })}
                    action={
                      newAddress && (
                        <Button variant="contained" onClick={handleSubmit}>
                          {intl.formatMessage({ id: "save" })}
                        </Button>
                      )
                    }
                  />
                  <CardContent>
                    <Box sx={{ height: 450 }}>
                      <MapContainer
                        address={{ lat, lng, ...newAddress }}
                        formatted_address={newAddress?.formatted_address ?? ""}
                        libraries={libraries}
                        range={range}
                        onAddressUpdate={handleAddressUpdate}
                        onRangeUpdate={handleRangeUpdate}
                        vendors={vendors}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title={intl.formatMessage({ id: "profile" })}
                    action={
                      <>
                        <LoadingButton
                          type="submit"
                          variant="contained"
                          loading={isSubmitting}
                          disabled={formik.isSubmitting}
                          onClick={handleSubmit}
                        >
                          {intl.formatMessage({ id: "save" })}
                        </LoadingButton>
                        <IconButton
                          aria-label="settings"
                          onClick={() => setDeleteDialogOpen(true)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </>
                    }
                  />
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        {uid && (
                          <AvatarImageField
                            name="photoURL"
                            variant="rounded"
                            disabled={!true}
                            uid={uid}
                            change={({ downloadURL, fileName }) => {
                              setFieldValue("photoURL", downloadURL);
                              setFieldValue("photoURLId", fileName);
                            }}
                            initialized={true}
                            intl={intl}
                            path={"users"}
                            src={values.photoURL}
                            required
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} md={8}>
                        <Grid container item spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              type="text"
                              fullWidth
                              label={intl.formatMessage({
                                id: "displayName",
                              })}
                              {...getFieldProps("displayName")}
                              onChange={formik.handleChange}
                              value={values.displayName}
                              error={Boolean(
                                touched.displayName && errors.displayName
                              )}
                              helperText={
                                touched.displayName && errors.displayName
                              }
                              placeholder={intl.formatMessage({
                                id: "placeholder_display_name",
                              })}
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              type="text"
                              fullWidth
                              label={intl.formatMessage({ id: "email" })}
                              {...getFieldProps("userEmail")}
                              onChange={formik.handleChange}
                              value={values.userEmail}
                              error={Boolean(touched.email && errors.email)}
                              helperText={touched.email && errors.email}
                              placeholder={intl.formatMessage({
                                id: "email_placeholder",
                              })}
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
      <Dialog
        open={deleteDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {intl.formatMessage({
            id: "delete_user_title",
            defaultMessage: "Delete User?",
          })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {intl.formatMessage({
              id: "delete_user_content",
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {intl.formatMessage({
              id: "cancel",
              defaultMessage: "Cancel",
            })}
          </Button>
          <Button onClick={() => handleOnDeleteUser()} color="primary">
            {intl.formatMessage({ id: "confirm" })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
