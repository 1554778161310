import { PURGE } from "redux-persist";
import { createAction, createAsyncThunk, createSelector, createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { logout, refreshSite, setSnackbar } from './index';
import * as api from '../apis/firebase';
import { updateManyNotification } from './index';

export const setCurrentPosition = createAction('invitations/setCurrentPosition');

const invitationsAdapter = createEntityAdapter()

export const deleteInvitation = createAsyncThunk(
  'invitations/delete',
  async (id, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    await api.removeDoc('invitations', id)
  });

export const updateInvitation = createAsyncThunk(
  'invitations/update',
  async ({ id, payload }, {dispatch}) => {
    dispatch(setSnackbar({pageLoading: 'loading'}));
    await api.updateDoc('invitations', id, payload)
  });

export const invitations = createSlice({
  name: 'invitations',
  initialState: invitationsAdapter.getInitialState({
    position: { coords: { longitude: 0, latitude: 0 }}
  }),
  reducers: {
    upsertOneInvitation: {
      reducer: (state, action) => invitationsAdapter.upsertOne(state, action.payload),
      prepare: (data) => {
        const {at, updated, toBeAcceptedOnLogin = false, ...rest} = data;
        return {
          payload: {
            toBeAcceptedOnLogin,
            ...rest
          }
        }
      }
    },
    removeOneInvitation: invitationsAdapter.removeOne,
    removeAllInvitation: invitationsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder
      .addCase(PURGE, (state) => {
        invitationsAdapter.removeAll(state);
      })
      .addCase(logout.fulfilled, (state) => {
        invitationsAdapter.removeAll(state);
      })
      .addCase(refreshSite.fulfilled, (state) => {
        invitationsAdapter.removeAll(state);
      })
      .addCase(updateManyNotification, (state, action) => {
        invitationsAdapter.updateMany(state, action.payload);
      })
  },
});

export const { 
  upsertOneInvitation,
  removeOneInvitation,
  removeAllInvitation
} = invitations.actions;

export const invitationsSelectors = invitationsAdapter.getSelectors((state) => state.invitations);

export const {
  selectIds,
  selectById,
  selectTotal,
  selectEntities,
  selectAll,
} = invitationsSelectors;

export const selectInvitations = state => state.invitations;

export const selectInvitationsToBeAcceptedOnLogin = createSelector(
  [selectAll],
  (invitations) => invitations.filter((invitation) => invitation.toBeAcceptedOnLogin)
);

export default invitations.reducer;
