/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import ThemeConfig from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import { IntlProvider } from "react-intl";
import Router from "./routes";
import { geofire } from "./apis/firebase";
import {
  enrolmentsSelectors,
  selectProfile,
  selectUserEmail,
  selectUserId,
  selectUserLocale,
  selectLocale,
  selectVendorIdByOwnerId,
} from "./store";
import { locationsSelectors } from "./store/locations";
import { LinearProgress } from "@mui/material";
const SiteConfigHandler = React.lazy(() => import('./dataHandlers/SiteConfigHandler'));
const UserAuthHandler = React.lazy(() => import('./dataHandlers/UserAuthHandler'));
const UserDataHandler = React.lazy(() => import('./dataHandlers/UserDataHandler'));
const VendorDataHandler = React.lazy(() => import('./dataHandlers/VendorDataHandler'));
const LanguageHandler = React.lazy(() => import('./dataHandlers/LanguageHandler'));
const NotificationsHandler = React.lazy(() => import('./dataHandlers/NotificationsHandler'));
const EnrolmentsHandler = React.lazy(() => import('./dataHandlers/EnrolmentsHandler'));


function App() {
  const userId = useSelector(selectUserId);
  const vendorId = useSelector((state) =>
    selectVendorIdByOwnerId(state, userId)
  );
  const { range = 0 } = useSelector(selectProfile);
  const email = useSelector(selectUserEmail);
  const { value } = useSelector(selectUserLocale);
  const enrolmentIds = useSelector(enrolmentsSelectors.selectIds);
  const messages = useSelector(selectLocale);
  const place = useSelector((state) =>
    locationsSelectors.selectById(state, userId)
  );
  const { latitude = 0, longitude = 0 } = place || {
    latitude: 0,
    longitude: 0,
  };

  const radiusInM = range * 1000;
  const bounds = useRef([]);

  useEffect(() => {
    if (latitude && longitude && radiusInM) {
      bounds.current = geofire?.geohashQueryBounds(
        [latitude, longitude],
        radiusInM
      );
    }
  }, [latitude, longitude, radiusInM]);

  return (
    <IntlProvider messages={messages} locale={value} defaultLocale={"en-US"}>
      <ThemeConfig>
        <ScrollToTop />
        <Suspense fallback={<LinearProgress />}>
          <SiteConfigHandler />
          <UserAuthHandler />
          {userId && (
            <UserDataHandler
              userId={userId}
              bounds={bounds.current}
              latitude={latitude}
              longitude={longitude}
              radiusInM={radiusInM}
            />
          )}
          {vendorId && (
            <VendorDataHandler
              vendorId={vendorId}
              bounds={bounds.current}
              latitude={latitude}
              longitude={longitude}
              radiusInM={radiusInM}
            />
          )}
          {value && <LanguageHandler value={value} />}
          {email && <NotificationsHandler email={email} />}
          {enrolmentIds && <EnrolmentsHandler enrolmentIds={enrolmentIds} />}
          <Router />
        </Suspense>
      </ThemeConfig>
    </IntlProvider>
  );
  
}

export default App;
