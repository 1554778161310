import { useEffect, useState, } from "react";
import { useSelector } from 'react-redux';
import Page from '../components/Page';
import { ProfileForm } from '../components/forms/ProfileForm';
import { usersSelectors, selectHasAddress, selectUserId, } from '../store';
import { useIntl } from 'react-intl';

export default function Profile() {
  const id = useSelector(selectUserId);
  const intl = useIntl();
  const user = useSelector(state => usersSelectors.selectById(state, id));
  const hasAddress = useSelector(state => selectHasAddress(state, id));
  const [isUserReady, setIsUserReady] = useState(false);
  
  useEffect(() => {
    setIsUserReady(user !== undefined && user !== null);
  }, [user])
  return (
    <Page pageId="profile" showBackdrop={!hasAddress} title={(user?.name && user.name) || intl.formatMessage({ id: 'user' })}>
      {!isUserReady ? <div>Loading...</div> : <ProfileForm user={user} />}      
    </Page>
  );
}